@import '~@telia-front/sass-tokens/index.scss';

@import '~@core-front/reset/styles.scss';

[data-theme='light'] {
  color-scheme: light;
}

[data-theme='dark'] {
  color-scheme: dark;
}
