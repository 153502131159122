@import '~@core-front/sass-tokens/index.scss';

.text {
  width: 100%;

  > * + * {
    margin-top: 1em;
  }

  > h1,
  > .h1 {
    margin-bottom: $text-h1-margin-bottom;
  }

  > :last-child:not(.grid) {
    margin-bottom: 0;
  }

  > :first-child {
    margin-top: 0;
  }

  > .text__margin {
    margin-top: 1em;
  }

  > .text__margin-none {
    margin-top: 0;
  }

  > .text__margin-double {
    margin-top: 2em;
  }

  > .text__margin-half {
    margin-top: 0.5em;
  }
}

.text--double > * + * {
  margin-top: 2em;
}

.text--half > * + * {
  margin-top: 0.5em;
}

.text--pre-wrap {
  white-space: pre-wrap;
}
