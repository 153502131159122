.header__megamenu-grid {
  flex-wrap: wrap;
  margin-left: -20px;

  @media (min-width: $media-width-lg-min) {
    flex-wrap: nowrap;
    display: inline-flex;
  }
}

.header__nav-megamenu-dropdown {
  width: 100%;
  max-width: 100%;

  & .header__dropdown-content-wrapper {
    background-color: var(--color-neutral-bg-level-2);
    border-radius: 0;
  }
}

.header__megamenu-column {
  padding: 0 20px 20px;
  width: 15rem;

  @media (max-width: $media-width-md-max) {
    width: inherit;
  }
}

.header__megamenu-column--bordered {
  @media (min-width: $media-width-lg-min) {
    border-left: 1px solid var(--color-neutral-border-subtlest);
  }
}

.header__megamenu-title {
  margin-bottom: 16px;

  &.header__megamenu-title--no-margin {
    margin-bottom: 0;
  }
}

.header__megamenu-title-tag {
  margin-bottom: 16px;
}

.header__megamenu-link {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  font-size: var(--type-small-font-size);
  line-height: var(--type-small-line-height);
  font-weight: 400;
  color: var(--color-neutral-text);

  @media (min-width: $media-width-xl-min) {
    font-size: var(--type-font-size);
    line-height: var(--type-line-height);
  }

  @media (max-width: $media-width-md-max) {
    font-size: var(--type-font-size);
    line-height: var(--type-line-height);
  }

  &.is-current {
    color: var(--color-brand-primary-text);
  }

  html[data-whatintent='mouse'] &:hover {
    color: var(--color-brand-primary-text-hover);
  }
}

.header__megamenu-link--employee,
.header__megamenu-link--employee.is-current {
  color: var(--color-employee-text);

  html[data-whatintent='mouse'] &:hover {
    color: var(--color-employee-text-hover);
  }
}

.header__megamenu-link-inner {
  margin-right: 6px;
}

.header__megamenu-link-secure-icon,
.header__megamenu-external-icon {
  margin-left: 3px;
  font-size: 1rem;
}

.header__megamenu-link-image {
  display: inline-block;
  vertical-align: middle;
  margin-top: -1px;
}

.header__megamenu-section {
  & + & {
    margin-top: 20px;
  }
}

.header__megamenu-tag {
  margin-top: -1px;
}

.header__megamenu-banners {
  @media (min-width: $media-width-lg-min) {
    margin-top: 40px;
    margin-bottom: -40px;
  }
}

.header__megamenu-banners-grid {
  @media (max-width: $media-width-md-max) {
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 0;
  }

  .header__megamenu-banners-grid-col {
    @media (max-width: $media-width-md-max) {
      padding-right: 0;
      padding-left: 0;
    }
  }
}

.header__megamenu-list--bordered {
  border-top: 1px solid var(--color-neutral-border-subtlest);
  padding-top: 15px;
  margin-top: -5px;
}
