@import '~@telia-front/sass-tokens/index.scss';

@import '~@core-front/typography/styles.scss';

.text-expressive {
  color: var(--color-brand-expressive-text);
}

.text-attention {
  color: var(--color-attention-text);
}

.text-error {
  color: var(--color-error-text);
}

.text-info {
  color: var(--color-info-text);
}

.text-employee {
  color: var(--color-employee-text);
}

// Deprecated. Use dark theme instead.
.text-white {
  color: $text-color-white;
}

// Text utilities

.text-tiny {
  color: var(--color-neutral-text-subtle);
  font-size: $font-size-tiny;
  line-height: $line-height-tiny;

  @media (min-width: $media-width-xl-min) {
    font-size: $font-size-tiny-xl;
    line-height: $line-height-tiny-xl;
  }
}

.text-uppercase {
  text-transform: uppercase;
}

// Also defined in core-front, but we need it to override text-tiny in some cases (InternetSpeedIndicator)...
.text-primary {
  color: var(--color-neutral-text);
}

// Deprecated. Use dark theme instead.
.text-primary-dark {
  color: $text-color-dark-primary;
}

// Deprecated. Use dark theme instead.
.text-light-dark {
  color: $text-color-dark-light;
}

// Deprecated. Use dark theme instead.
.text-inactive-dark {
  color: $text-color-dark-inactive;
}

.text-beige {
  color: var(--color-brand-secondary-text);
}

.text-campaign {
  color: var(--color-campaign-2-text);
}
