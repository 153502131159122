@use 'sass:color';
@use 'sass:map';

@import '~@core-front/sass-tokens/index.scss';

// Calculate the space between the screen and the container
// Calculation breakdown:
// 1. 100vw - $page-max-width: Calculate the remaining width after subtracting the container width from the viewport width.
// 2. + $container-padding-lg * 2: Add twice the container padding to account for the padding on both sides of the container.
// 3. - var(--notice-scrollbar-width): Subtract the width of the scrollbar to ensure the calculation accounts for the available screen space excluding the scrollbar.
// 4. / 2: Divide the remaining space by 2 to evenly distribute the extra space to the left and right of the container.
// 5. * -1: Negate the value to apply it as a negative margin, effectively pulling the container to the left edge of the screen.
$space-between-screen-and-container: calc(((100vw - $page-max-width + $container-padding-lg * 2 - var(--notice-scrollbar-width)) / 2) * -1) !default;

.notice {
  position: relative;
  border: $notice-border-size solid;
  border-radius: $notice-border-radius;
  padding: 15px;

  @media (min-width: $media-width-sm-min) {
    padding: 15px 23px;
  }

  &.notice--small,
  &.notice--vertical {
    padding: 0;
  }

  &.notice--full-width {
    &.notice--full-width-container,
    .container > & {
      padding: 16px 0;
      border: 0;
      border-radius: 0;
      opacity: 0.95;
      backdrop-filter: blur(2px);
      margin-left: -$container-padding;
      margin-right: -$container-padding;

      @media (min-width: $media-width-sm-min) {
        margin-left: -$container-padding-sm;
        margin-right: -$container-padding-sm;
      }

      @media (min-width: $media-width-lg-min) {
        margin-left: -$container-padding-lg;
        margin-right: -$container-padding-lg;
      }

      @media (min-width: $media-width-xl-min) {
        margin-left: $space-between-screen-and-container;
        margin-right: $space-between-screen-and-container;
      }
    }
  }
}

.notice__content-wrapper {
  display: flex;
  gap: $notice-gap-sm;

  .notice--vertical & {
    display: block;
  }

  .notice--center.notice--small & {
    justify-content: center;
  }

  @media (min-width: $media-width-sm-min) {
    gap: $notice-gap;

    .notice--small & {
      gap: $notice-gap-sm;
    }
  }
}

.notice--with-close {
  @media (min-width: $media-width-sm-min) {
    padding-right: 15px;
  }
}

@each $name, $color in $notice-colors {
  .notice--#{$name} {
    border-color: map.get($color, 'border');
    background: map.get($color, 'background');

    & .notice__icon {
      color: map.get($color, 'icon');
    }

    &.notice--small,
    &.notice--vertical {
      background: transparent;
    }
  }
}

.notice--success {
  border: none;
  padding: 15px;

  @media (min-width: $media-width-sm-min) {
    padding: 20px;
  }

  &.notice--small,
  &.notice--vertical {
    padding: 0;
  }
}

.notice--small {
  background: transparent;
  border: none;
}

.notice--vertical {
  background: transparent;
  border: none;
}

.notice__icon {
  @include reset-icon();

  .notice--success:not(.notice--vertical, .notice--small, .notice--full-width) & {
    font-size: $notice-success-icon-size;

    @media (min-width: $media-width-sm-min) {
      font-size: $notice-success-icon-size-sm;
    }
  }

  .notice--small & {
    margin-top: 0;
  }
}

.notice__inner-wrapper {
  display: flex;
  align-items: center;
  width: 100%;
}

.notice__inner {
  width: 100%;
}

.notice__icon-cell {
  padding: 0;
  display: flex;
  align-self: flex-start;
  line-height: 1;

  .notice--vertical & {
    justify-content: center;
  }

  .notice--center & {
    align-self: center;
  }
}

.notice__content {
  display: flex;
  width: 100%;
  align-self: center;
  text-align: left;

  .notice--vertical & {
    text-align: center;
    padding: 16px 0 0;

    @media (min-width: $media-width-sm-min) {
      padding-top: 24px;
    }
  }

  .notice--vertical.notice--small & {
    padding-top: 8px;
  }

  .notice--center.notice--small & {
    width: auto;
  }
}

.notice__close {
  padding: 0 0 0 $notice-close-padding-left-sm;
  align-self: flex-start;

  @media (min-width: $media-width-sm-min) {
    padding-left: $notice-close-padding-left;
  }

  .notice--center & {
    align-self: center;
  }
}
