@import '~@telia-front/sass-tokens/index.scss';

.status {
  display: flex;
  align-items: baseline;
  font-size: 14px;
}

.status__circle {
  flex-shrink: 0;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  margin-right: 8px;

  .status--error & {
    background-color: $color-error-1;
  }

  .status--repair & {
    background-color: $blue;
  }

  .status--attention & {
    background-color: $orange;
  }

  .status--success & {
    background-color: $color-success-1;
  }

  .status--inactive & {
    background-color: $color-raw-grey-dark;
  }
}
