@import '~@core-front/sass-tokens/index.scss';

.mini-header {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
}

.mini-header__inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 20px;

  .mini-header--no-logo & {
    justify-content: flex-end;
  }
}
