@import '~@telia-front/sass-tokens/index.scss';

.truncate {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.truncate--fade {
  position: relative;
  text-overflow: clip;

  &.truncate--shown {
    mask-image: linear-gradient(to left, transparent, rgba(0, 0, 0, 1) 40px);
  }
}
