@use 'sass:color';
@use 'sass:math';
@import '~@telia-front/design-tokens/lib/scss/variables.scss';
@import '~@telia-front/design-tokens/lib/scss/telia-light.scss';

// Variables

// Page minimum width
$page-min-width: 320px;

// Page maximum width
$page-max-width: 80.5rem !default; // 1288px

// Breakpoints

// Extra small (320px - 599px)
// Use the modifier "-xs" when defining classes.
$media-width-xs-min: 20rem;
$media-width-xs-max: 37.499rem;

// Small (600px - 799px)
// Use the modifier "-sm" when defining classes.
$media-width-sm-min: 37.5rem;
$media-width-sm-max: 49.999rem;

// Medium (800px - 999px)
// Use the modifier "-md" when defining classes.
$media-width-md-min: 50rem;
$media-width-md-max: 62.499rem;

// Large (1000px - 1288px)
// Use the modifier "-lg" when defining classes.
$media-width-lg-min: 62.5rem;
$media-width-lg-max: 80.499rem;

// Extra large (1288px - 1449px)
// Use the modifier "-xl" when defining classes.
$media-width-xl-min: 80.5rem;
$media-width-xl-max: 90.624rem;

// 2xExtra large (1450px - ...)
// Use the modifier "-xxl" when defining classes.
$media-width-xxl-min: 90.625rem;

// Color definitions

// Main brand colors
$purple: $color-raw-purple-base;
$purple-l: $color-raw-purple-light;
$purple-d: $color-raw-purple-dark;
$purple-deep: $color-raw-purple-deep;
$purple-black: $color-raw-purple-black;

// Commerce flow colors
$green: $color-raw-green-base;
$green-l: $color-raw-green-light;

// Info colors
$blue: $color-raw-blue-base;
$blue-l: $color-raw-blue-light;

// Additional colors
$red: $color-raw-red-base;
$red-l: $color-raw-red-light;

$orange: $color-raw-orange-base;
$orange-l: $color-raw-orange-light;

$pink: $color-raw-pink-base;
$pink-l: $color-raw-pink-light;
$pink-m: $color-raw-pink-medium;

$teal: $color-raw-green-base;
$teal-l: $color-raw-green-light;

// Neutral colors
// Background colors
$white: $color-raw-white-base; // white background
$grey: $color-raw-grey-base; // tables and lines
$grey-l: $color-raw-grey-light; // default background
$grey-m: $color-raw-grey-light; // tables and lines
$grey-b: $color-raw-grey-black; // dark background

// Text colors
$grey-d: $color-raw-grey-dark; // grey text and input field line
$grey-t: $color-raw-black-strong; // text, headings
$black: $color-raw-black-base; // for graphics

// Third party colors
$swedbank: $color-third-party-swedbank-base;
$swedbank-l: $color-third-party-swedbank-light;
$seb: $color-third-party-seb-base;
$seb-l: $color-third-party-seb-light;
$danske: $color-third-party-danske-base;
$danske-l: $color-third-party-danske-light;
$lhv: $color-third-party-lhv-base;
$lhv-l: $color-third-party-lhv-light;
$coop: $color-third-party-coop-base;
$luminor: $color-third-party-luminor-base;

$google: $color-third-party-google-base;
$facebook: $color-third-party-facebook-base;
$microsoft: $color-third-party-microsoft-base;
$spotify: $color-third-party-spotify-base;
$twitter: $color-third-party-twitter-base;
$diil: $color-third-party-diil-base;

// Graph colors
$graph-color-1: $color-graph-1;
$graph-color-2: $color-graph-2;
$graph-color-3: $color-graph-3;
$graph-color-4: $color-graph-4;
$graph-color-5: $color-graph-5;
$graph-color-6: $color-graph-6;
$graph-color-7: $color-graph-7;

$graph-unused-color: var(--color-neutral-bg-subtle) !default;

// Grid
$grid-columns: 12;
$grid-gutter: 24px;
$grid-gutter-small: 8px;
$grid-gutter-smedium: 16px;
$grid-gutter-medium: 48px;
$grid-gutter-medium-xs: 32px;
$grid-gutter-xs: 16px;
$grid-gutter-xsmall: 4px;

$grid-border-color: var(--color-neutral-border-subtle) !default;
$grid-border-light-color: var(--color-neutral-border-subtlest) !default;
$grid-border-dark-color: var(--color-neutral-border) !default;
$grid-border-on-dark-color: var(--color-neutral-border-subtle) !default;

// container
$container-padding: 16px !default;
$container-padding-sm: 24px !default;
$container-padding-lg: calc(24px * 4 / 3) !default;

// Main
$main-padding-top: 30px;

// Slick
$slick-arrow-color: $white !default;
$slick-prev-character: '←' !default;
$slick-next-character: '→' !default;
$slick-dot-character: '•' !default;
$slick-dot-size: 6px !default;
$slick-opacity-default: 0.75 !default;
$slick-opacity-on-hover: 1 !default;
$slick-opacity-not-active: 0.25 !default;

// Custom inputs
$address-search-height: 60px;

// Spinner list pebble rotations
$pebble-rotation-1: 135deg;
$pebble-rotation-2: 110deg;
$pebble-rotation-3: -45deg;
$pebble-rotation-4: 45deg;

$transition-duration: 150ms;
$transition-timing-function: ease;
$transition-default: $transition-duration $transition-timing-function;

// z-index
$zindex: (
  datepicker: 100,
  carousel-arrow: 100,
  tooltip-on-header: 32,
  tooltip: 32,
  datepickers-container-scroll-disabled: 31,
  select-menu: 31,
  notice-on-top: 30,
  modal: 30,
  modal-footer: 30,
  lightbox: 30,
  page-container: 22,
  filter-sidemenu-open: 22,
  header-dropdown: 22,
  progress-card-sidebar-open: 21,
  header: 20,
  progress-card-sidebar: 19,
  suggestion: 19,
  dropdown-menu: 19,
  filter-sidemenu: 19,
  horizontal-filter-dropdown: 19,
  filter-backdrop: 18,
  dropdown-backdrop: 18,
  overlay: 15,
  fixed-buttons: 15,
  collapse-modal: 10,
  header-backdrop: 10,
  header-backdrop-desktop: 9,
  header-backdrop-nav: 9,
  product-comparison-bar: 4,
  scroll-to-top-button: 3,
  form-address-search: 2,
  sidemenu: 2,
  badge: 2,
  affix: 2,
  breadcrumbs: 1,
  carousel: 1,
  search-large: 1,
  carousel-hero: 0,
);

// Base spacing
$base-horizontal-spacing-xs: 15px !default;
$base-horizontal-spacing: 20px !default;

// Typography
// TODO: remove "freesans" after https://github.com/Zenika/alpine-chrome/issues/171 is solved
$font-primary: var(--type-font-family, 'Telia Sans', 'freesans') !default;
$font-primary-ru: $font-primary !default;
$font-secondary: var(--type-font-family-display, 'Telia Sans Heading', 'Telia Sans', 'freesans') !default;

$body-bg: var(--color-neutral-bg-level-1) !default;

$base-font-size: var(--type-font-size) !default;
$base-font-size-xl: var(--type-font-size) !default;
$base-line-height: var(--type-line-height) !default; // 16/22;
$base-line-height-xl: var(--type-line-height) !default; // 16/22;
$font-weight-base: 400 !default;

$font-size-small: var(--type-small-font-size) !default; // 14px
$font-size-small-xl: var(--type-small-font-size) !default; // 14px
$line-height-small: var(--type-small-line-height) !default; // 14/20
$line-height-small-xl: var(--type-small-line-height) !default; // 14/20

$font-size-tiny: var(--type-tiny-font-size) !default; // 12px
$font-size-tiny-xl: var(--type-tiny-font-size) !default; // 12px
$line-height-tiny: var(--type-tiny-line-height) !default; // 12/16
$line-height-tiny-xl: var(--type-tiny-line-height) !default; // 12/16

$text-color-base: var(--color-neutral-text) !default;
$text-color-primary: $color-primary-1 !default;
$text-color-white: $white !default;
$text-color-attention: $color-attention-3 !default;
$text-color-light: $color-raw-black-medium !default;
$text-color-dark-primary: $color-raw-white-strong !default;
$text-color-dark-inactive: $color-raw-white-dull !default;
$text-color-dark-light: $color-raw-white-medium !default;

// Heading
$heading-font: $font-secondary !default;
$heading-transform: none !default;
$heading-weight: 700 !default;

$h1-size: var(--type-h1-font-size) !default; // 32px
$h1-size-sm: var(--type-h1-sm-font-size) !default; // 44px
$h1-size-xl: var(--type-h1-xl-font-size) !default; // 56px
$h1-line-height: var(--type-h1-line-height) !default; // 32/34
$h1-line-height-sm: var(--type-h1-sm-line-height) !default; // 44/46
$h1-line-height-xl: var(--type-h1-xl-line-height) !default; // 56/56
$h1-transform: var(--type-h1-text-transform) !default;

$h2-size: var(--type-h2-font-size) !default; // 26px
$h2-size-sm: var(--type-h2-sm-font-size) !default; // 32px
$h2-size-xl: var(--type-h2-xl-font-size) !default; // 40px
$h2-line-height: var(--type-h2-line-height) !default; // 26/30
$h2-line-height-sm: var(--type-h2-sm-line-height) !default; // 32/34
$h2-line-height-xl: var(--type-h2-xl-line-height) !default; // 40/44

$h3-size: var(--type-h3-font-size) !default; // 24px
$h3-size-sm: var(--type-h3-sm-font-size) !default; // 24px
$h3-size-xl: var(--type-h3-xl-font-size) !default; // 30px
$h3-line-height: var(--type-h3-line-height) !default; // 24/28
$h3-line-height-sm: var(--type-h3-sm-line-height) !default; // 24/28
$h3-line-height-xl: var(--type-h3-xl-line-height) !default; // 30/34
$h3-font-family: var(--type-h3-font-family) !default;

$h4-size: var(--type-h4-font-size) !default; // 20px
$h4-size-xl: var(--type-h4-xl-font-size) !default; // 22px
$h4-line-height: var(--type-h4-line-height) !default; // 20/26
$h4-line-height-xl: var(--type-h4-xl-line-height) !default; // 22/28

$h5-size: var(--type-h5-font-size) !default; // 18px
$h5-size-xl: var(--type-h5-font-size) !default; // 18px
$h5-line-height: var(--type-h5-line-height) !default; // 18/24
$h5-line-height-xl: var(--type-h5-line-height) !default; // 18/24

// Anchor
$anchor-weight: 700 !default;
$anchor-color: var(--color-brand-primary-text) !default;
$anchor-hover-color: var(--color-brand-primary-text-hover) !default;
$anchor-text-color-hover-color: var(--color-brand-primary-text-hover) !default;

// Badge
$badge-font-size: var(--type-tiny-font-size) !default;
$badge-line-height: 1rem !default; // must be in rems so it could be used in calc
$badge-icon-size: 1rem !default;
$badge-background: var(--color-brand-primary-bg-strong) !default;
$badge-color: var(--color-brand-primary-text-onbg-strong) !default;
$badge-inactive-background: var(--color-neutral-bg-subtle) !default;
$badge-inactive-color: var(--color-neutral-text) !default;
$badge-recommended-background: var(--color-brand-expressive-bg-subtle) !default;
$badge-recommended-color: var(--color-brand-expressive-text) !default;
$badge-selected-background: var(--color-brand-primary-bg-strong) !default;
$badge-selected-color: var(--color-brand-primary-text-onbg-strong) !default;
$badge-success-background: var(--color-success-bg-subtle) !default;
$badge-success-color: var(--color-success-text) !default;

$badge-font-size-small: 0.625rem !default;
$badge-font-height-small: 1.1 !default;
$badge-icon-size-small: 0.625rem !default;
$badge-font-weight-large: 400 !default;
$badge-font-weight-large-sm: 700 !default;
$badge-font-size-large: 1.25rem !default;
$badge-font-size-large-sm: 1rem !default;
$badge-line-height-large-sm: 1 !default;
$badge-size-large: 32px !default;
$badge-size-large-line-height: 1.1 !default;
$badge-size-large-icon-height: 1.375rem !default;
$badge-size-large-icon-width: 1.25rem !default;
$badge-size-large-icon-size-sm: 1rem !default;

$badge-font-size-medium: var(--type-small-font-size) !default;
$badge-line-height-medium: var(--type-small-line-height) !default;

$badge-three-digit-font-weight: 700 !default;
$badge-three-digit-large-font-size: 1rem !default;
$badge-three-digit-large-line-height: 1.5 !default;
$badge-three-digit-large-line-height-sm: 1.8334 !default;
$badge-three-digit-large-font-size-sm: 0.75rem !default;
$badge-three-digit-large-padding: 4px !default;
$badge-three-digit-large-padding-sm: 1px !default;
$badge-three-digit-small-font-size: 0.5rem !default;
$badge-three-digit-small-line-height: 1.5 !default;
$badge-three-digit-small-padding: 2px !default;

$badge-two-digit-small-padding: 2px !default;
$badge-two-digit-small-line-height: 1.2 !default;
$badge-two-digit-large-padding: 4px !default;
$badge-two-digit-large-line-height: 1.2 !default;
$badge-two-digit-large-padding-sm: 2px !default;
$badge-two-digit-large-line-height-sm: 1.25 !default;

// Banner
$banner-border-radius: 16px !default;
$banner-border-radius-small: 8px !default;
$banner-border-radius-xsmall: 8px !default;
$banner-padding-none: 0 !default;
$banner-padding-xxsmall: 8px !default;
$banner-padding-xsmall: 12px !default;
$banner-padding-small: 16px !default;
$banner-padding-medium: 24px !default;
$banner-padding-large: 32px !default;
$banner-padding-xlarge: 48px !default;
$banner-padding-xxlarge: 64px !default;
$banner-margin-none: 0 !default;
$banner-margin-xxsmall: 8px !default;
$banner-margin-xsmall: 12px !default;
$banner-margin-small: 16px !default;
$banner-margin-medium: 24px !default;
$banner-margin-large: 32px !default;
$banner-margin-xlarge: 48px !default;
$banner-margin-xxlarge: 64px !default;

$banner-paddings: (
  none $banner-padding-none,
  xxsmall $banner-padding-xxsmall,
  xsmall $banner-padding-xsmall,
  small $banner-padding-small,
  medium $banner-padding-medium,
  large $banner-padding-large,
  xlarge $banner-padding-xlarge,
  xxlarge $banner-padding-xxlarge
);

$banner-margins: (
  none $banner-margin-none,
  xxsmall $banner-margin-xxsmall,
  xsmall $banner-margin-xsmall,
  small $banner-margin-small,
  medium $banner-margin-medium,
  large $banner-margin-large,
  xlarge $banner-margin-xlarge,
  xxlarge $banner-margin-xxlarge
);

// Breadcrumbs
$breadcrumbs-link-color: var(--color-brand-primary-text) !default;
$breadcrumbs-icon-color: var(--color-brand-primary-text) !default;
$breadcrumbs-link-hover-color: var(--color-brand-primary-text-hover) !default;

// Button variants
// necessary for mixin atm
$btn-min-width: 150px !default;
$btn-min-height: 48px !default;
$btn-font-size: var(--type-font-size) !default;
$btn-line-height: 1.125 !default; // 18px
$btn-text-transform: none !default;
$btn-border-width: 1px !default;
$btn-padding: 6px !default;
$btn-border-radius: 24px !default;
$btn-min-vertical-padding: 6 - $btn-border-width !default;
$btn-min-horizontal-padding: 24 - $btn-border-width !default;
$btn-letter-spacing: normal !default;
$btn-icon-size: 1.5rem !default;
$btn-spinner-size: 1.5rem !default;

$btn-small-min-height: 32px !default;
$btn-small-font-size: var(--type-small-font-size) !default;
$btn-small-line-height: 1.2 !default;
$btn-small-padding: 2px !default;
$btn-small-min-horizontal-padding: 16 - $btn-border-width !default;
$btn-small-border-radius: 16px !default;
$btn-small-icon-padding: 16px - $btn-border-width !default;
$btn-small-icon-spacing: 6px !default;
$btn-small-inner-min-height: 26px !default;
$btn-small-icon-size: 1rem !default;
$btn-small-spinner-size: 1rem !default;

$btn-icon-large-size: 1.5rem !default;
$btn-large-font-size: 1rem !default;

$btn-icon-min-width: $btn-min-height;
$btn-icon-wrapper-margin-left: -4px !default;
$btn-icon-wrapper-margin-right: -4px !default;
$btn-icon-outer-padding: 19px - $btn-min-horizontal-padding !default;

$btn-link-disabled-pointer-events: auto !default;
$btn-link-spinner-size: 1.5rem !default;
$btn-link-inner-loading-margin: calc(#{$btn-link-spinner-size} + 4px) !default;
$btn-link-small-spinner-size: 1rem !default;
$btn-link-small-inner-loading-margin: calc(#{$btn-link-small-spinner-size} + 4px) !default;

$btn-outer-swed-color: $white !default;
$btn-outer-swed-background: #d34300 !default;
$btn-outer-swed-background-hover: #ae3700 !default;
$btn-outer-swed-border: #d34300 !default;
$btn-outer-swed-border-hover: #ae3700 !default;
$btn-outer-swed-hover-color: $white !default;
$btn-outer-swed-pressed-color: $white !default;
$btn-outer-swed-pressed-background: #882b00 !default;

$btn-outer-seb-color: $white !default;
$btn-outer-seb-background: #3f8510 !default;
$btn-outer-seb-background-hover: #336d0d !default;
$btn-outer-seb-border: #3f8510 !default;
$btn-outer-seb-border-hover: #336d0d !default;
$btn-outer-seb-hover-color: $white !default;
$btn-outer-seb-pressed-color: $white !default;
$btn-outer-seb-pressed-background: #28540a !default;

$btn-outer-luminor-color: $white !default;
$btn-outer-luminor-background: #481335 !default;
$btn-outer-luminor-background-hover: #370e28 !default;
$btn-outer-luminor-border: #481335 !default;
$btn-outer-luminor-border-hover: #370e28 !default;
$btn-outer-luminor-hover-color: $white !default;
$btn-outer-luminor-pressed-color: $white !default;
$btn-outer-luminor-pressed-background: #200818 !default;

// Deprecared, as Danske Bank does not exist anymore.
$btn-outer-danske-color: $white !default;
$btn-outer-danske-background: $danske !default;
$btn-outer-danske-background-hover: $danske !default;
$btn-outer-danske-border: $danske !default;
$btn-outer-danske-border-hover: $danske !default;
$btn-outer-danske-hover-color: $white !default;
$btn-outer-danske-pressed-color: $white !default;
$btn-outer-danske-pressed-background: $danske !default;

$btn-outer-coop-color: $white !default;
$btn-outer-coop-background: #004bc5 !default;
$btn-outer-coop-background-hover: #002f7d !default;
$btn-outer-coop-border: #004bc5 !default;
$btn-outer-coop-border-hover: #002f7d !default;
$btn-outer-coop-hover-color: $white !default;
$btn-outer-coop-pressed-color: $white !default;
$btn-outer-coop-pressed-background: #002665 !default;

$btn-outer-lhv-color: $white !default;
$btn-outer-lhv-background: #222221 !default;
$btn-outer-lhv-background-hover: #1d1d1d !default;
$btn-outer-lhv-border: #222221 !default;
$btn-outer-lhv-border-hover: #1d1d1d !default;
$btn-outer-lhv-hover-color: $white !default;
$btn-outer-lhv-pressed-color: $white !default;
$btn-outer-lhv-pressed-background: #111110 !default;

$btn-outer-google-color: $white !default;
$btn-outer-google-background: #1a73e8 !default;
$btn-outer-google-background-hover: #155ebd !default;
$btn-outer-google-border: #1a73e8 !default;
$btn-outer-google-border-hover: #155ebd !default;
$btn-outer-google-hover-color: $white !default;
$btn-outer-google-pressed-color: $white !default;
$btn-outer-google-pressed-background: #104992 !default;

$btn-outer-facebook-color: $white !default;
$btn-outer-facebook-background: #0866ff !default;
$btn-outer-facebook-background-hover: #0756d8 !default;
$btn-outer-facebook-border: #0866ff !default;
$btn-outer-facebook-border-hover: #0756d8 !default;
$btn-outer-facebook-hover-color: $white !default;
$btn-outer-facebook-pressed-color: $white !default;
$btn-outer-facebook-pressed-background: #0543a9 !default;

$btn-outer-microsoft-color: $white !default;
$btn-outer-microsoft-background: #007cb6 !default;
$btn-outer-microsoft-background-hover: #006696 !default;
$btn-outer-microsoft-border: #007cb6 !default;
$btn-outer-microsoft-border-hover: #006696 !default;
$btn-outer-microsoft-hover-color: $white !default;
$btn-outer-microsoft-pressed-color: $white !default;
$btn-outer-microsoft-pressed-background: #004f74 !default;

$btn-outer-spotify-color: $white !default;
$btn-outer-spotify-background: #15873e !default;
$btn-outer-spotify-background-hover: #116f32 !default;
$btn-outer-spotify-border: #15873e !default;
$btn-outer-spotify-border-hover: #116f32 !default;
$btn-outer-spotify-hover-color: $white !default;
$btn-outer-spotify-pressed-color: $white !default;
$btn-outer-spotify-pressed-background: #0e5727 !default;

$btn-outer-twitter-color: $white !default;
$btn-outer-twitter-background: #1a93dd !default;
$btn-outer-twitter-background-hover: #167ab8 !default;
$btn-outer-twitter-border: #1a93dd !default;
$btn-outer-twitter-border-hover: #167ab8 !default;
$btn-outer-twitter-hover-color: $white !default;
$btn-outer-twitter-pressed-color: $white !default;
$btn-outer-twitter-pressed-background: #126497 !default;

$btn-outer-diil-color: $white !default;
$btn-outer-diil-background: #00847e !default;
$btn-outer-diil-background-hover: #006c67 !default;
$btn-outer-diil-border: #00847e !default;
$btn-outer-diil-border-hover: #006c67 !default;
$btn-outer-diil-hover-color: $white !default;
$btn-outer-diil-pressed-color: $white !default;
$btn-outer-diil-pressed-background: #02534f !default;

$btn-primary-background: var(--color-brand-primary-bg-strong) !default;
$btn-primary-background-hover-color: var(--color-brand-primary-bg-strong-hover) !default;
$btn-primary-border-color: var(--color-brand-primary-bg-strong) !default;
$btn-primary-border-hover-color: var(--color-brand-primary-bg-strong-hover) !default;
$btn-primary-color: var(--color-brand-primary-text-onbg-strong) !default;
$btn-primary-hover-color: var(--color-brand-primary-text-onbg-strong) !default;
$btn-primary-pressed-color: var(--color-brand-primary-text-onbg-strong) !default;
$btn-primary-pressed-background: var(--color-brand-primary-bg-strong-pressed) !default;

$btn-primary-link-color: var(--color-brand-primary-text) !default;
$btn-primary-link-hover-color: var(--color-brand-primary-text-hover) !default;
$btn-primary-link-pressed-color: var(--color-brand-primary-text-hover) !default;

$btn-employee-color: var(--color-employee-text-onbg-strong) !default;
$btn-employee-hover-color: var(--color-employee-text-onbg-strong) !default;
$btn-employee-background: var(--color-employee-bg-strong) !default;
$btn-employee-background-hover: var(--color-employee-bg-strong-hover) !default;
$btn-employee-border-color: var(--color-employee-bg-strong) !default;
$btn-employee-border-hover-color: var(--color-employee-bg-strong-hover) !default;
$btn-employee-link-color: var(--color-employee-text) !default;
$btn-employee-link-hover-color: var(--color-employee-text-hover) !default;
$btn-employee-link-pressed-color: var(--color-employee-text-hover) !default;
$btn-employee-pressed-color: var(--color-employee-text-onbg-strong) !default;
$btn-employee-pressed-background: var(--color-employee-bg-strong-pressed) !default;

$btn-employee-secondary-color: var(--color-employee-text) !default;
$btn-employee-secondary-color-hover: var(--color-employee-text-onbg-strong) !default;
$btn-employee-secondary-background: transparent !default;
$btn-employee-secondary-background-hover: var(--color-employee-bg-strong) !default;
$btn-employee-secondary-border: var(--color-employee-border) !default;
$btn-employee-secondary-border-hover: var(--color-employee-bg-strong) !default;
$btn-employee-secondary-box-shadow-hover: null !default;
$btn-employee-secondary-pressed-color: var(--color-employee-text-onbg-strong) !default;
$btn-employee-secondary-pressed-background: var(--color-employee-bg-strong-hover) !default;

$btn-withdrawal-color: var(--color-error-text-onbg-strong) !default;
$btn-withdrawal-background: var(--color-error-bg-strong) !default;
$btn-withdrawal-background-hover: var(--color-error-bg-strong-hover) !default;
$btn-withdrawal-border-color: var(--color-error-bg-strong) !default;
$btn-withdrawal-border-hover-color: var(--color-error-bg-strong-hover) !default;
$btn-withdrawal-link-color: var(--color-error-text) !default;
$btn-withdrawal-link-hover-color: var(--color-error-text-hover) !default;
$btn-withdrawal-link-pressed-color: var(--color-error-text-hover) !default;
$btn-withdrawal-pressed-background: var(--color-error-bg-strong-pressed) !default;

$btn-withdrawal-secondary-color: var(--color-error-text) !default;
$btn-withdrawal-secondary-background: transparent !default;
$btn-withdrawal-secondary-background-hover: var(--color-error-bg-strong) !default;
$btn-withdrawal-secondary-border: var(--color-error-border) !default;
$btn-withdrawal-secondary-border-hover: var(--color-error-bg-strong) !default;
$btn-withdrawal-secondary-box-shadow-hover: null !default;
$btn-withdrawal-secondary-pressed-background: var(--color-error-bg-strong-hover) !default;

$btn-expressive-color: var(--color-brand-expressive-text-onbg-strong) !default;
$btn-expressive-background: var(--color-brand-expressive-bg-strong) !default;
$btn-expressive-background-hover: var(--color-brand-expressive-bg-strong-hover) !default;
$btn-expressive-border-color: var(--color-brand-expressive-bg-strong) !default;
$btn-expressive-border-hover-color: var(--color-brand-expressive-bg-strong-hover) !default;
$btn-expressive-link-color: var(--color-brand-expressive-text) !default;
$btn-expressive-link-hover-color: var(--color-brand-expressive-text-hover) !default;
$btn-expressive-link-pressed-color: var(--color-brand-expressive-text-hover) !default;
$btn-expressive-pressed-color: var(--color-brand-expressive-text-onbg-strong) !default;
$btn-expressive-pressed-background: var(--color-brand-expressive-bg-strong-pressed) !default;

$btn-secondary-color: var(--color-brand-primary-text) !default;
$btn-secondary-color-hover: var(--color-brand-primary-text-onbg-strong) !default;
$btn-secondary-background: transparent !default;
$btn-secondary-background-hover: var(--color-brand-primary-bg-strong) !default;
$btn-secondary-icon-only-background-hover: var(--color-brand-primary-bg-strong) !default;
$btn-secondary-border: var(--color-brand-primary-border) !default;
$btn-secondary-border-hover: var(--color-brand-primary-bg-strong) !default;
$btn-secondary-icon-only-border: $btn-secondary-border !default;
$btn-secondary-icon-only-border-hover: var(--color-brand-primary-bg-strong) !default;
$btn-secondary-box-shadow-hover: null !default;
$btn-secondary-pressed-color: var(--color-brand-primary-text-onbg-strong) !default;
$btn-secondary-pressed-background: var(--color-brand-primary-bg-strong-hover) !default;

$btn-text-color: var(--color-neutral-text-onbg-strong) !default;
$btn-text-color-background-color: var(--color-neutral-bg-strong) !default;
$btn-text-color-background-hover-color: var(--color-neutral-bg-strong-hover) !default;
$btn-text-color-background-pressed-color: var(--color-neutral-bg-strong-pressed) !default;
$btn-text-color-border-hover-color: var(--color-neutral-bg-strong-hover) !default;
$btn-text-color-link-color: var(--color-neutral-text) !default;
$btn-text-color-link-hover-color: var(--color-brand-primary-text-hover) !default;

$btn-disabled-color: var(--color-disabled-text) !default;
$btn-disabled-background-color: var(--color-disabled-bg) !default;
$btn-disabled-border-color: transparent !default;
$btn-disabled-link-color: $btn-disabled-color !default;

// Campaign
$campaign-collapse-icon-color: $purple !default;
$campaign-selected-color: $green !default;
$campaign-background-color: $white !default;
$campaign-after-line-color: $grey-l !default;
$campaign-input-background-color: $grey-l !default;

// Check
$check-border-color: var(--color-neutral-border) !default;
$check-background: transparent !default;
$check-disabled-text-color: var(--color-disabled-text) !default;
$check-disabled-border-color: var(--color-disabled-border) !default;
$check-disabled-background-color: var(--color-disabled-bg) !default;
$check-disabled-indicator-color: var(--color-disabled-text) !default;
$check-disabled-checked-background-color: var(--color-disabled-bg) !default;
$check-disabled-checked-border-color: var(--color-disabled-border) !default;
$check-indicator-color: var(--color-brand-primary-text-onbg-strong) !default;
$check-employee-indicator-color: var(--color-employee-text-onbg-strong) !default;
$check-employee-indicator-background-color: var(--color-employee-bg-strong) !default;
$check-employee-indicator-border-color: var(--color-employee-border) !default;
$check-employee-subtle-background-color: var(--color-employee-bg-subtlest) !default;
$check-checked-background: var(--color-brand-primary-bg-strong) !default;
$check-checked-border: var(--color-brand-primary-border) !default;
$check-indicator-disabled-opacity: 1 !default;

$check-indicator-size: 1.5rem !default;
$check-indicator-size-medium: 1.25rem !default;
$check-indicator-size-small: 1rem !default;

$check-icon-size: 1.25rem !default;
$check-icon-size-medium: 1rem !default;
$check-icon-size-small: 0.75rem !default;

$check-content-line-height: var(--type-line-height) !default;
$check-content-medium-line-height: var(--type-small-line-height) !default;
$check-content-small-line-height: var(--type-tiny-line-height) !default;

$check-content-font-size: var(--type-font-size) !default;
$check-content-medium-font-size: var(--type-small-font-size) !default;
$check-content-small-font-size: var(--type-tiny-font-size) !default;

// Chip
$chip-border-color: var(--color-neutral-border-subtle) !default;
$chip-hover-color: var(--color-brand-primary-text-hover) !default;

// Radio
$radio-border-color: var(--color-neutral-border) !default;
$radio-disabled-color: var(--color-disabled-text) !default;
$radio-employee-background: var(--color-employee-bg-subtlest) !default;
$radio-indicator-bg: transparent !default;
$radio-indicator-checked-color: var(--color-brand-primary-text-onbg-strong) !default;
$radio-indicator-checked-bg: var(--color-brand-primary-bg-strong) !default;
$radio-indicator-checked-border: var(--color-brand-primary-border) !default;
$radio-indicator-employee-color: var(--color-employee-text-onbg-strong) !default;
$radio-indicator-employee-bg: var(--color-employee-bg-strong) !default;
$radio-indicator-employee-border: var(--color-employee-border) !default;
$radio-indicator-disabled-color: var(--color-disabled-text) !default;
$radio-indicator-disabled-checked-bg: var(--color-disabled-bg) !default;
$radio-indicator-disabled-checked-border: var(--color-disabled-border) !default;
$radio-indicator-disabled-bg: var(--color-disabled-bg) !default;
$radio-indicator-disabled-border: var(--color-disabled-border) !default;
$radio-indicator-disabled-opacity: 1 !default;

$radio-indicator-size: 1.5rem !default;
$radio-indicator-size-medium: 1.25rem !default;
$radio-indicator-size-small: 1rem !default;

$radio-content-line-height: var(--type-line-height) !default;
$radio-content-medium-line-height: var(--type-small-line-height) !default;
$radio-content-small-line-height: var(--type-tiny-line-height) !default;

$radio-content-font-size: var(--type-font-size) !default;
$radio-content-medium-font-size: var(--type-small-font-size) !default;
$radio-content-small-font-size: var(--type-tiny-font-size) !default;

$radio-indicator-inner-size: 0.625rem !default;
$radio-indicator-inner-size-medium: 0.5rem !default;
$radio-indicator-inner-size-small: 0.375rem !default;

// ChoiceGroup
$choice-group-purpose-agreement-background: var(--color-neutral-bg-level-2) !default;
$choice-group-purpose-agreement-box-shadow: $black !default;
$choice-group-purpose-agreement-invalid-border: var(--color-error-border) !default;
$choice-group-purpose-agreement-border-radius: 3px !default;
$choice-group-form-helper-help: var(--color-neutral-text) !default;
$choice-group-label-font-size: 14px !default;

// ClearButton
$clear-button-color: var(--color-neutral-graphic) !default;
$clear-button-hover: var(--color-brand-primary-text-hover) !default;

// CloseButton
$close-button-color: var(--color-neutral-graphic) !default;
$close-button-hover-color: var(--color-brand-primary-text-hover) !default;
$close-button-background: transparent !default;
$close-button-font-size: 1.5rem !default;

$close-button-modal-size: 40px !default;
$close-button-modal-background: var(--color-neutral-bg-level-3) !default;

// Horizontal progress
$horizontal-progress-title-color: var(--color-neutral-text-subtle) !default;
$horizontal-progress-title-color-current-done: var(--color-neutral-text) !default;
$horizontal-progress-current-color: var(--color-brand-primary-bg-strong) !default;
$horizontal-progress-indicator-line-inactive-color: var(--color-neutral-border-subtlest) !default;
$horizontal-progress-indicator-line-success-color: var(--color-success-border) !default;
$horizontal-progress-content-border-color: var(--color-neutral-border-subtlest) !default;

// Sorting-menu
$sorting-menu-background-color: $white !default;
$sorting-menu-link-hover: $purple !default;
$sorting-menu-link-current-color: $purple !default;
$sorting-menu-link-add-color: $purple !default;
$sorting-menu-link-add-hover-color: $purple-l !default;

// Large-search
$large-search-submit-color: $green !default;
$large-search-submit-hover-color: $green-l !default;

// Carousel
$carousel-dots-with-arrows-bottom: 20px !default;
$carousel-dots-with-small-arrows-bottom: 12px !default;
$carousel-dots-active-color: var(--color-brand-primary-bg-strong) !default;
$carousel-dots-color: var(--color-neutral-bg-subtle) !default;

// Accordion
$accordion-arrow-hover-color: var(--color-brand-primary-text-hover) !default;
$accordion-arrow-is-open-color: var(--color-brand-primary-text) !default;
$accordion-arrow-left-sm: auto !default;
$accordion-body-box-shadow: none !default;
$accordion-border-color: var(--color-neutral-border-subtlest) !default;
$accordion-content-padding: 0 16px 16px 16px !default;
$accordion-content-padding-sm: 0 16px 16px 16px !default;
$accordion-header-inner-box-shadow: none !default;
$accordion-header-inner-min-height: 56px !default;
$accordion-header-inner-min-height-sm: 56px !default;
$accordion-header-inner-padding-top: 16px !default;
$accordion-header-inner-padding-bottom: 16px !default;
$accordion-header-inner-padding-right: 48px !default;
$accordion-header-inner-padding-left: 16px !default;
$accordion-header-inner-padding-right-sm: 48px !default;
$accordion-header-inner-padding-left-sm: 16px !default;
$accordion-header-inner-with-icon-padding-top: 16px !default;
$accordion-header-inner-with-icon-padding-bottom: 16px !default;
$accordion-header-inner-no-content-padding-left: 16px !default;
$accordion-header-inner-nth-last-of-type-2-padding-right: 16px !default;
$accordion-meta-padding: 8px 8px 0 0 !default;
$accordion-title-font-weight: 700 !default;
$accordion-title-padding: 0 16px 0 0 !default;
$accordion-title-padding-sm: 0 16px 0 0 !default;
$accordion-title-hover-color: var(--color-brand-primary-text-hover) !default;
$accordion-title-open-color: var(--color-brand-primary-text) !default;
$accordion-transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
$accordion-label-inactive: var(--color-disabled-text);
$accordion-icon-inactive: var(--color-disabled-text);
$accordion-employee-bg: var(--color-employee-bg-subtlest) !default;
$accordion-employee-open-color: var(--color-employee-text) !default;
$accordion-employee-hover-color: var(--color-employee-text-hover) !default;
$accordion-type-card-header-background: var(--color-neutral-bg-level-2) !default;
$accordion-type-card-content-border: var(--color-neutral-border-subtlest) !default;
$accordion-type-card-content-padding: 24px !default;

// Elevations
$elevation-0: 0 0 0 0 rgba(0, 0, 0, 0.1) !default;
$elevation-1: 0 1px 2px rgba(0, 0, 0, 0.1) !default;
$elevation-2: 0 2px 4px rgba(0, 0, 0, 0.1) !default;
$elevation-3: 0 4px 8px rgba(0, 0, 0, 0.1) !default;
$elevation-4: 0 8px 16px rgba(0, 0, 0, 0.1) !default;
$elevation-5: 0 12px 24px rgba(0, 0, 0, 0.1) !default;

// Search
$search-textfield-label-color: var(--color-neutral-text-subtle) !default;
$search-action-hover-color: var(--color-brand-primary-text-hover) !default;
$search-action-disabled-color: var(--color-disabled-text) !default;

// Select
$select-primary-color: var(--color-neutral-text) !default;
$select-secondary-color: var(--color-neutral-border) !default;
$select-secondary-text-color: var(--color-neutral-text) !default;
$select-menu-color: var(--color-neutral-bg-level-3) !default;
$select-option-color: $grey !default; // removed
$select-selected-color: $color-selected-1 !default; // removed
$select-disabled-color: var(--color-disabled-text) !default;
$select-disabled-border-color: var(--color-disabled-border) !default;
$select-error-border-color: var(--color-error-border) !default;
$select-employee-selected-color: var(--color-employee-text) !default;
$select-employee-focused-color: var(--color-employee-text-hover) !default;
$select-employee-background-color: var(--color-employee-bg-subtlest) !default;
$select-focus-color: var(--color-focus-outline) !default;
$select-focus-label-color: var(--color-neutral-text) !default;
$select-sub-label-color: var(--color-neutral-text-subtle) !default;
$select-option-selected-font-weight: 700 !default;
$select-box-shaddow: $elevation-5 !default;
$select-disabled-opacity: 1 !default;

$select-option-border-color: var(--color-neutral-border-subtle) !default;
$select-option-selected-color: var(--color-brand-primary-text) !default;
$select-option-focused-color: var(--color-brand-primary-text-hover) !default;
$select-option-focused-background-color: var(--color-neutral-bg-hover) !default;

$select-option-employee-color: var(--color-employee-text) !default;
$select-option-employee-focused-color: var(--color-employee-text-hover) !default;

// Selector
$selector-background: transparent !default;
$selector-border-radius: 4px !default;
$selector-border-color: var(--color-neutral-border) !default;

$selector-border-hover-color: var(--color-brand-primary-border) !default;
$selector-border-hover-color-custom: var(--color-brand-primary-border) !default;

$selector-disabled-color: var(--color-disabled-text) !default;
$selector-disabled-border-color: var(--color-disabled-border) !default;
$selector-disabled-background: transparent !default;
$selector-disabled-selected-color: var(--color-disabled-text) !default;
$selector-disabled-selected-background: var(--color-disabled-bg) !default;

$selector-inactive-text-color: var(--color-neutral-text) !default;
$selector-inactive-color: var(--color-neutral-text-subtle) !default;

$selector-form-helper-help: var(--color-neutral-text) !default;

$selector-selected-color: var(--color-brand-primary-text-onbg-strong) !default;
$selector-selected-background-color: var(--color-brand-primary-bg-strong) !default;
$selector-selected-border-color: var(--color-brand-primary-border) !default;
$selector-selected-background-hover-color: var(--color-brand-primary-bg-strong-hover) !default;
$selector-selected-border-hover-color: var(--color-brand-primary-bg-strong-hover) !default;
$selector-selected-color-border-color: $selector-selected-border-color !default;

$selector-color-employee: var(--color-employee-text) !default;
$selector-border-hover-color-employee: var(--color-employee-bg-strong-hover) !default;
$selector-selected-color-employee: var(--color-employee-text-onbg-strong) !default;
$selector-selected-background-color-employee: var(--color-employee-bg-strong) !default;
$selector-selected-border-color-employee: var(--color-employee-bg-strong) !default;
$selector-selected-hover-color-employee: var(--color-employee-text-onbg-strong) !default;
$selector-selected-background-hover-color-employee: var(--color-employee-bg-strong-hover) !default;
$selector-selected-border-hover-color-employee: var(--color-employee-bg-strong-hover) !default;

$selector-font-size: 16px !default;
$selector-line-height: 22px !default;
$selector-padding-vertical: 10px !default;
$selector-padding-horizontal: 16px !default;
$selector-min-height: 48px !default;

$selector-small-font-size: 14px !default;
$selector-small-line-height: 20px !default;
$selector-small-padding-vertical: 4px !default;
$selector-small-padding-horizontal: 8px !default;
$selector-small-min-height: 32px !default;

$selector-large-font-size: 18px !default;
$selector-large-line-height: 24px !default;
$selector-large-padding-vertical: 10px !default;
$selector-large-padding-horizontal: 16px !default;
$selector-large-min-height: 60px !default;

$selector-xlarge-font-size: 20px !default;
$selector-xlarge-line-height: 26px !default;
$selector-xlarge-padding-vertical: 26px !default;
$selector-xlarge-padding-horizontal: 20px !default;
$selector-xlarge-size: 80px !default;
$selector-xlarge-min-height: 80px !default;

// FormHelper
$form-helper-check-length: 36px !default;
$form-helper-size: 12px !default;
$form-helper-help-color: var(--color-info-graphic) !default;
$form-helper-valid-color: var(--color-success-text) !default;
$form-helper-valid-icon-color: var(--color-success-graphic) !default;
$form-helper-error-color: var(--color-error-text) !default;
$form-helper-error-icon-color: var(--color-error-graphic) !default;
$form-helper-error-font-weight: 400 !default;

// Icon
$icon-font-size-xxs: 1rem !default;
$icon-font-size-xs: 1.5rem !default;
$icon-font-size-sm: 2rem !default;
$icon-font-size-md: 2.5rem !default;
$icon-font-size-lg: 3rem !default;
$icon-font-size-xl: 3.5rem !default;
$icon-font-size-xxl: 4rem !default;
$icon-font-size-xxxl: 6rem !default;

// Iframe
$iframe-consent-background: var(--color-backdrop) !default;
$iframe-consent-inner-background: var(--color-neutral-bg-level-3) !default;

// Main
$main-header-padding-top: 100px !default;
$main-header-padding-top-lg: 110px !default;
$main-content-padding: 30px 0 100px !default;

// Mini Form
$mini-form-button-top: 12px !default;
$mini-form-hide-label-button-top: 5px !default;

// Modal
$modal-backdrop-color: var(--color-backdrop) !default;
$modal-border-radius: 8px !default;
$modal-box-shadow: $elevation-5 !default;
$modal-horizontal-spacing-xs: 16px !default;
$modal-background-color: var(--color-neutral-bg-level-3) !default;
$modal-footer-background-color: var(--color-neutral-bg-level-2) !default;

// Text
$text-h1-margin-bottom: 0.5em !default;

// Textfield
$textfield-label-size: 12px !default;
$textfield-label-padding: 0 !default;
$textfield-floating-label-size: 16px !default;
$textfield-label-start-position: 17px !default;
$textfield-height: 32px !default;
$textfield-weight: 400 !default;
$textfield-label-visible-height: 17px !default;
$textfield-border-radius: 0 0 3px 3px !default;
$textfield-border-top-position: 7px !default;
$textfield-box-shadow: none !default;
$textfield-background: none !default;
$textfield-dirty-border-color: var(--color-neutral-border) !default;
$textfield-form-helper-margin-top: 5px !default;
$textfield-large-font-family: $font-primary;

$textfield-employee-subtle-background-color: var(--color-employee-bg-subtlest) !default;
$textfield-focused-border-color: var(--color-focus-outline) !default;
$textfield-border-color: var(--color-neutral-border) !default;
$textfield-disabled-color: var(--color-disabled-text) !default;
$textfield-disabled-border-color: var(--color-disabled-border) !default;
$textfield-error-border-color: var(--color-error-border) !default;
$textfield-border: 1px solid $textfield-border-color !default;
$textfield-color: var(--color-neutral-text) !default;
$textfield-floating-label-color: var(--color-neutral-text-subtle) !default;
$textfield-dirty-text-color: var(--color-neutral-text) !default;
$textfield-focused-color: var(--color-neutral-text) !default;
$textfield-focused-border-color: var(--color-focus-outline) !default;
$textfield-helper-color: var(--color-neutral-text) !default;

// Filefield
$filefield-label-color: var(--color-neutral-text-subtle) !default;
$filefield-floating-label-line-height: 30px !default;
$filefield-label-line-height: 16px !default;

// Passfield
$passfield-button-hover-color: var(--color-brand-primary-text-hover) !default;

// Textarea
$textarea-small-height: $textfield-height !default;

// Tabs
$tabs-text-color: var(--color-neutral-text) !default;
$tabs-hover-text-color: var(--color-brand-primary-text-hover) !default;
$tabs-selected-text-color: var(--color-brand-primary-text) !default;
$tabs-border-color: var(--color-neutral-border-subtlest) !default;
$tabs-selected-border-color: var(--color-brand-primary-border) !default;
$tabs-nav-link-icon-color: var(--color-neutral-graphic) !default;
$tabs-employee-text-color: var(--color-employee-text) !default;
$tabs-employee-hover-text-color: var(--color-employee-text-hover) !default;
$tabs-employee-border-color: var(--color-employee-text) !default;
$tabs-employee-selected-text-color: var(--color-employee-text) !default;
$tabs-huge-background-color: var(--color-neutral-bg-level-2) !default;
$tabs-huge-background-color-current: var(--color-neutral-bg-level-1) !default;
$tabs-huge-border-color: var(--color-neutral-border-subtle) !default;
$tabs-huge-employee-border-color: var(--color-employee-border) !default;
$tabs-light-color: var(--color-neutral-bg-level-1) !default;
$tabs-light-selected-color: var(--color-neutral-bg-level-2) !default;
$tabs-tag-selected-text-color: var(--color-brand-primary-text-onbg-strong) !default;
$tabs-tag-selected-background-color: var(--color-brand-primary-bg-strong) !default;
$tabs-employee-tag-text-color: var(--color-employee-text) !default;
$tabs-employee-tag-background-color: var(--color-employee-bg-subtle) !default;

// Toggle
$toggle-border: 1px solid var(--color-neutral-border) !default;
$toggle-indicator-after-background: var(--color-neutral-border) !default;
$toggle-indicator-checked-color: var(--color-brand-primary-bg-strong) !default;
$toggle-indicator-checked-border-color: var(--color-brand-primary-border) !default;
$toggle-checked-indicator-after-background: var(--color-brand-primary-text-onbg-strong) !default;
$toggle-label-inside-color: var(--color-neutral-text-subtle) !default;
$toggle-checked-label-inside-color: var(--color-brand-primary-text-onbg-strong) !default;
$toggle-disabled-label-color: var(--color-disabled-text) !default;
$toggle-checked-spinner-inside-color: var(--color-brand-primary-graphic) !default;
$toggle-disabled-opacity: 1 !default;
$toggle-indicator-disabled-border-color: var(--color-disabled-border) !default;
$toggle-indicator-disabled-after-background: var(--color-disabled-bg) !default;
$toggle-indicator-disabled-checked-border-color: var(--color-disabled-border) !default;
$toggle-indicator-disabled-checked-bg-color: var(--color-disabled-bg) !default;
$toggle-indicator-disabled-checked-after-background: var(--color-neutral-bg-level-1) !default;
$toggle-disabled-checked-label-inside-color: var(--color-disabled-text) !default;

$toggle-indicator-checked-color-employee: var(--color-employee-bg-strong) !default;
$toggle-indicator-after-background-employee: var(--color-employee-graphic) !default;
$toggle-checked-indicator-after-background-employee: var(--color-employee-text-onbg-strong) !default;
$toggle-spinner-color-employee: var(--color-employee-graphic) !default;
$toggle-employee-checked-label-inside-color: var(--color-employee-text-onbg-strong) !default;

// Header
$header-search-close-button-margin-left: 8px;
$header-search-close-button-width: 32px;
$header-search-offset-right: $header-search-close-button-margin-left + $header-search-close-button-width;
$header-search-offset-left-xs: $header-search-offset-right + $container-padding * 2;
$header-search-offset-left-md: $header-search-offset-right + $container-padding-sm * 2;
$header-bottom-height: 60px !default;
$header-bottom-md-height: 80px !default;
$header-scrolled-height: 40px !default;

// Menu
$menu-link-font-weight: $font-weight-base !default;
$menu-link-padding: 13px 7px 10px !default;
$menu-large-link-padding: 12px 12px 16px !default;
$menu-large-link-md-padding: $menu-large-link-padding !default;
$menu-text-font-size: 7px !default;
$menu-text-bottom: 6px !default;
$menu-large-icon-font-size: 22px !default;
$menu-item-current-indicator-bottom: 0 !default;
$menu-icon-wrapper-height: 20px !default;

// Nav
$nav-container-box-shadow: none !default;
$nav-link-font-weight: $font-weight-base !default;
$nav-container-margin-left: 1px !default;
$nav-link-hover: $purple !default;
$nav-link-hover-background: $grey-l !default;
$nav-icon-display: inline-block !default;
$nav-icon-md-display: block !default;
$nav-item-md-height: $header-bottom-md-height !default;
$nav-link-md-padding: 17px 10px !default;
$nav-item-after-display: block !default;
$nav-item-md-min-width: 95px !default;
$nav-item-font-size: 12px !default;
$nav-compact-item-height: $header-scrolled-height !default;
$nav-compact-link-md-padding: 12px 8px !default;
$nav-compact-link-md2-padding: 10px 10px !default;
$nav-compact-item-multiline-md-padding: 8px !default;
$nav-compact-item-multiline-md2-padding: 6px !default;
$nav-compact-padding-top: 10px !default;
$nav-item-scroll-visible-padding: 0 10px !default;
$nav-sub-container-right-position: 2px !default;

// Dropdown
$dropdown-selected-color: $purple !default;

// List
$list-large-font-size-xs: $base-font-size !default;
$list-large-font-size-sm: $h4-size !default;
$list-large-font-size-md: $h4-size !default;
$list-large-font-size-xl: $h4-size-xl !default;
$list-large-font-weight: 400 !default;
$list-icon-color: var(--color-neutral-graphic) !default;
$list-text-color: var(--color-neutral-text) !default;
$list-border-color: var(--color-neutral-border-subtlest) !default;
$list-marker-top-position-xl: 9px !default;
$list-icon-spacing: 0.4em !default;
$list-ordered-counter-weight: 700 !default;
$list-gutter-medium: $grid-gutter-medium;
$list-gutter-medium-xs: $grid-gutter-medium-xs;

// Arrow-list
$arrow-list-background-default: $color-raw-grey-light !default;
$arrow-list-background-default-hover: $color-raw-grey-base !default;
$arrow-list-background-white: $color-raw-white-base !default;
$arrow-list-background-white-hover: $color-raw-grey-base !default;
$arrow-list-background-beige: $color-raw-beige-fare !default;
$arrow-list-background-beige-hover: $color-raw-beige-light !default;

// Logo
$logo-padding-top: math.div(97, 250) * 100% !default; // (height / width * 100%)
$logo-background-url: '~@telia-front/static-assets/images/telia-logo.svg' !default;
$logo-background-url-inverse: '~@telia-front/static-assets/images/telia-logo-inverse.svg' !default;
$logo-background-url-black: '~@telia-front/static-assets/images/telia-logo-black.svg' !default;
$logo-small-width-sm: 80px;

// Search-large
$search-large-background: $white !default;
$search-large-light-background: $grey-l !default;

// Clear-field
$clear-field-hover: $purple !default;

// Skeleton
$skeleton-background-color: var(--color-neutral-border-subtlest) !default;
$skeleton-background-color-secondary: var(--color-neutral-border) !default;

// SkipLinks
$skip-links-background-color: var(--color-neutral-bg-level-2) !default;
$skip-links-border-color: var(--color-neutral-border-subtlest) !default;
$skip-links-font-size: var(--type-tiny-font-size) !default;
$skip-links-line-height: var(--type-tiny-line-height) !default;

// Suggestion
$suggestion-font-size: 1rem !default;
$suggestion-background: var(--color-neutral-bg-level-3) !default;
$suggestion-shadow: 0 3px 6px rgba($black, 0.2) !default;
$suggestion-border-radius: 3px !default;
$suggestion-link-padding: 9px 21px !default;
$suggestion-link-hover-color: var(--color-neutral-text) !default;
$suggestion-link-background: var(--color-neutral-bg-level-4) !default;
$suggestion-link-active-color: var(--color-brand-primary-text) !default;
$suggestion-link-active-color-employee: var(--color-employee-text) !default;
$suggestion-group-label-color: var(--color-neutral-text-subtle) !default;
$suggestion-group-label-font-size: var(--type-tiny-font-size) !default;
$suggestion-item-background: var(--color-neutral-border-subtle) !default;
$suggestion-item-margin-top: -1px !default;
$suggestion-sub-label-color: var(--color-neutral-text-subtle) !default;
$suggestion-sub-label-font-size: var(--type-tiny-font-size) !default;
$suggestion-icon-size: 1.5rem !default;
$suggestion-image-width: 1.5rem !default;

// Affix
$affix-top: $header-bottom-height !default;
$affix-top-lg: $header-scrolled-height !default;

// Notice
$notice-colors: (
  info: (
    icon: var(--color-info-graphic),
    background: var(--color-info-bg-subtle),
    border: var(--color-info-border),
  ),
  success: (
    icon: var(--color-success-graphic),
    background: var(--color-success-bg-subtle),
    border: var(--color-success-border),
  ),
  employee: (
    icon: var(--color-employee-graphic),
    background: var(--color-employee-bg-subtlest),
    border: var(--color-employee-border),
  ),
  error: (
    icon: var(--color-error-graphic),
    background: var(--color-error-bg-subtle),
    border: var(--color-error-border),
  ),
  attention: (
    icon: var(--color-attention-graphic),
    background: var(--color-attention-bg-subtle),
    border: var(--color-attention-border),
  ),
) !default;
$notice-height: 64px !default;

// Number
$number-disabled-color: var(--color-disabled-text) !default;
$number-input-disabled-border-color: var(--color-disabled-border) !default;
$number-input-border-color: var(--color-neutral-border) !default;
$number-input-focus-outline: var(--color-focus-outline) !default;
$number-invalid-color: var(--color-error-border) !default;

// Card
$card-box-shadow: none !default;
$card-border-radius-medium: 8px !default;
$card-left-border-width: 4px !default;
$card-hover-bg: var(--color-neutral-bg-hover) !default;

$card-old-inactive-color: $color-raw-grey-dark !default;

$card-selectable-border-color: var(--color-neutral-border-subtle) !default;
$card-selectable-employee-color: var(--color-employee-text) !default;
$card-selectable-hover-border-color: var(--color-brand-primary-border) !default;
$card-selectable-employee-hover-border-color: var(--color-employee-border) !default;
$card-selected-border-color: var(--color-brand-primary-border) !default;
$card-selected-border-employee-color: var(--color-employee-border) !default;
$card-selectable-disabled-border-color: var(--color-disabled-border) !default;

$card-type-success-border-color: var(--color-success-border) !default;
$card-type-error-border-color: var(--color-error-border) !default;
$card-type-employee-border-color: var(--color-employee-border) !default;
$card-type-inactive-border-color: var(--color-disabled-border) !default;
$card-type-attention-border-color: var(--color-attention-border) !default;
$card-type-light-border-color: var(--color-neutral-border-subtle) !default;

$card-hover-border-color: var(--color-brand-primary-border) !default;

// Option-card
$option-card-error-color: var(--color-error-border) !default;
$option-card-disabled-border-color: var(--color-disabled-border) !default;
$option-card-disabled-selected-border-color: var(--color-disabled-border) !default;
$option-card-indicator-background: transparent !default;
$option-card-indicator-color: var(--color-brand-primary-text-onbg-strong) !default;
$option-card-indicator-color-employee: var(--color-employee-text-onbg-strong) !default;
$option-card-indicator-background-selected: var(--color-brand-primary-bg-strong) !default;
$option-card-indicator-background-selected-border: var(--color-brand-primary-border) !default;
$option-card-indicator-employee-background-selected: var(--color-employee-bg-strong) !default;
$option-card-indicator-employee-background-selected-border: var(--color-employee-border) !default;
$option-card-indicator-border-color: var(--color-neutral-border) !default;
$option-card-indicator-disabled-background-color: var(--color-disabled-bg) !default;
$option-card-indicator-disabled-border-color: var(--color-disabled-border) !default;
$option-card-indicator-disabled-color: var(--color-disabled-text) !default;

$option-card-small-vertical-padding: 20px !default;
$option-card-small-horizontal-padding: 15px !default;
$option-card-medium-vertical-padding: 30px !default;
$option-card-medium-horizontal-padding: 20px !default;

// Option-card-old
$option-card-old-recommended-color: $color-recommended-1 !default;
$option-card-old-error-color: $color-error-1 !default;

$option-card-old-content-padding: 14px 30px !default;
$option-card-old-content-padding-xs: 14px 30px !default;
$option-card-old-small-vertical-padding: 20px !default;
$option-card-old-small-horizontal-padding: 15px !default;
$option-card-old-medium-vertical-padding: 30px !default;
$option-card-old-medium-horizontal-padding: 20px !default;
$option-card-old-content-vertical-padding: 40px !default;
$option-card-old-content-vertical-padding-xs: 40px !default;

$option-card-old-footer-padding: 16px 30px !default;
$option-card-old-footer-padding-xs: 16px 30px !default;
$option-card-old-background-color: $color-primary-3 !default;

// Overlay
$overlay-background: var(--color-neutral-bg-level-1) !default;

// Collapse
$collapse-background-color: var(--color-neutral-bg-level-1) !default;
$collapse-background-color-grey: var(--color-neutral-bg-level-2) !default;
$collapse-border-color: var(--color-brand-primary-border) !default;
$collapse-close-button-color-hover: var(--color-brand-primary-text-hover) !default;

// CookieNotice
$cookie-notice-background-color: #d9f8ff !default;
$cookie-notice-color: $black !default;
$cookie-notice-font-size: 12px !default;
$cookie-notice-font-weight: 700 !default;
$cookie-notice-icon-color: $blue !default;
$cookie-notice-icon-size: 16px !default;
$cookie-notice-align-items: flex-start !default;
$cookie-notice-padding: 5px 10px 10px 10px !default;
$cookie-notice-padding-sm: 5px 20px 4px 20px !default;

// Datepicker
$datepicker-bg: var(--color-neutral-bg-level-3) !default;
$datepicker-buttons-bg: var(--color-neutral-bg-level-4) !default;
$datepicker-column-border: var(--color-neutral-border-subtlest) !default;
$datepicker-text-color: (
  hover: var(--color-brand-primary-text-hover),
  selected: var(--color-brand-primary-text-onbg-strong),
) !default;
$datepicker-background: (
  hover: var(--color-neutral-bg-hover),
  selected: var(--color-brand-primary-bg-strong),
) !default;
$datepicker-day-disabled-color: var(--color-neutral-text-subtle) !default;
$datepicker-value-color: var(--color-brand-primary-text) !default;

// Filter
$filter-trigger-hover: $purple !default;

// Separator
$separator-color-default: var(--color-neutral-border-subtlest) !default;
$separator-color-medium: var(--color-neutral-border-subtle) !default;
$separator-color-strong: var(--color-neutral-border) !default;
$separator-weight-default: 2px !default;
$separator-weight-thin: 1px !default;

// Signature
$signature-background: var(--color-neutral-bg-level-2) !default;
$signature-background-disabled: var(--color-disabled-bg) !default;
$signature-border-color: var(--color-neutral-border) !default;
$signature-light-background: var(--color-neutral-bg-level-1) !default;
$signature-employee-border: var(--color-employee-border) !default;
$signature-invalid-border: var(--color-error-border) !default;
$signature-focus-border: var(--color-brand-primary-border) !default;
$signature-employee-focus-border: var(--color-employee-border) !default;
$signature-disabled-opacity: 1 !default;
$signature-disabled-border-color: var(--color-disabled-border) !default;
$signature-disabled-label-color: var(--color-disabled-text) !default;

// Spinner
$spinner-color: var(--color-brand-primary-graphic);

// Styleguide variables
$sg-body-background: $white !default;
$sg-body-background-grey: $grey-l !default;

// Tag
$tag-size: 96px !default;
$tag-size-small: 64px !default;
$tag-size-medium: 80px !default;
$tag-padding: 15% !default;
$tag-padding-small: 15% !default;
$tag-font-size-size-tiny: 9px !default;
$tag-font-size-size-small: 11px !default;
$tag-font-size-size-medium: 14px !default;
$tag-font-size-size-large: 16px !default;
$tag-font-size-size-extra-large: 20px !default;
$tag-svg-size: 24px !default;
$tag-svg-size-icon: 40px !default;
$tag-svg-size-icon-small: 32px !default;
$tag-tiny-padding-vertical: 4px !default;
$tag-tiny-padding-horizontal: 8px !default;
$tag-tiny-border-radius: 4px !default;
$tag-tiny-font-size: var(--type-tiny-font-size) !default;
$tag-tiny-font-height: var(--type-tiny-line-height) !default;
$tag-tiny-height: calc(#{$tag-tiny-padding-vertical * 2} + (#{$tag-tiny-font-height} * 1em)) !default;
$tag-purpose-service-border-color: var(--color-neutral-border-subtle) !default;
$tag-purpose-service-text-color: var(--color-neutral-text) !default;
$tag-default-background-color: var(--color-brand-primary-bg-strong) !default;
$tag-default-text-color: var(--color-brand-primary-text-onbg-strong) !default;

// Tooltip
$tooltip-color: var(--color-neutral-bg-level-3) !default;
$tooltip-border-color: var(--color-neutral-border-subtlest) !default;

// Tooltip toggle
$tooltip-toggle-border-color: var(--color-brand-primary-text) !default;
$tooltip-toggle-border-color-hover: var(--color-brand-primary-text-hover) !default;
$tooltip-toggle-color-hover: var(--color-brand-primary-text-hover) !default;
$tooltip-toggle-icon-color: var(--color-brand-primary-text) !default;
$tooltip-toggle-icon-color-hover: var(--color-brand-primary-text-hover) !default;
$tooltip-toggle-employee-border-color: var(--color-employee-text) !default;
$tooltip-toggle-employee-border-color-hover: var(--color-employee-text-hover) !default;
$tooltip-toggle-employee-color-hover: var(--color-employee-text-hover) !default;
$tooltip-toggle-employee-icon-color: var(--color-employee-text) !default;
$tooltip-toggle-employee-icon-color-hover: var(--color-employee-text-hover) !default;

// Video
$video-background-color: var(--color-neutral-bg-level-2) !default;
$video-box-shadow: none !default;
$video-icon-background-color: var(--color-brand-primary-bg-subtle) !default;
$video-icon-background-color-hover: var(--color-brand-primary-bg-strong) !default;
$video-icon-fill: var(--color-brand-primary-text-subtle) !default;
$video-icon-fill-hover: var(--color-brand-primary-text-onbg-strong) !default;
$video-border-radius: 8px !default;
$video-title-background: var(--color-brand-primary-bg-strong) !default;
$video-title-color: var(--color-brand-primary-text-onbg-strong) !default;
