@import '~@telia-front/sass-tokens/index.scss';

@import '~@core-front/accordion/styles.scss';

.accordion__item {
  .accordion--hide-header.accordion--large > & {
    display: flex;
  }
}

.accordion__item--transparent {
  background: transparent;
}

.accordion__header {
  .accordion--hide-header.accordion--large > .accordion__item > .accordion__item-inner > & {
    flex-direction: row;

    @media (min-width: $media-width-lg-min) {
      height: auto;
      transition: opacity 300ms $accordion-transition-timing-function;
      opacity: 1;
      flex-direction: column;
    }
  }

  .accordion--hide-header.accordion--large > .accordion__item.is-open > .accordion__item-inner > & {
    @media (min-width: $media-width-lg-min) {
      height: 0;
      opacity: 0;
    }
  }

  .accordion--hide-header.accordion--large > .accordion__item.is-animating > .accordion__item-inner & {
    @media (min-width: $media-width-lg-min) {
      position: absolute;
      width: 100%;
      height: 100%;
    }
  }
}

.accordion__header-inner {
  .accordion--hide-header.accordion--large > .accordion__item > .accordion__item-inner > .accordion__header > & {
    width: 100%;
    padding-top: 20px;
    padding-right: 20px;
    padding-bottom: 20px;
    min-width: 0;
  }
}

.accordion__arrow {
  html[data-whatintent='mouse']
    .accordion--hide-header.accordion--large
    .accordion__item:not(.accordion__item--employee).is-hovered
    > .accordion__item-inner
    ~ &:hover {
    color: var(--color-brand-primary-text-hover);
  }

  .accordion--hide-header.accordion--large > .accordion__item > .accordion__item-inner > .accordion__header > &,
  .accordion--hide-header.accordion--large > .accordion__item > & {
    position: relative;
    top: auto;
    right: auto;
    transform: none;
    width: 58px;
    padding: $base-horizontal-spacing-xs;
    transition: background 300ms $accordion-transition-timing-function;
    background-color: $grey-l;

    @media (min-width: $media-width-lg-min) {
      padding: $base-horizontal-spacing;
    }
  }
}

.accordion__arrow-icon {
  .accordion--hide-header.accordion--large > .accordion__item > .accordion__arrow > &,
  .accordion--hide-header.accordion--large > .accordion__item > .accordion__item-inner > .accordion__header > .accordion__arrow > & {
    margin-left: -8px;
    margin-top: 30px;
    transform: translateY(-50%);
    transition:
      color 300ms $accordion-transition-timing-function,
      transform 300ms $accordion-transition-timing-function;

    @media (min-width: $media-width-sm-min) {
      margin-left: -12px;
    }

    @media (min-width: $media-width-lg-min) {
      margin-top: 34px;
      margin-left: -7px;
    }
  }

  .accordion--hide-header.accordion--large > .accordion__item.is-open > .accordion__arrow > &,
  .accordion--hide-header.accordion--large > .accordion__item.is-open > .accordion__item-inner > .accordion__header > .accordion__arrow > & {
    transform: rotate(180deg) translateY(50%);
    color: var(--color-brand-primary-text);
  }
}

.accordion__content {
  .accordion--large.accordion--hide-header & {
    padding-right: $base-horizontal-spacing-xs;

    @media (min-width: $media-width-sm-min) {
      padding-right: $base-horizontal-spacing;
    }

    @media (min-width: $media-width-lg-min) {
      min-height: 100px;
      padding-top: 27px;
      transition: opacity 300ms $accordion-transition-timing-function;
      opacity: 0;
    }

    @media (min-width: $media-width-xl-min) {
      padding-top: 26px;
    }
  }

  .accordion--hide-header.accordion--large > .accordion__item.is-open & {
    @media (min-width: $media-width-lg-min) {
      opacity: 1;
    }
  }
}

.accordion__check {
  width: 24px;
  height: 24px;
  flex-shrink: 0;
  border: 2px solid var(--color-neutral-border-subtlest);
  border-radius: 50%;

  .accordion__item.is-checked > .accordion__item-inner > .accordion__header & {
    border: none;
  }

  @media (min-width: $media-width-sm-min) {
    width: 32px;
    height: 32px;
  }
}

.accordion__check-icon {
  display: none;
  font-size: 24px;
  color: var(--color-success-graphic);

  .accordion__item.is-checked > .accordion__item-inner > .accordion__header & {
    display: block;
  }

  @media (min-width: $media-width-sm-min) {
    font-size: 32px;
  }
}

.accordion--type-card {
  border: 0;

  .accordion__item {
    border: 0;
    margin: 0 0 2px;

    &:last-child {
      margin: 0;
      border: 0;

      .accordion__header,
      .accordion__border {
        border-radius: 0 0 $card-border-radius-medium $card-border-radius-medium;
        transition: border-radius 300ms $accordion-transition-timing-function;
      }

      &.is-open {
        .accordion__header {
          border-radius: 0;
          transition: border-radius 300ms $accordion-transition-timing-function;
        }
      }
    }

    &:first-child {
      .accordion__header {
        border-radius: $card-border-radius-medium $card-border-radius-medium 0 0;
      }
    }

    &:only-child {
      .accordion__header {
        border-radius: $card-border-radius-medium;
        transition: border-radius 300ms $accordion-transition-timing-function;
      }

      &.is-open {
        .accordion__header-inner {
          border-radius: $card-border-radius-medium $card-border-radius-medium 0 0;
          transition: border-radius 300ms $accordion-transition-timing-function;
        }
      }

      .accordion__border {
        border-radius: 0 0 $card-border-radius-medium $card-border-radius-medium;
      }
    }

    &.is-open {
      .accordion__header-inner::before {
        opacity: 1;
      }

      .accordion__border {
        border: 1px solid $accordion-type-card-content-border;
        border-top: 0;
      }
    }

    &.is-animating .accordion__border {
      border: 1px solid $accordion-type-card-content-border;
      border-top: 0;
    }
  }

  .accordion__header {
    background-color: $accordion-type-card-header-background;
    transition: background-color 300ms $accordion-transition-timing-function;
  }

  .accordion__header-inner::before {
    content: '';
    position: absolute;
    inset: 0;
    background-color: var(--color-neutral-bg-subtlest);
    opacity: 0;
    transition: opacity 300ms $accordion-transition-timing-function;
  }

  .accordion__content {
    padding: $accordion-type-card-content-padding;
  }
}
