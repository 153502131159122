@use 'sass:map';

@import '~@core-front/sass-tokens/index.scss';

.suggestion {
  @include list-unstyled();

  font-size: $suggestion-font-size;
  background: $suggestion-background;
  border-radius: $suggestion-border-radius;
  box-shadow: $suggestion-shadow;
  text-align: left;
  max-height: 400px; // height of 10 items
  overflow: auto;
  position: relative;
  z-index: map.get($zindex, suggestion);
}

.suggestion__item {
  position: relative;
  margin-top: $suggestion-item-margin-top;

  &::after,
  &::before {
    content: '';
    position: absolute;
    display: block;
    height: 1px;
    margin-left: auto;
    margin-right: auto;
    left: 10px;
    right: 10px;
    background-color: $suggestion-item-background;
    z-index: 0;
  }

  &::after {
    top: 0;
    display: none;
  }

  &::before {
    bottom: 0;
  }

  &:first-child {
    margin-top: 0;
  }

  &:last-child::before {
    display: none;
  }

  &.suggestion__item--grouped {
    &:not(:first-child)::after {
      display: none;
    }

    + .suggestion__item:not(.suggestion__item--grouped) {
      padding-top: 20px;
    }
  }

  .suggestion__group-list > &:first-child::after {
    display: block;
  }

  &.suggestion__item--additional-content {
    padding-top: 10px;
  }
}

.suggestion__group-list {
  @include list-unstyled();
}

.suggestion__link {
  display: flex;
  align-items: center;
  padding: $suggestion-link-padding;
  color: $text-color-base;
  position: relative;
  z-index: 1;
  cursor: pointer;
  font-weight: 400;
  transition: color $transition-default;

  html[data-whatintent='mouse'] &:hover,
  &:focus-visible,
  .suggestion__item.is-focused & {
    color: $suggestion-link-hover-color;
    background-color: $suggestion-link-background;
  }

  &:focus-visible,
  .suggestion__item.is-focused & {
    @include keyboard-focus();

    outline-offset: -2px;
  }

  html[data-whatintent='mouse'] .suggestion__item.is-active & {
    &:hover {
      color: $suggestion-link-active-color;
    }
  }

  .suggestion__item--employee.is-active & {
    color: $suggestion-link-active-color-employee;
  }

  html[data-whatintent='mouse'] .suggestion__item--employee.is-active & {
    &:hover {
      color: $suggestion-link-active-color-employee;
    }
  }
}

.suggestion__icon {
  @include reset-icon();

  font-size: $suggestion-icon-size;
  margin-right: 6px;
  flex-shrink: 0;
}

.suggestion__image {
  width: $suggestion-image-width;
  margin-right: 8px;
  flex-shrink: 0;
}

.suggestion__sub-label {
  display: block;
  font-size: $suggestion-sub-label-font-size;
  font-weight: 700;
  color: $suggestion-sub-label-color;
}

.suggestion__spinner {
  padding: $suggestion-link-padding;

  & .spinner__circle {
    width: 24px;
    height: 24px;
  }
}

.suggestion__label-mark {
  background: none;
  color: inherit;
  font-weight: $suggestion-label-mark-font-weight;
}

.suggestion__group-label {
  padding: 20px 10px 6px;
  font-size: $suggestion-group-label-font-size;
  color: $suggestion-group-label-color;
}
