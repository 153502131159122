@import '~@telia-front/sass-tokens/index.scss';

.compare-menu {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: $grey-l;
  border-radius: 30px;
  box-shadow: 0 1px 1px rgba($black, 0.1);
  height: 42px;
  padding: 0 20px;
}

.compare-menu--theme-light {
  background: $white;
  box-shadow: 0 1px 1px rgba($black, 0.1);
}

.compare-menu__list {
  @include list-unstyled();

  display: flex;
  margin-right: -13px;
  padding-left: 20px;
}

.compare-menu__item {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 42px;
  color: $grey-d;
  text-align: center;
  border-left: 1px solid $white;

  .compare-menu--theme-light & {
    color: $black;
    border-left: 1px solid $grey-l;
  }
}

.compare-menu__button {
  @include reset-button();

  position: relative;
  color: $purple;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  html[data-whatintent='mouse'] &:hover {
    color: $purple-l;
  }
}

.compare-menu__icon {
  @include reset-icon();

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 16px;

  .compare-menu__item--active & {
    opacity: 0;
  }

  html[data-whatintent='mouse'] .compare-menu__item--active .compare-menu__button:hover & {
    opacity: 1;
  }
}

.compare-menu__image {
  width: 30px;
  height: 30px;
  opacity: 1;

  html[data-whatintent='mouse'] .compare-menu__item--active .compare-menu__button:hover & {
    opacity: 0.1;
  }
}
