@import '~@core-front/sass-tokens/index.scss';

.skip-links {
  height: 0;
  overflow: hidden;
  padding: 0;

  &:focus-within {
    border-bottom: 1px solid $skip-links-border-color;
    background-color: $skip-links-background-color;
    height: auto;
    overflow: auto;
    padding: $skip-links-padding-size 0;
  }
}

.skip-links__anchor {
  font-size: $skip-links-font-size;
  line-height: $skip-links-line-height;
}
