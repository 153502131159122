@import '~@telia-front/sass-tokens/index.scss';
@import '~@core-front/feature-list/styles.scss';

.feature-list__inner {
  border-radius: 8px;
  background-color: var(--color-brand-primary-bg-subtle);
  padding: 16px 32px;
  font-size: 18px;
  align-items: flex-start;

  @media (max-width: $media-width-sm-max) {
    padding: 24px 16px;
    font-size: 16px;
  }
}

.feature-list__item {
  padding: 16px;

  @media (max-width: $media-width-sm-max) {
    padding: 8px 16px;
  }

  @media (min-width: $media-width-md-min) {
    width: 100%;
  }
}

.feature-list__item-icon {
  font-size: 40px;

  @media (min-width: $media-width-md-min) {
    font-size: 48px;
  }
}

.feature-list__item-image {
  width: 40px;

  @media (min-width: $media-width-md-min) {
    width: 48px;
  }
}

.feature-list__item-icon-col {
  height: 48px;
}
