.header__segment {
  --header-segment-link-hor-padding: 8px;
  --header-segment-link-ver-padding: 16px;
  --header-segment-container-padding: #{$container-padding};

  margin-left: calc(var(--header-segment-container-padding) * -1);
  flex-shrink: 1;
  min-width: 0;

  @media (min-width: $media-width-sm-min) {
    --header-segment-link-hor-padding: 12px;
    --header-segment-link-ver-padding: 14px;
    --header-segment-container-padding: #{$container-padding-sm};
  }

  @media (min-width: $media-width-lg-min) {
    --header-segment-container-padding: #{$container-padding-lg};
  }
}

.header__segment-list {
  display: flex;
  font-size: var(--type-tiny-font-size);
  line-height: var(--type-tiny-line-height);
  padding-left: calc(var(--header-segment-container-padding) - var(--header-segment-link-hor-padding));

  @media (min-width: $media-width-sm-min) {
    font-size: var(--type-small-font-size);
    line-height: var(--type-small-line-height);
  }

  .header__segment:not(.header__segment--employee) & > .list__inner {
    flex-wrap: nowrap;
  }
}

.header__segment-link {
  display: block;
  color: var(--color-neutral-text);
  padding: var(--header-segment-link-ver-padding) var(--header-segment-link-hor-padding);
  border-top: 2px solid transparent;
  font-weight: 700;
  transition: $transition-default;
  transition-property: color, background-color;
  background: transparent;
  outline-offset: -5px;

  html[data-whatintent='mouse'] &:hover {
    color: var(--color-brand-primary-text-hover);
  }

  .header__segment-item.is-current &,
  .header__segment-item-with-icon.is-current & {
    color: var(--color-brand-primary-text);
    background-color: var(--color-neutral-bg-level-3);

    .header__segment--employee & {
      border-top-color: var(--color-employee-border);
      color: var(--color-employee-text);
    }

    .header__segment--employee-test & {
      border-top-color: var(--color-palette-blue-500);
      color: var(--color-palette-blue-500);
    }

    .header__segment--employee-dev & {
      border-top-color: var(--color-palette-orange-400);
      color: var(--color-palette-orange-400);
    }
  }

  .header__segment-item.is-current.is-employee & {
    color: var(--color-employee-text);
    border-top-color: var(--color-employee-border);

    &:hover {
      color: var(--color-employee-text);
    }
  }

  .header__segment--employee & {
    border-top-color: var(--color-employee-border);
    color: var(--color-employee-text-onbg-strong);

    html[data-whatintent='mouse'] &:hover {
      color: var(--color-employee-text);
      background-color: var(--color-neutral-bg-level-3);
    }
  }

  .header__segment--employee-test & {
    border-top-color: var(--color-palette-blue-500);
    color: var(--color-employee-text-onbg-strong);

    html[data-whatintent='mouse'] &:hover {
      color: var(--color-palette-blue-500);
      background-color: var(--color-neutral-bg-level-3);
    }
  }

  .header__segment--employee-dev & {
    border-top-color: var(--color-palette-orange-400);
    color: var(--color-employee-text-onbg-strong);

    html[data-whatintent='mouse'] &:hover {
      color: var(--color-palette-orange-400);
      background-color: var(--color-neutral-bg-level-3);
    }
  }
}

.header__segment-item {
  &.is-current {
    position: relative;

    .header__segment-link::after {
      display: block;
      content: '';
      border-bottom: 2px solid var(--color-brand-primary-border);
      position: absolute;
      bottom: 0;
      left: var(--header-segment-link-hor-padding);
      right: var(--header-segment-link-hor-padding);
    }
  }

  & + .header__segment-item {
    margin-left: unset;
  }

  &.is-employee {
    .header__segment-link {
      color: var(--color-employee-text);
    }

    &:not(.is-current) .header__segment-link:hover {
      color: var(--color-employee-text-hover);
    }

    &.is-current {
      .header__segment-link::after {
        border-bottom-color: var(--color-employee-border);
      }
    }
  }
}

.header__segment:not(.header__segment--employee, .header__segment--employee-dev, .header__segment--employee-test) {
  .header__segment-link {
    border-top: unset;
  }

  .header__segment-item.is-current,
  .header__segment-item-with-icon.is-current {
    .header__segment-link {
      background-color: unset;
    }
  }
}

.header__segment-item-with-icon {
  .header__segment-link {
    padding-left: 3px;
  }
}

.header__segment-icon {
  margin-right: 3px;
  padding-left: 15px;

  @media (min-width: $media-width-sm-min) {
    padding-left: 25px;
  }

  @media (min-width: $media-width-lg-min) {
    padding-left: 0;
  }
}

.header__employee {
  .header__segment-link,
  .header__segment-item.is-current .header__segment-link,
  .header__segment-item:not(.is-current) + .header__segment-item:not(.is-current) .header__segment-link,
  .header__segment-item.is-current + .header__segment-item:not(.is-current) .header__segment-link {
    padding: 7px 10px;
    color: var(--color-employee-text-onbg-strong);

    @media (min-width: $media-width-sm-min) {
      padding-left: 15px;
      padding-right: 15px;
    }
  }

  .header__segment {
    width: unset;
  }

  .header__segment-list {
    font-size: var(--type-tiny-font-size);
    line-height: var(--type-tiny-line-height);
  }

  .header__segment-icon {
    padding-left: 0;
  }
}

.header__segment--employee,
.header__segment--employee-dev,
.header__segment--employee-test {
  .header__segment-icon {
    display: block;
    margin-right: 8px;
  }
}
