@import '~@core-front/sass-tokens/index.scss';

.feature-list {
  display: flex;
  position: relative;
}

.feature-list__inner {
  min-height: 100px;
  width: 100%;
  padding: 10px;
  background: $white;
  border-radius: 3px;

  @media (min-width: $media-width-md-min) {
    padding: 5px 18px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @media (min-width: $media-width-lg-min) {
    padding: 5px 25px;
  }
}

.feature-list__item {
  padding: 10px;
  display: block;

  @media (min-width: $media-width-md-min) {
    padding: 12px;
    width: 300px;
  }

  @media (min-width: $media-width-lg-min) {
    padding: 15px;
  }
}

.feature-list__item-inner {
  @media (min-width: $media-width-sm-min) {
    max-width: 270px;
  }
}

.feature-list__item-icon {
  font-size: 32px;

  @media (min-width: $media-width-sm-min) {
    font-size: 48px;
  }
}

.feature-list__item-image {
  width: 32px;

  @media (min-width: $media-width-sm-min) {
    width: 48px;
  }
}
