@import '~@core-front/sass-tokens/index.scss';

a,
.anchor {
  text-decoration: none;
  color: $anchor-color;
  font-weight: $anchor-weight;
  @include transition(
    (
      'animation': short,
      'properties': color,
    )
  );

  html[data-whatintent='mouse'] .click-area:hover &.click-area__trigger,
  html[data-whatintent='mouse'] &:hover {
    color: $anchor-hover-color;
  }

  &.is-text-color {
    color: $text-color-base;

    html[data-whatintent='mouse'] .click-area:hover &.click-area__trigger,
    html[data-whatintent='mouse'] &:hover {
      color: $anchor-text-color-hover-color;
    }
  }

  &.is-underlined {
    text-decoration: underline;
  }
}

.anchor--employee {
  color: var(--color-employee-text, $color-employee-1);

  html[data-whatintent='mouse'] .click-area:hover &.click-area__trigger,
  html[data-whatintent='mouse'] &:hover {
    color: var(--color-employee-text-hover, $color-employee-2);
  }

  &.is-text-color {
    color: $text-color-base;

    html[data-whatintent='mouse'] .click-area:hover &.click-area__trigger,
    html[data-whatintent='mouse'] &:hover {
      color: var(--color-employee-text-hover, $color-employee-2);
    }
  }
}
