@use 'sass:math';

@import '~@core-front/sass-tokens/index.scss';

.option-card-old {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: math.div(30px, 2);
  text-align: $option-card-old-text-align;
}

.option-card-old--badge-top {
  margin-top: math.div(30px, 2);
}

.option-card-old__input {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  pointer-events: none;
}

.option-card-old__label {
  @include visually-hidden();
}

.option-card-old__body {
  position: relative;
  z-index: 1;
  display: flex;
  width: 100%;
  flex: 1 0 auto;
  background: $option-card-old-background-color;
  border-radius: $option-card-old-border-radius;

  .option-card-old__input:disabled ~ & {
    opacity: 0.5;
    cursor: default;
  }

  .option-card-old--grey & {
    background: $option-card-old-grey-background;
  }
}

html[data-whatintent='mouse']:not([data-whatintent='touch']) .option-card-old__input:not(:disabled, :checked) ~ .option-card-old__body:hover::before {
  border-color: $color-primary-1;
  box-shadow: none;
}

.option-card-old__body::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  pointer-events: none;
  border: 2px solid transparent;
  border-radius: $option-card-old-border-radius;
  box-shadow: $option-card-old-box-shadow;

  .option-card-old--employee & {
    border-color: $color-employee-1;
  }

  .option-card-old--grey & {
    box-shadow: $option-card-old-grey-box-shadow;
  }

  .option-card-old__input:checked ~ & {
    border-color: $color-selected-1;
    box-shadow: none;
  }

  .option-card-old__input:focus-visible ~ & {
    border-color: $color-selected-1;
    box-shadow: none;
  }

  .option-card-old--error.is-animating & {
    border-color: $option-card-old-error-color;
  }

  .option-card-old--error.is-animating.option-card-old--animate-in & {
    transform: scale($error-box-animation-scale);
    animation: $error-box-animation-in-duration $error-box-animation-in-easing 0s 1 forwards option-card-old-error-in;
  }

  .option-card-old--error.is-animating.option-card-old--animate-out & {
    transform: scale(1);
    animation: $error-box-animation-out-duration $error-box-animation-out-easing 0s 1 forwards option-card-old-error-out;
  }
}

.option-card-old__elements {
  width: 100%;
  display: flex;
  flex-direction: column;

  & > *:last-child {
    padding-bottom: $option-card-old-content-vertical-padding-xs;

    @media (min-width: $media-width-sm-min) {
      padding-bottom: $option-card-old-content-vertical-padding;
    }
  }
}

.option-card-old__content {
  flex: 1 0 auto;
  padding: $option-card-old-content-padding-xs;

  @media (min-width: $media-width-sm-min) {
    padding: $option-card-old-content-padding;
  }

  &:first-child {
    padding-top: $option-card-old-content-vertical-padding-xs;
    border-radius: $option-card-old-border-radius $option-card-old-border-radius 0 0;

    @media (min-width: $media-width-sm-min) {
      padding-top: $option-card-old-content-vertical-padding;
    }
  }

  .option-card-old__indicator + &:nth-child(2) {
    padding-top: $option-card-old-content-vertical-padding-xs + 20px + 24px;
    border-radius: $option-card-old-border-radius $option-card-old-border-radius 0 0;

    @media (min-width: $media-width-sm-min) {
      padding-top: $option-card-old-content-vertical-padding + 20px + 24px;
    }
  }
}

.option-card-old__indicator {
  display: block;
  box-shadow: none;
  position: absolute;
  top: $option-card-old-content-vertical-padding-xs;
  left: 50%;
  transform: translateX(-50%);

  @media (min-width: $media-width-sm-min) {
    top: $option-card-old-content-vertical-padding;
  }

  .option-card-old--input-radio & {
    border: 1px solid $radio-border-color;
    width: $radio-indicator-size;
    height: $radio-indicator-size;
    border-radius: 50%;
    background: $white;
    color: $white;

    &::before {
      content: '';
      display: block;
      width: 8px;
      height: 8px;
      background: currentcolor;
      border-radius: 50%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      opacity: 0;
    }
  }

  .option-card-old--input-radio .option-card-old__input:checked ~ .option-card-old__body &::before {
    opacity: 1;
  }

  .option-card-old__input:checked ~ .option-card-old__body & {
    background: $color-selected-1;
    border-color: $color-selected-1;

    .option-card-old--employee & {
      // todo: fix this selector
      background: $color-employee-1;
      border-color: $color-employee-1;
    }
  }

  .option-card-old__input:disabled ~ .option-card-old__body & {
    opacity: 0.5;
  }

  .option-card-old--input-radio .option-card-old__input:disabled:checked ~ .option-card-old__body & {
    background: $radio-inactive-color;
    border-color: $radio-inactive-color;
  }

  .option-card-old--input-checkbox & {
    width: $check-indicator-size;
    height: $check-indicator-size;
    background: $option-card-old-checkbox-bg;
    border: 1px solid $option-card-old-checkbox-border;
    border-radius: $check-indicator-radius;
    color: $option-card-old-checkbox-color;
  }

  .option-card-old--input-checkbox .option-card-old__input:disabled:checked ~ .option-card-old__body & {
    background: $option-card-old-checkbox-disabled-bg;
    border-color: $option-card-old-checkbox-disabled-border;
  }
}

.option-card-old__indicator-icon {
  @include reset-icon();

  font-size: $check-icon-size;
  position: absolute;
  top: 1px;
  right: 1px;
  opacity: 0;
  pointer-events: none;

  .option-card-old__input:checked ~ .option-card-old__body & {
    opacity: 1;
  }
}

.option-card-old__checked-badge {
  position: relative;
  z-index: 1;
}

.option-card-old__badge--with-label {
  padding-left: 15px;
}

.option-card-old__badge-label {
  padding-left: 7px;
  padding-right: 7px;
}

.option-card-old__badge-icon {
  @include reset-icon();

  font-size: 1.15em;
}

.option-card-old__checked-badge-wrapper {
  text-align: center;
  position: absolute;
  right: 0;
  left: 0;
  bottom: 1px;
  transform: translateY(50%);

  .option-card-old--error.is-animating.option-card-old--animate-in & {
    transform: translateY(60%);
    animation: $error-box-animation-in-duration $error-box-animation-in-easing 0s 1 forwards option-card-old-error-wrapper-in;
  }

  .option-card-old--error.is-animating.option-card-old--animate-out & {
    transform: translateY(50%);
    animation: $error-box-animation-out-duration $error-box-animation-out-easing 0s 1 forwards option-card-old-error-wrapper-out;
  }
}

.option-card-old__checked-badge-inner {
  display: none;
  position: relative;

  &::before,
  &::after {
    content: '';
    display: block;
    height: 10px;
    width: 14px;
    top: 10px;
    position: absolute;
    background-repeat: no-repeat;
  }

  &::before {
    left: -12px;
    background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxMyAxMCI+PHBhdGggZmlsbD0iIzAwMDAwMCIgZD0iTTEzIDEwQzEwLjcgNi4xIDcuOSA2IDIuNyA2aC0uM0MtLjUgNi0uOCA0IDIuMSA0aC42YzUuNCAwIDguMS0uMyAxMC40LTR2MTB6Ii8+PC9zdmc+');
  }

  &::after {
    right: -13px;
    background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxMyAxMCI+PHBhdGggZmlsbD0iIzAwMDAwMCIgZD0iTTAgMGMyLjMgMy45IDUuNCA0IDEwLjcgNCAyLjkgMCAyLjkgMiAwIDJoLS4zYy01LjIgMC04IC4xLTEwLjMgNFYweiIvPjwvc3ZnPg==');
  }

  .option-card-old__input:checked ~ .option-card-old__body & {
    display: inline-block;
  }
}

.option-card-old__badge-top-wrapper {
  text-align: center;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  transform: translateY(-50%);

  .option-card-old--error.is-animating.option-card-old--animate-in & {
    transform: translateY(-60%);
    animation: $error-box-animation-in-duration $error-box-animation-in-easing 0s 1 forwards option-card-old-error-top-wrapperIn;
  }

  .option-card-old--error.is-animating.option-card-old--animate-out & {
    transform: translateY(-50%);
    animation: $error-box-animation-out-duration $error-box-animation-out-easing 0s 1 forwards option-card-old-error-top-wrapper-out;
  }
}

.option-card-old__badge-top-wrapper-inner {
  display: inline-block;
  border-radius: 100rem;
  background: $body-bg;
}

.option-card-old__footer {
  padding: $option-card-old-footer-padding-xs;

  @media (min-width: $media-width-sm-min) {
    padding: $option-card-old-footer-padding;
  }
}

@keyframes option-card-old-error-in {
  0% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba($black, 0.4);
  }

  100% {
    transform: scale($error-box-animation-scale);
    box-shadow: 0 0 20px 0 rgba($black, 0.4);
  }
}

@keyframes option-card-old-error-out {
  0% {
    transform: scale($error-box-animation-scale);
    box-shadow: 0 0 20px 0 rgba($black, 0.4);
  }

  100% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba($black, 0.4);
  }
}

@keyframes option-card-old-error-wrapper-in {
  0% {
    transform: translateY(50%);
  }

  100% {
    transform: translateY(60%);
  }
}

@keyframes option-card-old-error-wrapper-out {
  0% {
    transform: translateY(60%);
  }

  100% {
    transform: translateY(50%);
  }
}

@keyframes option-card-old-error-top-wrapper {
  0% {
    transform: translateY(-50%);
  }

  100% {
    transform: translateY(-60%);
  }
}

@keyframes option-card-old-error-top-wrapper-out {
  0% {
    transform: translateY(-60%);
  }

  100% {
    transform: translateY(-50%);
  }
}
