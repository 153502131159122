@import '~@core-front/sass-tokens/index.scss';

.cookie-notice {
  position: relative;
  pointer-events: auto;
  background-color: $cookie-notice-background-color;
  color: $cookie-notice-color;
  font-size: $cookie-notice-font-size;
  font-weight: $cookie-notice-font-weight;
  z-index: 4;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: flex-end;
  min-height: 35px;
  padding: $cookie-notice-padding;

  @media (min-width: $media-width-sm-min) {
    flex-direction: row;
    align-items: center;
    flex-wrap: nowrap;
    justify-content: $cookie-notice-align-items;
    padding: $cookie-notice-padding-sm;
  }
}

.cookie-notice__main {
  display: flex;
  align-self: stretch;
  align-items: center;
  flex: 0 1 auto;
  margin-bottom: 10px;

  @media (min-width: $media-width-sm-min) {
    margin-bottom: 0;
  }
}

.cookie-notice__icon {
  @include reset-icon();

  flex: 0 0 auto;
  font-size: $cookie-notice-icon-size;
  color: $cookie-notice-icon-color;
  margin-right: 6px;
}

.cookie-notice__content {
  flex: 1 1 auto;
}

.cookie-notice__button {
  flex-shrink: 0;
  margin-left: 20px;
}
