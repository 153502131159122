@use 'sass:color';
@use 'sass:math';

// Page maximum width
$page-max-width: 1200px !default;

// Extra small (320px - 599px)
// Use the modifier "-xs" when defining classes.
$media-width-xs-min: 320px !default;
$media-width-xs-max: 599px !default;

// Small (600px - 799px)
// Use the modifier "-sm" when defining classes.
$media-width-sm-min: 600px !default;
$media-width-sm-max: 799px !default;

// Medium (800px - 999px)
// Use the modifier "-md" when defining classes.
$media-width-md-min: 800px !default;
$media-width-md-max: 999px !default;

// Large (1000px - 1299px)
// Use the modifier "-lg" when defining classes.
$media-width-lg-min: 1000px !default;
$media-width-lg-max: 1299px !default;

// Extra large (1300px - ...)
// Use the modifier "-xl" when defining classes.
$media-width-xl-min: 1300px !default;
$media-width-xl-max: 1449px !default;

// 2xExtra large (1450px - ...)
// Use the modifier "-xxl" when defining classes.
$media-width-xxl-min: 1450px !default;

$zindex: (
  tooltip-on-header: 32,
  tooltip: 32,
  modal: 30,
  modal-footer: 30,
  page-container: 22,
  suggestion: 19,
  overlay: 15,
  affix: 2,
) !default;

$white: #ffffff !default;
$grey: #e4e4e4 !default; // tables and lines
$grey-bl: #dcdce1 !default;
$grey-vl: #e8e8ed !default;
$grey-l: #f2f2f2 !default;
$grey-m: #f6f6f6 !default;
$grey-ld: #8c8c90 !default;
$grey-d: #8a8a8a !default;
$grey-t: #666666 !default;
$grey-b: #333333 !default;

$black: #000000 !default;

$green: #00cc66 !default;

// Additional colors
$pink: #d22db9 !default;
$pink-l: #ff00cd !default;
$red-l: #ff3264 !default;

// Third party colors
$swedbank: #fc8314 !default;
$seb: #51c103 !default;
$danske: #00466d !default;
$lhv: #474747 !default;
$coop: #0079cf !default;
$luminor: #481335 !default;

$google: #f24a2e !default;
$facebook: #3b5998 !default;
$microsoft: #03a4f0 !default;

$color-primary-1: $black !default;
$color-primary-2: $grey-d !default;
$color-campaign-1: $black !default;

$color-success-1: $black !default;

$color-selected-1: $black !default;
$color-selected-2: $grey-d !default;

$color-recommended-1: $white !default;
$color-recommended-2: $black !default;

$color-information-1: $black !default;

$color-inactive-1: $grey-d !default;
$color-inactive-2: $grey-t !default;

$color-warning-1: $black !default;
$color-error-1: $black !default;
$color-error-2: $black !default;
$color-error-3: $grey-d !default;

$color-employee-1: $pink !default;
$color-employee-2: $pink-l !default;
$color-employee-3: rgba(196, 40, 173, 0.1) !default;

$purple-d: $black;
$color-primary-6: $black !default;

// Base spacing
$base-horizontal-spacing-xs: 15px !default;
$base-horizontal-spacing: 20px !default;

// Base animations
$error-box-animation-scale: 1.02 !default;
$error-box-animation-in-easing: cubic-bezier(0.22, 0.61, 0.35, 1) !default;
$error-box-animation-out-easing: cubic-bezier(0.55, 0.05, 0.67, 0.19) !default;
$error-box-animation-in-duration: 300ms !default;
$error-box-animation-out-duration: 400ms !default;

// Elevations
$elevation-0: 0 0 0 0 rgba(0, 0, 0, 0.1) !default;
$elevation-1: 0 1px 2px rgba(0, 0, 0, 0.1) !default;
$elevation-2: 0 2px 4px rgba(0, 0, 0, 0.1) !default;
$elevation-3: 0 4px 8px rgba(0, 0, 0, 0.1) !default;
$elevation-4: 0 8px 16px rgba(0, 0, 0, 0.1) !default;
$elevation-5: 0 12px 24px rgba(0, 0, 0, 0.1) !default;

// Accordion
$accordion-arrow-is-open-color: $color-primary-1 !default;
$accordion-arrow-hover-color: $color-primary-1 !default;
$accordion-arrow-right: 0 !default;
$accordion-arrow-right-sm: 0 !default;
$accordion-arrow-left-sm: auto !default;
$accordion-body-box-shadow: none !default;
$accordion-border-color: $grey !default;
$accordion-content-padding: 0 35px 20px $base-horizontal-spacing-xs !default;
$accordion-content-padding-sm: 0 $base-horizontal-spacing 20px !default;
$accordion-icon-wrapper-top: 50% !default;
$accordion-label-hover-color: $color-primary-1 !default;
$accordion-label-inactive-hover-color: $accordion-label-hover-color !default;
$accordion-header-inner-gap: 8px !default;
$accordion-header-inner-gap-large: 16px !default;
$accordion-header-inner-box-shadow: none !default;
$accordion-header-inner-min-height: 0 !default;
$accordion-header-inner-min-height-sm: 0 !default;
$accordion-header-inner-large-min-height: 82px !default;
$accordion-header-inner-padding-top: 14px !default;
$accordion-header-inner-padding-bottom: 14px !default;
$accordion-header-inner-padding-right: 46px !default;
$accordion-header-inner-padding-left: $base-horizontal-spacing-xs !default;
$accordion-header-inner-padding-right-sm: 56px !default;
$accordion-header-inner-padding-left-sm: $base-horizontal-spacing !default;
$accordion-header-inner-with-icon-padding-top: 17px !default;
$accordion-header-inner-with-icon-padding-bottom: 17px !default;
$accordion-header-inner-no-content-padding-left: 20px !default;
$accordion-header-inner-nth-last-of-type-2-padding-right: 30px !default;
$accordion-header-inner-icon-padding-left: 56px !default;
$accordion-large-header-inner-icon-padding-left: 80px !default;
$accordion-meta-padding: 5px 20px 5px 0 !default;
$accordion-title-hover-color: $color-primary-1 !default;
$accordion-title-open-color: $accordion-title-hover-color !default;
$accordion-title-font-weight: 700 !default;
$accordion-title-padding: 0 36px 0 0 !default;
$accordion-title-padding-sm: 0 20px 0 0 !default;
$accordion-transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
$accordion-large-icon-wrapper-left: -70px !default;
$accordion-large-icon-wrapper-transform: none !default;
$accordion-large-icon-wrapper-top: 0 !default;
$accordion-large-icon-size: 48px !default;
$accordion-large-image-size: 48px !default;
$accordion-label-inactive: $color-inactive-1 !default;
$accordion-icon-inactive: $color-inactive-1 !default;
$accordion-employee-bg: $color-employee-3 !default;
$accordion-employee-open-color: $color-employee-1 !default;
$accordion-employee-hover-color: $color-employee-2 !default;

// Affix
$affix-top: 0 !default;
$affix-top-lg: 0 !default;

// Graph
$graph-unused-color: #e4e4e4 !default;

// Grid
$grid-columns: 12 !default;
$grid-gutter: 20px !default;
$grid-gutter-xs: 14px !default;
$grid-gutter-xsmall: 4px !default;
$grid-gutter-small: 10px !default;

$grid-border-color: #000000 !default;
$grid-border-light-color: #000000 !default;
$grid-border-dark-color: #000000 !default;
$grid-border-on-dark-color: #ffffff !default;

// Reset
$text-color-base: $black !default;
$reset-mark-background: rgba($color-primary-1, 0.1) !default;
$reset-mark-color: $text-color-base !default;

// Typography
// TODO: remove "freesans" after https://github.com/Zenika/alpine-chrome/issues/171 is solved
$font-sans-serif: 'freesans', sans-serif !default;
$font-primary: $font-sans-serif !default;
$font-primary-ru: $font-primary !default;

$body-bg: #ffffff !default;

$base-font-size: 14px !default;
$base-font-size-xl: null !default;
$base-line-height: 1.43 !default;
$base-line-height-xl: null !default;
$font-weight-base: 400 !default;

$font-size-small: 0.8572rem !default;
$font-size-small-xl: null !default;
$line-height-small: 1.5 !default;
$line-height-small-xl: null !default;

$font-size-tiny: 0.8572rem !default;
$font-size-tiny-xl: null !default;
$line-height-tiny: 1.334 !default;
$line-height-tiny: null !default;

$text-color-price: #0099ff !default;
$text-color-light: #8a8a8a !default;

// Heading
$heading-font: $font-primary !default;
$heading-transform: none !default;
$heading-weight: $font-weight-base !default;

$h1-color: inherit !default;
$h1-size: 30px !default; // 30px
$h1-size-sm: null !default;
$h1-size-md: null !default;
$h1-size-xl: null !default;
$h1-line-height: 1.13 !default; // 30/34
$h1-line-height-sm: null !default;
$h1-line-height-md: null !default;
$h1-line-height-xl: null !default;
$h1-transform: $heading-transform !default;
$h1-letter-spacing: null !default;

$h2-size: 1.71rem !default; // 24px
$h2-size-sm: null !default;
$h2-size-md: null !default;
$h2-size-xl: null !default;
$h2-line-height: 1.167 !default; // 24/28
$h2-line-height-sm: null !default;
$h2-line-height-md: null !default;
$h2-line-height-xl: null !default;
$h2-letter-spacing: null !default;

$h3-size: 1.43rem !default; // 20px
$h3-size-sm: null !default;
$h3-size-md: null !default;
$h3-size-xl: null !default;
$h3-line-height: 1.2 !default; // 20/24
$h3-line-height-sm: null !default;
$h3-line-height-md: null !default;
$h3-line-height-xl: null !default;
$h3-transform: $heading-transform !default;
$h3-font-family: $heading-font !default;
$h3-letter-spacing: null !default;

$h4-size: 1.43rem !default; // 20px
$h4-size-xl: null !default;
$h4-line-height: 1.3 !default; // 20/26
$h4-line-height-xl: null !default;
$h4-font-weight: inherit !default;

$h5-size: 1.143rem !default; // 16px
$h5-size-xl: null !default;
$h5-line-height: 1.375 !default; // 16/22
$h5-line-height-xl: null !default;
$h5-font-weight: inherit !default;

$h6-size: $base-font-size !default;
$h6-size-xl: $base-font-size-xl !default;
$h6-line-height: $base-line-height !default;
$h6-line-height-xl: $base-line-height-xl !default;
$h6-font-weight: inherit !default;

// Anchor
$anchor-weight: 700 !default;
$anchor-text-color-hover-color: $color-primary-1 !default;
$anchor-color: $color-primary-1 !default;
$anchor-hover-color: $color-primary-2 !default;

// Badge
$badge-font-size: 12px !default;
$badge-icon-size: 16px !default;
$badge-background: $color-campaign-1 !default;
$badge-color: $white !default;
$badge-inactive-background: $color-inactive-1 !default;
$badge-inactive-color: $white !default;
$badge-recommended-background: $color-recommended-2 !default;
$badge-recommended-color: $color-recommended-1 !default;
$badge-selected-background: $color-selected-1 !default;
$badge-selected-color: $badge-color !default;
$badge-success-color: $white !default;
$badge-success-background: $color-success-1 !default;
$badge-size-small: 16px !default;
$badge-size-large: 34px !default;
$badge-size-large-line-height: 24px !default;
$badge-size-large-icon-width: 24px !default;
$badge-size-large-icon-height: 24px !default;
$badge-size-large-icon-size-sm: 16px !default;
$badge-spacing-small: 2.5px !default;

$badge-font-size-small: 10px !default;
$badge-font-size-large: 22px !default;
$badge-font-size-large-sm: 16px !default;
$badge-line-height-large-sm: 16px !default;
$badge-font-height-small: 11px !default;
$badge-font-weight-large: 400 !default;
$badge-font-weight-large-sm: 700 !default;

$badge-font-size-medium: 14px !default;
$badge-line-height-medium: 20px !default;

$badge-icon-size-small: 10px !default;
$badge-icon-margin-small: 0 !default;

$badge-text-spacing-small: 0 !default;
$badge-text-transform: none !default;
$badge-padding: 7px !default;
$badge-line-height: 16px !default;
$badge-height: calc(#{$badge-padding * 2} + $badge-line-height) !default;

// Breadcrumbs
$breadcrumbs-link-color: $color-inactive-1 !default;
$breadcrumbs-link-hover-color: $color-primary-1 !default;
$breadcrumbs-icon-color: $color-inactive-2 !default;

// Buttons
$btn-min-width: 125px !default;
$btn-min-width-sm: 150px !default;
$btn-min-height: 42px !default;
$btn-font-size: 12px !default;
$btn-line-height: 1.4 !default;
$btn-text-transform: uppercase !default;
$btn-border-width: 1px !default;
$btn-padding: 8px !default;
$btn-min-horizontal-padding: 30 - $btn-border-width !default;
$btn-min-vertical-padding: 0 !default;
$btn-border-radius: 30px !default;
$btn-letter-spacing: 0.2px !default;
$btn-primary-color: $white !default;
$btn-spinner-color: currentcolor !default;
$btn-spinner-background-color: transparent !default;
$btn-loading-background-opacity: 1 !default;
$btn-icon-size: 24px !default;
$btn-spinner-size: 24px !default;

$btn-small-min-height: 26px !default;
$btn-small-font-size: 11px !default;
$btn-small-line-height: 16px !default;
$btn-small-padding: 4px !default;
$btn-icon-only-small-padding: 0 !default;
$btn-small-min-horizontal-padding: 20 - $btn-border-width !default;
$btn-small-border-radius: 15px !default;
$btn-small-icon-size: 16px !default;
$btn-small-spinner-size: 13px !default;

$btn-icon-min-width: 42px !default;
$btn-icon-outer-padding: 24px - $btn-border-width - $btn-min-horizontal-padding !default;
$btn-icon-inner-padding: 6px !default;
$btn-icon-grid-icon-size: 10px !default;
$btn-icon-large-size: 12px !default;
$btn-icon-wrapper-margin-left: 0 !default;
$btn-icon-wrapper-margin-right: 0 !default;

$btn-small-icon-padding: 15px - $btn-border-width !default;
$btn-small-icon-spacing: 6px !default;

$btn-large-font-size: 16px !default;

$btn-icon-translate: translateY(-50%) !default;
$btn-icon-transition: cubic-bezier(0.25, 0.1, 0.25, 1) !default;
$btn-icon-transition-length: 5px !default;
$btn-small-icon-transition-length: 4px !default;

$btn-inner-min-height: 24px !default;
$btn-small-inner-min-height: 16px !default;

$btn-primary-background: $black !default;

// Button variants

$btn-link-letter-spacing: 0 !default;
$btn-link-text-decoration: null !default;
$btn-link-disabled-pointer-events: auto !default;
$btn-link-spinner-size: 20px !default;
$btn-link-inner-loading-margin: 25px !default;
$btn-link-small-spinner-size: 20px !default;
$btn-link-small-inner-loading-margin: 25px !default;

$btn-outer-swed-color: $swedbank !default;
$btn-outer-swed-background: transparent !default;
$btn-outer-swed-background-hover: $swedbank !default;
$btn-outer-swed-border: $swedbank !default;
$btn-outer-swed-border-hover: $swedbank !default;
$btn-outer-swed-hover-color: $white !default;
$btn-outer-swed-pressed-color: $white !default;
$btn-outer-swed-pressed-background: $swedbank !default;

$btn-outer-seb-color: $seb !default;
$btn-outer-seb-background: transparent !default;
$btn-outer-seb-background-hover: $seb !default;
$btn-outer-seb-border: $seb !default;
$btn-outer-seb-border-hover: $seb !default;
$btn-outer-seb-hover-color: $white !default;
$btn-outer-seb-pressed-color: $white !default;
$btn-outer-seb-pressed-background: $seb !default;

$btn-outer-luminor-color: $luminor !default;
$btn-outer-luminor-background: transparent !default;
$btn-outer-luminor-background-hover: $luminor !default;
$btn-outer-luminor-border: $luminor !default;
$btn-outer-luminor-border-hover: $luminor !default;
$btn-outer-luminor-hover-color: $white !default;
$btn-outer-luminor-pressed-color: $white !default;
$btn-outer-luminor-pressed-background: $luminor !default;

$btn-outer-danske-color: $danske !default;
$btn-outer-danske-background: transparent !default;
$btn-outer-danske-background-hover: $danske !default;
$btn-outer-danske-border: $danske !default;
$btn-outer-danske-border-hover: $danske !default;
$btn-outer-danske-hover-color: $white !default;
$btn-outer-danske-pressed-color: $white !default;
$btn-outer-danske-pressed-background: $danske !default;

$btn-outer-coop-color: $coop !default;
$btn-outer-coop-background: transparent !default;
$btn-outer-coop-background-hover: $coop !default;
$btn-outer-coop-border: $coop !default;
$btn-outer-coop-border-hover: $coop !default;
$btn-outer-coop-hover-color: $white !default;
$btn-outer-coop-pressed-color: $white !default;
$btn-outer-coop-pressed-background: $coop !default;

$btn-outer-lhv-color: $lhv !default;
$btn-outer-lhv-background: transparent !default;
$btn-outer-lhv-background-hover: $lhv !default;
$btn-outer-lhv-border: $lhv !default;
$btn-outer-lhv-border-hover: $lhv !default;
$btn-outer-lhv-hover-color: $white !default;
$btn-outer-lhv-pressed-color: $white !default;
$btn-outer-lhv-pressed-background: $lhv !default;

$btn-outer-google-color: $google !default;
$btn-outer-google-background: transparent !default;
$btn-outer-google-background-hover: $google !default;
$btn-outer-google-border: $google !default;
$btn-outer-google-border-hover: $google !default;
$btn-outer-google-hover-color: $white !default;
$btn-outer-google-pressed-color: $white !default;
$btn-outer-google-pressed-background: $google !default;

$btn-outer-facebook-color: $facebook !default;
$btn-outer-facebook-background: transparent !default;
$btn-outer-facebook-background-hover: $facebook !default;
$btn-outer-facebook-border: $facebook !default;
$btn-outer-facebook-border-hover: $facebook !default;
$btn-outer-facebook-hover-color: $white !default;
$btn-outer-facebook-pressed-color: $white !default;
$btn-outer-facebook-pressed-background: $facebook !default;

$btn-outer-microsoft-color: $microsoft !default;
$btn-outer-microsoft-background: transparent !default;
$btn-outer-microsoft-background-hover: $microsoft !default;
$btn-outer-microsoft-border: $microsoft !default;
$btn-outer-microsoft-border-hover: $microsoft !default;
$btn-outer-microsoft-hover-color: $white !default;
$btn-outer-microsoft-pressed-color: $white !default;
$btn-outer-microsoft-pressed-background: $microsoft !default;

$btn-text-color: $white !default;
$btn-text-color-background-color: $black !default;
$btn-text-color-background-hover-color: color.adjust($black, $lightness: 20%) !default;
$btn-text-color-background-pressed-color: color.adjust($black, $lightness: 20%) !default;
$btn-text-color-border-hover-color: color.adjust($black, $lightness: 20%) !default;
$btn-text-color-link-color: $black !default;
$btn-text-color-link-hover-color: $color-primary-1 !default;

$btn-primary-background: $color-primary-1 !default;
$btn-primary-background-hover-color: $color-primary-2 !default;
$btn-primary-border-color: $color-primary-1 !default;
$btn-primary-border-hover-color: $color-primary-2 !default;
$btn-primary-hover-color: $white !default;
$btn-primary-pressed-color: $white !default;
$btn-primary-pressed-background: $color-primary-1 !default;

$btn-primary-link-color: $color-primary-1 !default;
$btn-primary-link-hover-color: $color-primary-2 !default;
$btn-primary-link-pressed-color: $color-primary-2 !default;

$btn-employee-color: $white !default;
$btn-employee-hover-color: $white !default;
$btn-employee-background: $color-employee-1 !default;
$btn-employee-background-hover: $color-employee-2 !default;
$btn-employee-border-color: $color-employee-1 !default;
$btn-employee-border-hover-color: $color-employee-2 !default;
$btn-employee-link-color: $color-employee-1 !default;
$btn-employee-link-hover-color: $color-employee-2 !default;
$btn-employee-link-pressed-color: $color-employee-2 !default;
$btn-employee-pressed-color: $white !default;
$btn-employee-pressed-background: $color-employee-2 !default;

$btn-employee-secondary-color: $color-employee-1 !default;
$btn-employee-secondary-color-hover: $white !default;
$btn-employee-secondary-background: transparent !default;
$btn-employee-secondary-background-hover: $color-employee-1 !default;
$btn-employee-secondary-border: $color-employee-1 !default;
$btn-employee-secondary-border-hover: $color-employee-1 !default;
$btn-employee-secondary-box-shadow-hover: null !default;
$btn-employee-secondary-pressed-color: $white !default;
$btn-employee-secondary-pressed-background: $color-employee-2 !default;

$btn-withdrawal-color: $white !default;
$btn-withdrawal-background: $color-error-1 !default;
$btn-withdrawal-background-hover: $color-error-2 !default;
$btn-withdrawal-border-color: $color-error-1 !default;
$btn-withdrawal-border-hover-color: $color-error-2 !default;
$btn-withdrawal-link-color: $color-error-1 !default;
$btn-withdrawal-link-hover-color: $color-error-2 !default;
$btn-withdrawal-link-pressed-color: $color-error-3 !default;
$btn-withdrawal-pressed-background: $color-error-3 !default;

$btn-withdrawal-secondary-color: $color-error-1 !default;
$btn-withdrawal-secondary-background: transparent !default;
$btn-withdrawal-secondary-background-hover: $color-error-1 !default;
$btn-withdrawal-secondary-border: $color-error-1 !default;
$btn-withdrawal-secondary-border-hover: $color-error-1 !default;
$btn-withdrawal-secondary-box-shadow-hover: null !default;
$btn-withdrawal-secondary-pressed-background: $color-error-2 !default;

$btn-expressive-color: $btn-primary-color !default;
$btn-expressive-background: $color-primary-1 !default;
$btn-expressive-background-hover: $color-primary-2 !default;
$btn-expressive-border-color: $color-primary-1 !default;
$btn-expressive-border-hover-color: $color-primary-2 !default;
$btn-expressive-link-color: $color-primary-1 !default;
$btn-expressive-link-hover-color: $color-primary-2 !default;
$btn-expressive-link-pressed-color: $color-primary-2 !default;
$btn-expressive-pressed-color: $white !default;
$btn-expressive-pressed-background: $color-primary-2 !default;

$btn-secondary-color: $color-primary-1 !default;
$btn-secondary-color-hover: $white !default;
$btn-secondary-background: transparent !default;
$btn-secondary-background-hover: $color-primary-1 !default;
$btn-secondary-icon-only-background-hover: $color-primary-1 !default;
$btn-secondary-border: $color-primary-1 !default;
$btn-secondary-border-hover: $color-primary-1 !default;
$btn-secondary-icon-only-border-hover: $color-primary-1 !default;
$btn-secondary-icon-only-border: $btn-secondary-icon-only-border-hover !default;
$btn-secondary-box-shadow-hover: null !default;
$btn-secondary-pressed-color: $white !default;
$btn-secondary-pressed-background: $color-primary-1 !default;

$btn-disabled-color: $white !default;
$btn-disabled-background-color: $color-inactive-1 !default;
$btn-disabled-border-color: $color-inactive-1 !default;
$btn-disabled-link-color: $color-inactive-2 !default;

// Carousel
$carousel-dots-with-arrows-bottom: 17px !default;
$carousel-dots-with-small-arrows-bottom: 9px !default;
$carousel-dots-active-color: $black !default;
$carousel-dots-color: rgba($black, 0.15) !default;
$carousel-horizontal-padding-default: 24px !default;
$carousel-horizontal-padding-default-sm: 16px !default;
$carousel-horizontal-padding-small: 16px !default;
$carousel-horizontal-padding-small-sm: 8px !default;
$carousel-horizontal-padding-none: $grid-gutter !default;
$carousel-horizontal-padding-none-sm: $grid-gutter-xs !default;
$carousel-default-peeking-slide-width: 30px !default;
$carousel-align-left-peeking-slide-width: 60px !default;

// Card
$card-border-width: 1px !default;
$card-border-selected-width: 2px !default;
$card-border-radius-small: 4px !default;
$card-border-radius-medium: 8px !default;
$card-border-radius-large: 16px !default;
$card-disabled-opacity: 0.5 !default;
$card-box-shadow: 0 1px 1px rgba($black, 0.15) !default;
$card-padding-none: 0 !default;
$card-padding-xsmall: 8px !default;
$card-padding-small: 16px !default;
$card-padding-medium: 24px !default;
$card-padding-large: 32px !default;
$card-padding-xlarge: 48px !default;
$card-padding-xxlarge: 64px !default;
$card-footer-vertical-padding: 16px !default;
$card-header-vertical-padding: 16px !default;
$card-elevation-0: $elevation-0 !default;
$card-elevation-1: $elevation-1 !default;
$card-elevation-2: $elevation-2 !default;
$card-elevation-3: $elevation-3 !default;
$card-elevation-4: $elevation-4 !default;
$card-elevation-5: $elevation-5 !default;
$card-type-success-border-color: $color-success-1 !default;
$card-type-error-border-color: $color-error-1 !default;
$card-type-employee-border-color: $color-employee-1 !default;
$card-selectable-border-color: $grey-bl !default;
$card-selectable-employee-color: $color-employee-1 !default;
$card-selectable-hover-border-color: $color-primary-2 !default;
$card-selectable-employee-hover-border-color: $color-employee-2 !default;
$card-selectable-disabled-border-color: $grey-bl !default;
$card-selected-border-color: $color-primary-1 !default;
$card-selected-border-employee-color: $color-employee-1 !default;

$card-hover-border-color: $color-primary-1 !default;
$card-content-tag-top-margin: 16px;
$card-content-tag-bottom-margin: 16px;
$card-hover-bg: rgba(0, 0, 0, 0.05) !default;

$card-paddings: (
  none $card-padding-none,
  xsmall $card-padding-xsmall,
  small $card-padding-small,
  medium $card-padding-medium,
  large $card-padding-large,
  xlarge $card-padding-xlarge,
  xxlarge $card-padding-xxlarge
);

// CardOld
$card-old-border-width: 2px !default;
$card-old-border-radius: 3px !default;
$card-old-shape-option-border-radius: 12px !default;
$card-old-disabled-opacity: 0.5 !default;

// Check
$check-indicator-size: 24px !default;
$check-icon-size: 16px !default;
$check-weight: 400 !default;
$check-border-color: $grey-d !default;
$check-background: $white !default;
$check-indicator-radius: 3px !default;
$check-disabled-text-color: $grey-d !default;
$check-disabled-border-color: $grey-d !default;
$check-disabled-background-color: $white !default;
$check-disabled-indicator-color: $white !default;
$check-indicator-color: $white !default;
$check-employee-indicator-color: $white !default;
$check-employee-indicator-background-color: $color-employee-1 !default;
$check-employee-indicator-border-color: $color-employee-1 !default;
$check-employee-subtle-background-color: rgba($color-employee-1, 0.1) !default;
$check-checked-background: $color-selected-1 !default;
$check-checked-border: $color-selected-1 !default;
$check-disabled-checked-background-color: $grey-d !default;
$check-disabled-checked-border-color: $grey-d !default;

// ChoiceGroup
$choice-group-purpose-agreement-background: #8a8a8a !default;
$choice-group-purpose-agreement-box-shadow: #000000 !default;
$choice-group-purpose-agreement-invalid-border: $color-error-1 !default;
$choice-group-purpose-agreement-border-radius: 3px !default;
$choice-group-form-helper-help: #8a8a8a !default;
$choice-group-label-font-size: 14px !default;

// Chip
$chip-border-color: $grey !default;
$chip-hover-color: $color-primary-1 !default;

// ClearButton
$clear-button-color: $text-color-base !default;
$clear-button-hover: $color-primary-1 !default;

// CloseButton
$close-button-color: $black !default;
$close-button-hover-color: $color-primary-1 !default;
$close-button-background: transparent !default;
$close-button-font-size: 24px !default;

// Collapse
$collapse-background-color: $white !default;
$collapse-background-color-grey: $grey-m !default;
$collapse-border-color: $color-primary-1 !default;
$collapse-close-button-color: $text-color-base !default;
$collapse-close-button-color-hover: $color-primary-1 !default;

// container
$container-padding: 16px !default;
$container-padding-sm: 24px !default;
$container-padding-lg: 24px !default;

// CookieNotice
$cookie-notice-background-color: #d9f8ff !default;
$cookie-notice-color: #000000 !default;
$cookie-notice-font-size: 12px !default;
$cookie-notice-font-weight: 700 !default;
$cookie-notice-icon-color: #000000 !default;
$cookie-notice-icon-size: 16px !default;
$cookie-notice-align-items: flex-start !default;
$cookie-notice-padding: 5px 10px 10px 10px !default;
$cookie-notice-padding-sm: 5px 20px 4px 20px !default;

// Datepicker
$datepicker-bg: $white !default;
$datepicker-text-color: (
  hover: $black,
  selected: $white,
) !default;
$datepicker-background: (
  hover: $grey-l,
  selected: $color-selected-1,
) !default;
$datepicker-day-width: math.div(1, 7) * 100% !default;
$datepicker-buttons-bg: $grey-m !default;
$datepicker-column-border: $grey !default;
$datepicker-day-disabled-color: $color-inactive-1 !default;
$datepicker-value-color: $color-selected-1 !default;

// FormHelper
$form-helper-check-length: 36px !default;
$form-helper-size: 12px !default;
$form-helper-help-color: #000000 !default;
$form-helper-valid-color: #000000 !default;
$form-helper-valid-icon-color: $green !default;
$form-helper-error-color: #000000 !default;
$form-helper-error-icon-color: inherit !default;
$form-helper-error-font-weight: 400 !default;

// Horizontal progress
$horizontal-progress-title-color: $text-color-light !default;
$horizontal-progress-title-color-current-done: $text-color-base !default;
$horizontal-progress-current-color: $color-primary-1 !default;
$horizontal-progress-indicator-line-inactive-color: $grey !default;
$horizontal-progress-indicator-line-success-color: $color-success-1 !default;
$horizontal-progress-content-border-color: $grey !default;

// Icon
$icon-font-size-xxs: 16px !default;
$icon-font-size-xs: 24px !default;
$icon-font-size-sm: 32px !default;
$icon-font-size-md: 40px !default;
$icon-font-size-lg: 48px !default;
$icon-font-size-xl: 56px !default;
$icon-font-size-xxl: 64px !default;
$icon-font-size-xxxl: 96px !default;

// Iframe
$iframe-consent-background: $color-primary-1 !default;
$iframe-consent-inner-background: $white !default;

// Image
$image-border-rounded: 3px;

// List
$list-xsmall-font-size: $font-size-small !default;
$list-xsmall-line-height: $base-line-height !default;
$list-large-font-size-sm: $h4-size !default;
$list-large-font-size-md: $h4-size !default;
$list-large-font-size-xl: $h4-size-xl !default;
$list-text-color: currentcolor !default;
$list-icon-color: inherit !default;
$list-font-weight: 400 !default;
$list-border-color: #000000 !default;
$list-marker-top-position-xl: 7px !default;
$list-icon-spacing-xsmall: 0.25em !default;
$list-icon-spacing-small: 0.3333em !default;
$list-icon-spacing-large: 0.5em !default;
$list-spacing-small: 2px !default;
$list-spacing-medium: 4px !default;
$list-spacing-large: 8px !default;
$list-ordered-counter-weight: 700 !default;
$list-bullet-color: currentcolor !default;
$list-font-size: $base-font-size !default;
$list-line-height: inherit !default;

// Logo
$logo-padding-top: math.div(52, 100) * 100% !default; // (height / width * 100%)
$logo-background-url: '~@core-front/static-assets/images/core-logo.svg' !default;
$logo-small-width: 65px !default;
$logo-small-width-sm: $logo-small-width !default;

// Main
$main-header-padding-top: 100px !default;
$main-header-padding-top-lg: 110px !default;
$main-content-padding: 30px 0 100px !default;
$main-inner-min-height: 100vh !default;

// MiniForm
$mini-form-input-padding-right: 42px !default;
$mini-form-button-top: 15px !default;
$mini-form-hide-label-button-top: 8px !default;

// Modal
$modal-max-width: 1230px !default;
$modal-backdrop-color: rgba($grey-b, 0.8) !default;
$modal-background-color: $white !default;
$modal-box-shadow: unset !default;
$modal-header-background-color: $grey-m !default;
$modal-footer-background-color: $grey-m !default;
$modal-border-radius: 3px !default;
$modal-border: null !default;

// Notice
$notice-base-background: $white !default;
$notice-colors: (
  info: (
    icon: $color-information-1,
    background: $white,
    border: $color-information-1,
  ),
  success: (
    icon: $color-success-1,
    background: $white,
    border: $color-success-1,
  ),
  employee: (
    icon: $color-employee-1,
    background: $white,
    border: $color-employee-1,
  ),
  error: (
    icon: $color-error-1,
    background: $white,
    border: $color-error-1,
  ),
  attention: (
    icon: $color-primary-1,
    background: $white,
    border: $color-primary-1,
  ),
) !default;
$notice-border-size: 1px !default;
$notice-border-radius: 4px !default;
$notice-icon-size: 32px !default;
$notice-success-icon-size: 32px !default;
$notice-success-icon-size-sm: 48px !default;
$notice-close-padding-left: 16px !default;
$notice-close-padding-left-sm: 8px !default;
$notice-close-icon-size: 24px !default;
$notice-gap-sm: 8px !default;
$notice-gap: 16px !default;

// Number
$number-input-height: 42px !default;
$number-small-input-height: 26px !default;
$number-input-width: 60px !default;
$number-input-margin: 0 9px !default;
$number-input-small-margin: 0 4px !default;
$number-input-small-width: 40px !default;
$number-input-border-color: $grey-d !default;
$number-input-border-radius: 3px !default;
$number-max-width: 130px !default;
$number-small-max-width: 100px !default;
$number-invalid-color: $color-error-1 !default;
$number-disabled-color: $color-inactive-1 !default;
$number-input-disabled-border-color: $grey-d !default;
$number-input-focus-outline: $black !default;

// OptionCard
$option-card-border-radius: 12px !default;
$option-card-error-color: $color-error-1 !default;
$option-card-checked-border-width: 2px !default;
$option-card-disabled-border-color: $grey-bl !default;
$option-card-disabled-selected-border-color: rgba($black, 0.38) !default;
$option-card-indicator-background: $white !default;
$option-card-indicator-color: $white !default;
$option-card-indicator-color-employee: $white !default;
$option-card-indicator-background-selected: $color-selected-1 !default;
$option-card-indicator-background-selected-border: $color-selected-1 !default;
$option-card-indicator-employee-background-selected: $color-employee-1 !default;
$option-card-indicator-employee-background-selected-border: $color-employee-1 !default;

$option-card-grey-background: $grey-m !default;

$option-card-indicator-top-padding: 16px !default;
$option-card-indicator-right-padding: 16px !default;

$option-card-indicator-size: 24px !default;
$option-card-indicator-icon-size: 18px !default;
$option-card-indicator-border-color: $grey-ld !default;
$option-card-indicator-disabled-background-color: $grey-vl !default;
$option-card-indicator-disabled-border-color: $grey-bl !default;
$option-card-indicator-disabled-color: rgba($black, 0.38) !default;

// OptionCardOld
$option-card-old-recommended-color: $grey-l !default;
$option-card-old-border-radius: 12px !default;
$option-card-old-box-shadow: 0 1px 1px rgba($black, 0.1) !default;
$option-card-old-text-align: left !default;
$option-card-old-error-color: $color-error-1 !default;

$option-card-old-grey-background: $grey-m !default;
$option-card-old-grey-box-shadow: 0 1px 1px rgba($black, 0.2) !default;

$option-card-old-content-padding: 14px 30px !default;
$option-card-old-content-padding-xs: 14px 20px !default;
$option-card-old-content-vertical-padding: 40px !default;
$option-card-old-content-vertical-padding-xs: 30px !default;

$option-card-old-footer-padding: 16px 30px !default;
$option-card-old-footer-padding-xs: 16px 20px !default;
$option-card-old-background-color: $white !default;

$option-card-old-checkbox-bg: $white !default;
$option-card-old-checkbox-border: $grey-d !default;
$option-card-old-checkbox-color: $white !default;
$option-card-old-checkbox-disabled-bg: $grey-d !default;
$option-card-old-checkbox-disabled-border: $grey-d !default;

// Overlay
$overlay-background: $grey !default;

// Passfield
$passfield-button-top: 10px !default;
$passfield-button-right: 5px !default;
$passfield-input-padding-right: 40px !default;
$passfield-button-hover-color: $color-primary-1 !default;

// Radio
$radio-indicator-size: 24px !default;
$radio-border-color: $grey-d !default;
$radio-disabled-color: $grey-d !default;
$radio-inactive-color: $grey-d !default;
$radio-employee-background: rgba($color-employee-1, 0.1) !default;
$radio-indicator-bg: $white !default;
$radio-indicator-checked-color: $white !default;
$radio-indicator-checked-bg: $color-selected-1 !default;
$radio-indicator-checked-border: $color-selected-1 !default;
$radio-indicator-employee-color: $white !default;
$radio-indicator-employee-bg: $color-employee-1 !default;
$radio-indicator-employee-border: $color-employee-1 !default;
$radio-indicator-disabled-color: $white !default;
$radio-indicator-disabled-bg: $white !default;
$radio-indicator-disabled-border: $radio-inactive-color !default;
$radio-indicator-disabled-checked-bg: $radio-inactive-color !default;
$radio-indicator-disabled-checked-border: $radio-inactive-color !default;
$radio-indicator-disabled-opacity: 0.5 !default;

// Search
$search-textfield-label-color: #000000 !default;
$search-action-hover-color: #000000 !default;
$search-action-disabled-color: #8a8a8a !default;

// Select
$select-primary-color: $black !default;
$select-secondary-color: $grey-d !default;
$select-secondary-text-color: $grey-t !default;
$select-menu-color: $white !default;
$select-disabled-color: $grey-d !default;
$select-disabled-border-color: $grey-d !default;
$select-error-border-color: $color-error-1 !default;
$select-employee-selected-color: $color-employee-1 !default;
$select-employee-focused-color: $color-employee-1 !default;
$select-employee-background-color: rgba($color-employee-1, 0.1) !default;
$select-focus-color: $black !default;
$select-focus-label-color: $black !default;
$select-icon-top-position: 9px !default;
$select-small-icon-top-position: 14px !default;
$select-icon-color: inherit !default;
$select-value-align: left !default;
$select-sub-label-color: $color-inactive-1 !default;
$select-icon-size: 24px !default;
$select-icon-size-small: 16px !default;
$select-option-selected-font-weight: unset !default;
$select-box-shaddow: 0 3px 6px rgba($select-primary-color, 0.2) !default;
$select-disabled-opacity: 0.5 !default;

$select-option-border-color: $grey !default;
$select-option-selected-color: $color-selected-1 !default;
$select-option-focused-color: $color-selected-1 !default;
$select-option-focused-background-color: $grey-m !default;

$select-option-employee-color: $color-employee-1 !default;
$select-option-employee-focused-color: $color-employee-1 !default;

// Selector
$selector-background: $grey-l !default;
$selector-border-width: 1px !default;
$selector-border-radius: 3px !default;
$selector-border-color: $grey !default;
$selector-border-hover-color: $color-selected-2 !default;
$selector-border-hover-color-custom: $grey-d !default;
$selector-selected-color: $white !default;
$selector-selected-background-color: $color-selected-1 !default;
$selector-disabled-color: $grey-b !default;
$selector-disabled-border-color: $grey !default;
$selector-disabled-background: $grey !default;
$selector-disabled-selected-color: $black !default;
$selector-disabled-selected-background: $white !default;
$selector-inactive-text-color: $text-color-base !default;
$selector-form-helper-margin-top: 5px !default;
$selector-form-helper-help: $black !default;

$selector-font-size: 14px !default;
$selector-line-height: $base-line-height !default;
$selector-padding-vertical: 10px !default;
$selector-padding-horizontal: 15px !default;
$selector-min-height: auto !default;

$selector-small-font-size: 12px !default;
$selector-small-line-height: $base-line-height !default;
$selector-small-padding-vertical: 2px !default;
$selector-small-padding-horizontal: 10px !default;
$selector-small-min-height: auto !default;

$selector-large-font-size: 14px !default;
$selector-large-line-height: $base-line-height !default;
$selector-large-padding-vertical: 19px !default;
$selector-large-padding-horizontal: 15px !default;
$selector-large-min-height: auto !default;

$selector-xlarge-font-size: 20px !default;
$selector-xlarge-line-height: $base-line-height !default;
$selector-xlarge-padding-vertical: 25px !default;
$selector-xlarge-padding-horizontal: 15px !default;
$selector-xlarge-size: 80px !default;
$selector-xlarge-min-height: auto !default;

$selector-selected-border-color: $color-selected-1 !default;
$selector-inactive-color: $grey !default;
$selector-selected-background-hover-color: $color-selected-2 !default;
$selector-selected-border-hover-color: $color-selected-2 !default;
$selector-selected-color-border-color: $selector-selected-border-color !default;

$selector-color-employee: $color-employee-1 !default;
$selector-border-hover-color-employee: $color-employee-2 !default;
$selector-selected-color-employee: $white !default;
$selector-selected-background-color-employee: $color-employee-1 !default;
$selector-selected-border-color-employee: $color-employee-1 !default;
$selector-selected-hover-color-employee: $white !default;
$selector-selected-background-hover-color-employee: $color-employee-2 !default;
$selector-selected-border-hover-color-employee: $color-employee-2 !default;

// Separator
$separator-color-default: rgba($black, 0.12) !default;
$separator-color-medium: rgba($black, 0.24) !default;
$separator-color-strong: rgba($black, 0.48) !default;
$separator-color-dark: rgba($white, 0.12) !default;
$separator-color-dark-medium: rgba($white, 0.24) !default;
$separator-color-dark-strong: rgba($white, 0.48) !default;
$separator-weight-default: 2px !default;
$separator-weight-thin: 1px !default;

// Signature
$signature-background: $grey-m !default;
$signature-background-disabled: $grey-l !default;
$signature-border-color: $color-inactive-1 !default;
$signature-light-background: $white !default;
$signature-employee-border: $color-employee-1 !default;
$signature-invalid-border: $color-error-1 !default;
$signature-focus-border: $black !default;
$signature-employee-focus-border: $black !default;
$signature-disabled-opacity: 0.5 !default;
$signature-disabled-border-color: $color-inactive-1 !default;
$signature-disabled-label-color: $text-color-light !default;

// SkipLinks
$skip-links-background-color: $grey-l !default;
$skip-links-border-color: $grey !default;
$skip-links-padding-size: 10px !default;
$skip-links-font-size: 12px !default;
$skip-links-line-height: 16px !default;

// Skeleton
$skeleton-background-color: $grey-d !default;
$skeleton-background-color-secondary: $grey !default;

// Spinner
$spinner-color: $color-primary-1 !default;

// Suggestion
$suggestion-font-size: 16px !default;
$suggestion-background: #ffffff !default;
$suggestion-shadow: 0 3px 6px rgba(#000000, 0.2) !default;
$suggestion-border-radius: 3px !default;
$suggestion-link-padding: 9px 21px !default;
$suggestion-link-hover-color: #000000 !default;
$suggestion-link-hover-background: #000000 !default;
$suggestion-link-active-color: #8a8a8a !default;
$suggestion-item-background: #000000 !default;
$suggestion-link-background: #8a8a8a !default;
$suggestion-item-margin-top: -1px !default;
$suggestion-link-active-color-employee: #d22db9 !default;
$suggestion-group-label-color: $color-inactive-1 !default;
$suggestion-group-label-font-size: 12px !default;
$suggestion-sub-label-color: #000000 !default;
$suggestion-sub-label-font-size: 12px !default;
$suggestion-icon-size: 24px !default;
$suggestion-image-width: 22px !default;
$suggestion-label-mark-font-weight: 700 !default;

// Tabs
$tabs-text-color: $text-color-base !default;
$tabs-hover-text-color: $color-primary-2 !default;
$tabs-selected-text-color: $color-primary-1 !default;
$tabs-border-color: rgba($black, 0.12) !default;
$tabs-selected-border-color: $color-primary-1 !default;
$tabs-nav-link-font-weight: 400 !default;
$tabs-nav-link-icon-color: $black !default;
$tabs-employee-border-color: $color-employee-1 !default;
$tabs-employee-text-color: $color-employee-1 !default;
$tabs-employee-hover-text-color: $color-employee-2 !default;
$tabs-employee-selected-text-color: $color-employee-1 !default;

// Transition
$transition-duration: 150ms !default;
$transition-timing-function: ease !default;
$transition-default: $transition-duration $transition-timing-function !default;

// Text
$text-h1-margin-bottom: 0.5em !default;

// Textfield
$textfield-label-size: 12px !default;
$textfield-label-padding: 0 !default;
$textfield-floating-label-size: 16px !default;
$textfield-label-start-position: 17px !default;
$textfield-label-border: 1px solid $grey-d !default;
$textfield-label-border-radius: 3px !default;
$textfield-label-color: $black !default;
$textfield-label-font-weight: initial !default;
$textfield-label-pointer-events: none !default;
$textfield-border-color: $grey-d !default;
$textfield-border: 1px solid $textfield-border-color !default;
$textfield-border-radius: 0 0 3px 3px !default;
$textfield-border-top-position: 7px !default;
$textfield-floating-label-border-top-position: -10px !default;
$textfield-box-shadow: none !default;
$textfield-background: none !default;
$textfield-height: 32px !default;
$textfield-weight: 400 !default;
$textfield-label-visible-height: 17px !default;
$textfield-padding: 0 20px 10px !default;
$textfield-static-padding: 0 0 10px !default;
$textfield-after-visibility: visible !default;
$textfield-dirty-border-color: $grey-d !default;
$textfield-dirty-text-color: $grey-t !default;
$textfield-disabled-border: 1px solid $grey !default;
$textfield-disabled-background: transparent !default;
$textfield-valid-padding-right: 40px !default;
$textfield-icon-top: 15px !default;
$textfield-icon-valid-top: 21px !default;
$textfield-image-top: 19px !default;
$textfield-image-left: 15px !default;
$textfield-image-no-label-top: 13px !default;
$textfield-no-label-padding-top: 10px !default;
$textfield-form-helper-margin-top: 5px !default;
$textfield-text-align: initial !default;
$textfield-transform: none !default;
$textfield-font-size: 16px !default;

$textfield-color: $black !default;
$textfield-disabled-color: $grey-d !default;
$textfield-helper-color: $grey-t !default;
$textfield-floating-label-color: $black !default;

$textfield-small-label-size: 10px !default;
$textfield-small-floating-label-size: 12px !default;
$textfield-small-label-start-position: 12px !default;
$textfield-small-floating-label-border-top-position: -5px !default;
$textfield-small-height: 23px !default;
$textfield-small-padding: 0 12px 2px 10px !default;
$textfield-small-no-label-padding-top: 3px !default;

$textfield-large-font-family: $font-primary !default;
$textfield-large-height: 50px !default;
$textfield-large-padding: 5px 20px 15px !default;
$textfield-large-label-size: $textfield-label-size !default;
$textfield-large-label-line-height: 1.6 !default;
$textfield-large-text-size: $h2-size !default;
$textfield-large-text-line-height: 28px !default;
$textfield-large-font-weight: 700 !default;
$textfield-autofill-color: #000000 !default;
$textfield-autofill-box-shadow: #ffffff !default;

$textfield-employee-subtle-background-color: rgba($color-employee-1, 0.1) !default;
$textfield-focused-color: $black !default;
$textfield-focused-border-color: $black !default;
$textfield-disabled-border-color: rgba($textfield-border-color, 0.5) !default;
$textfield-error-border-color: $color-error-1 !default;

// Toggle
$toggle-border: 1px solid $grey-d !default;
$toggle-indicator-checked-color: $color-selected-1 !default;
$toggle-indicator-checked-border-color: $toggle-indicator-checked-color !default;
$toggle-indicator-after-background: $grey-d !default;
$toggle-checked-indicator-after-background: $white !default;
$toggle-label-inside-color: $color-inactive-1 !default;
$toggle-checked-label-inside-color: $white !default;
$toggle-disabled-label-color: $color-inactive-1 !default;
$toggle-checked-spinner-inside-color: $white !default;
$toggle-disabled-opacity: 0.5 !default;
$toggle-indicator-disabled-border-color: $grey-d !default;
$toggle-indicator-disabled-after-background: $grey-d !default;
$toggle-indicator-disabled-checked-border-color: $color-selected-1 !default;
$toggle-indicator-disabled-checked-bg-color: $color-selected-1 !default;
$toggle-indicator-disabled-checked-after-background: $white !default;
$toggle-disabled-checked-label-inside-color: $white !default;
$toggle-employee-checked-label-inside-color: $white !default;

$toggle-indicator-checked-color-employee: $color-employee-1 !default;
$toggle-indicator-after-background-employee: $color-employee-1 !default;
$toggle-checked-indicator-after-background-employee: $white !default;
$toggle-spinner-color-employee: $color-employee-1 !default;

// FileField
$filefield-label-visible-height: $textfield-label-visible-height !default;
$filefield-border-top-position: $textfield-border-top-position !default;
$filefield-border: $textfield-border !default;
$filefield-border-color: $textfield-border-color !default;
$filefield-invalid-color: $textfield-error-border-color !default;
$filefield-border-radius: $textfield-border-radius !default;
$filefield-box-shadow: $textfield-box-shadow !default;
$filefield-weight: $textfield-weight !default;
$filefield-padding: 0 15px 9px !default;
$filefield-background: $textfield-background;
$filefield-disabled-background: $textfield-disabled-background !default;
$filefield-label-size: $textfield-label-size !default;
$filefield-label-padding: $textfield-label-padding !default;
$filefield-label-start-position: $textfield-label-start-position !default;
$filefield-label-color: $textfield-label-color !default;
$filefield-floating-label-size: $textfield-floating-label-size !default;
$filefield-label-font-weight: $textfield-label-font-weight !default;
$filefield-floating-label-line-height: 24px !default;
$filefield-label-line-height: 17px !default;
$filefield-floating-label-color: $textfield-floating-label-color !default;
$filefield-dirty-color: $textfield-dirty-text-color !default;
$filefield-no-label-padding-top: 10px !default;
$filefield-input-color: inherit !default;
$filefield-disabled-color: $textfield-disabled-color !default;
$filefield-disabled-border-color: $textfield-disabled-border-color !default;
$filefield-focused-color: $textfield-focused-color !default;
$filefield-focused-border-color: $textfield-focused-border-color !default;
$filefield-input-top: -5px !default;
$filefield-input-bottom: -5px !default;
$filefield-dirty-border: $textfield-dirty-border-color !default;
$filefield-floating-label-top: $textfield-floating-label-border-top-position !default;
$filefield-add-btn-padding: 2px 18px !default;

// Tooltip
$tooltip-color: $white !default;
$tooltip-border-color: $grey-bl !default;

// Tooltip toggle
$tooltip-toggle-border-color: $color-primary-1 !default;
$tooltip-toggle-border-color-hover: $color-primary-1 !default;
$tooltip-toggle-color-hover: $color-primary-1 !default;
$tooltip-toggle-icon-color: $color-primary-1 !default;
$tooltip-toggle-icon-color-hover: $color-primary-2 !default;
$tooltip-toggle-employee-border-color: $color-employee-1 !default;
$tooltip-toggle-employee-border-color-hover: $color-employee-1 !default;
$tooltip-toggle-employee-color-hover: $color-employee-2 !default;
$tooltip-toggle-employee-icon-color: $color-employee-1 !default;
$tooltip-toggle-employee-icon-color-hover: $color-employee-2 !default;

// Video
$video-width: 640px;
$video-background-color: $black !default;
$video-box-shadow: 0 1px 1px rgba($black, 0.1) !default;
$video-icon-background-color: rgba($grey-l, 0.8) !default;
$video-icon-background-color-hover: rgba($color-primary-2, 0.9) !default;
$video-icon-fill: currentcolor !default;
$video-icon-fill-hover: currentcolor !default;
$video-border-radius: 0 !default;
$video-title-background: rgba($color-primary-1, 0.9) !default;
$video-title-color: $white !default;

// Styleguide variables
$sg-body-background: $white !default;
$sg-body-background-grey: $grey-l !default;

// Tag
$tag-size: 100px !default;
$tag-size-small: 60px !default;
$tag-size-medium: 80px !default;
$tag-padding: 10px !default;
$tag-padding-small: 8px !default;
$tag-font-size-size-tiny: 9px !default;
$tag-font-size-size-small: 11px !default;
$tag-font-size-height-small: 14px !default;
$tag-font-size-size-medium: 14px !default;
$tag-font-size-size-large: 16px !default;
$tag-font-size-size-extra-large: 20px !default;
$tag-font-size-height: calc(1em + 2px) !default;
$tag-svg-size: 24px !default;
$tag-svg-size-icon: 40px !default;
$tag-svg-size-icon-small: 32px !default;
$tag-text-weight: 700 !default;
$tag-tiny-font-height: 14px !default;
$tag-tiny-padding-vertical: 1px !default;
$tag-tiny-padding-horizontal: 5px !default;
$tag-tiny-height: $tag-tiny-padding-vertical * 2 + $tag-tiny-font-height !default;
$tag-tiny-border-radius: 3px !default;
$tag-tiny-font-size: 11px !default;
$tag-purpose-service-text-color: $black !default;
$tag-purpose-service-border-color: $grey-l !default;
$tag-default-background-color: $color-primary-1 !default;
$tag-default-text-color: $white !default;
