@import '~@core-front/sass-tokens/index.scss';

@mixin textfield-hide-label() {
  // important is necessary to override all the possible states
  color: transparent !important;
  padding-top: 10px !important;
  animation: none !important;

  &::before {
    opacity: 1 !important;
    top: -10px !important;
    animation: none !important;
  }

  &::after {
    top: 0;
  }
}

.filefield {
  position: relative;
  font-size: 16px;
  width: 100%;
  max-width: 100%;

  &.is-disabled {
    color: $filefield-disabled-color;
  }

  &.is-focused {
    z-index: 2;
  }
}

.filefield__inner {
  position: relative;
  padding: $filefield-label-visible-height 0 0;

  .filefield--no-label-no-break & {
    padding-top: $filefield-no-label-padding-top;

    &::after {
      // important is necessary to override all the possible states
      opacity: 1 !important;
      top: 0;
    }
  }

  .filefield--no-label-sm & {
    @media (min-width: $media-width-sm-min) {
      padding-top: $filefield-no-label-padding-top;

      &::after {
        // important is necessary to override all the possible states
        opacity: 1 !important;
        top: 0;
      }
    }
  }

  .filefield--no-label-md & {
    @media (min-width: $media-width-md-min) {
      padding-top: $filefield-no-label-padding-top;

      &::after {
        // important is necessary to override all the possible states
        opacity: 1 !important;
        top: 0;
      }
    }
  }

  &::after {
    @include transition(
      (
        'animation': short,
        'properties': border-color,
      ),
      (
        'animation': short,
        'properties': opacity,
        'delay': short,
      )
    );

    content: '';
    display: block;
    position: absolute;
    top: $filefield-border-top-position;
    left: 10px;
    right: 10px;
    border-top: $filefield-border;
    opacity: 0;

    .filefield--floating-label & {
      opacity: 1;
    }

    .filefield.is-focused & {
      border-color: $filefield-focused-border-color;
      opacity: 0;
    }

    .filefield.is-dirty & {
      opacity: 0;
    }

    .filefield.is-invalid & {
      border-color: $filefield-invalid-color;
    }

    .filefield.is-disabled & {
      border-color: rgba($filefield-border-color, 0.5);
    }
  }
}

.filefield__input {
  @include transition(
    (
      'animation': short,
      'properties': border-color,
    )
  );

  position: relative;
  border: $filefield-border;
  border-top: 0;
  border-radius: $filefield-border-radius;
  box-shadow: $filefield-box-shadow;
  display: block;
  font-size: 16px;
  font-weight: $filefield-weight;
  margin: 0;
  padding: $filefield-padding;
  width: 100%;
  background: $filefield-background;
  text-align: left;
  color: $filefield-input-color;

  .filefield.is-focused & {
    outline: none;
    border-color: $filefield-focused-border-color;
  }

  .filefield.is-invalid & {
    border-color: $filefield-invalid-color;
  }

  .filefield.is-disabled & {
    background-color: $filefield-disabled-background;
    border-color: $filefield-disabled-border-color;
    color: $filefield-disabled-color;
    outline: none;
  }
}

.filefield__input-field {
  position: absolute;
  top: $filefield-input-top;
  left: 0;
  bottom: $filefield-input-bottom;
  width: 100%;
  opacity: 0;
  cursor: pointer;

  .filefield.is-disabled & {
    cursor: default;
  }
}

.filefield__label {
  @include transition(
    (
      'animation': short,
      'properties': color,
    )
  );

  color: $filefield-label-color;
  font-size: $filefield-label-size;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
  position: absolute;
  display: flex;
  flex-direction: row;
  width: 100%;
  text-align: left;
  padding: $filefield-label-padding;
  transition: color 100ms ease-out;
  font-weight: $filefield-label-font-weight;

  .filefield--floating-label & {
    @include animation(
      (
        'animation': short,
        'name': hide-small-label,
      ),
      (
        'animation': short,
        'name': show-big-label,
        'delay': short,
      )
    );

    padding-top: $filefield-label-start-position;
    font-size: $filefield-floating-label-size;
    color: $filefield-floating-label-color;
    line-height: $filefield-floating-label-line-height;
  }

  .filefield--floating-label.is-focused &,
  .filefield--floating-label.is-dirty & {
    @include animation(
      (
        'animation': short,
        'name': hide-big-label,
      ),
      (
        'animation': short,
        'name': show-small-label,
        'delay': short,
      )
    );

    font-size: $filefield-label-size;
    padding-top: 0;
    line-height: $filefield-label-line-height;

    &::after,
    &::before {
      @include animation(
        (
          'animation': short,
          'name': float-line-with-big-label-to-top,
        ),
        (
          'animation': short,
          'name': float-line-with-small-label-to-top,
          'delay': short,
        )
      );

      top: $filefield-border-top-position;
    }
  }

  .filefield.is-dirty & {
    color: $filefield-dirty-color;

    &::before,
    &::after {
      border-color: $filefield-dirty-border;
    }
  }

  .filefield.is-focused & {
    color: $filefield-focused-color;

    &::before,
    &::after {
      border-color: $filefield-focused-border-color;
    }
  }

  .filefield.is-invalid & {
    &::before,
    &::after {
      border-color: $filefield-invalid-color;
    }
  }

  .filefield.is-disabled & {
    color: $filefield-disabled-color;

    &::before,
    &::after {
      border-color: $filefield-disabled-border-color;
    }
  }

  .filefield--no-label-no-break & {
    @include textfield-hide-label();
  }

  .filefield--no-label-no-break.is-dirty &,
  .filefield--no-label-no-break.is-focused & {
    &::after {
      top: -10px !important;
    }
  }

  .filefield--no-label-sm & {
    @media (min-width: $media-width-sm-min) {
      @include textfield-hide-label();
    }
  }

  .filefield--no-label-sm.is-dirty &,
  .filefield--no-label-sm.is-focused & {
    @media (min-width: $media-width-sm-min) {
      @include textfield-hide-label();

      &::after {
        top: -10px !important;
      }
    }
  }

  .filefield--no-label-md & {
    @media (min-width: $media-width-md-min) {
      @include textfield-hide-label();
    }
  }

  .filefield--no-label-md.is-dirty &,
  .filefield--no-label-md.is-focused & {
    @media (min-width: $media-width-md-min) {
      @include textfield-hide-label();

      &::after {
        top: -10px !important;
      }
    }
  }

  &::before,
  &::after {
    content: '';
    position: relative;
    top: $filefield-border-top-position;
    display: block;
    flex-grow: 0;
    height: 6px;
    border: $filefield-border;
    border-bottom: 0;
    transition: border-color 100ms ease-out;
  }

  &::after {
    right: 0;
    position: absolute;
    width: 10px;
    flex-grow: 1;
    border-radius: 0 3px 0 0;
    border-left: 0;

    .filefield.is-dirty &,
    .filefield.is-focused & {
      left: 0;
      position: relative;
      margin-left: 10px;
    }

    .filefield--no-label-no-break.is-dirty & {
      position: absolute;
      max-width: 10px;
      left: auto;
      right: 0;
      top: 0 !important;
      bottom: auto;
    }

    .filefield--no-label-md.is-dirty & {
      @media (min-width: $media-width-md-min) {
        position: absolute;
        max-width: 10px;
        left: auto;
        right: 0;
        top: 0 !important;
        bottom: auto;
      }
    }

    .filefield--no-label-sm.is-dirty & {
      @media (min-width: $media-width-sm-min) {
        position: absolute;
        max-width: 10px;
        left: auto;
        right: 0;
        top: 0 !important;
        bottom: auto;
      }
    }
  }

  &::before {
    left: 0;
    right: 0;
    top: -10px;
    padding-right: 9px;
    margin-right: 10px;
    border-radius: 3px 0 0;
    border-right: 0;
    flex: 0 1 auto;

    .filefield--floating-label & {
      @include animation(
        (
          'animation': short,
          'name': float-line-with-small-label-to-bottom,
        ),
        (
          'animation': short,
          'name': float-line-with-big-label-to-bottom,
          'delay': short,
        )
      );

      top: $filefield-floating-label-top;
    }
  }
}

.filefield__input-content {
  position: relative;
  display: flex;
  margin: -4px -12px -4px -4px;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.filefield__files {
  pointer-events: none;
}

.filefield__file {
  margin-right: 10px;
  pointer-events: auto;
}

.filefield__add-btn {
  display: block;
  margin: 3px;
  padding: $filefield-add-btn-padding;

  .filefield.is-disabled & {
    cursor: default;
    pointer-events: none;
  }
}

@keyframes hide-big-label {
  0% {
    padding-top: $filefield-label-start-position;
    font-size: $filefield-floating-label-size;
  }

  100% {
    padding-top: $filefield-border-top-position;
    color: transparent;
    font-size: $filefield-floating-label-size;
  }
}

@keyframes show-small-label {
  0% {
    padding-top: $filefield-border-top-position;
    color: transparent;
    font-size: $filefield-label-size;
  }

  100% {
    padding-top: 0;
    font-size: $filefield-label-size;
  }
}

@keyframes show-big-label {
  0% {
    padding-top: $filefield-border-top-position;
    color: transparent;
    font-size: $filefield-floating-label-size;
  }

  100% {
    padding-top: $filefield-label-start-position;
    font-size: $filefield-floating-label-size;
  }
}

@keyframes hide-small-label {
  0% {
    padding-top: 0;
    font-size: $filefield-label-size;
  }

  100% {
    padding-top: $filefield-border-top-position;
    color: transparent;
    font-size: $filefield-label-size;
  }
}

@keyframes float-line-with-big-label-to-top {
  0% {
    top: -10px;
  }

  100% {
    top: 0;
  }
}

@keyframes float-line-with-small-label-to-top {
  0% {
    top: 0;
  }

  100% {
    top: $filefield-border-top-position;
  }
}

@keyframes float-line-with-big-label-to-bottom {
  0% {
    top: 0;
  }

  100% {
    top: $filefield-floating-label-top;
  }
}

@keyframes float-line-with-small-label-to-bottom {
  0% {
    top: $filefield-border-top-position;
  }

  100% {
    top: 0;
  }
}
