@import '~@telia-front/sass-tokens/index.scss';

.logo {
  display: block;
}

.logo--small {
  width: 4.75rem;

  [data-brand='diil'] & {
    width: 3.75rem;
  }

  [data-brand='super'] & {
    width: 2.5rem;
  }

  [data-brand='simpel'] & {
    width: 5rem;
  }

  [data-brand='greenit'] & {
    width: 6.375rem;
  }

  &.digital-hub-logo {
    width: 5.875rem;
  }
}

.logo__img {
  display: block;
  width: 100%;
  height: auto;
}

.logo__path {
  .logo--color-neutral & {
    fill: var(--color-neutral-graphic);
  }

  [data-brand='telia'][data-theme='dark'] &,
  [data-brand='telia'] [data-theme='dark'] &,
  [data-brand='greenit'][data-theme='dark'] &,
  [data-brand='greenit'] [data-theme='dark'] &,
  [data-theme='dark'] .digital-hub-logo &,
  .digital-hub-logo[data-theme='dark'] & {
    fill: #ffffff;
  }
}

// Diil logo only, needs an "inverted" text color.
.logo__path-foreground {
  .logo--color-neutral & {
    fill: var(--color-neutral-bg-level-1);
  }
}
