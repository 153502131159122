@import '~@core-front/sass-tokens/index.scss';

.clear-btn {
  @include transition(
    (
      'animation': short,
      'properties': color,
    )
  );

  background: transparent;
  border: 0;
  margin: 0;
  font-size: 16px;
  line-height: 1.375;
  padding: 0;
  color: $clear-button-color;
  height: 24px;
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;

  html[data-whatintent='mouse'] &:hover {
    color: $clear-button-hover;
  }
}

.clear-btn__icon {
  @include reset-icon();
}
