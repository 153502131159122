@import '~@core-front/sass-tokens/index.scss';

.signature__canvas {
  position: relative;
  display: flex;
  height: 230px;
  margin-top: 10px;
  background: $signature-background;
  border: 1px solid $signature-border-color;
  border-radius: 3px;
  overflow: auto;

  .signature--light & {
    background: $signature-light-background;
  }

  .signature--employee & {
    border-color: $signature-employee-border;
  }

  .signature--disabled & {
    opacity: $signature-disabled-opacity;
    border-color: $signature-disabled-border-color;
    background: $signature-background-disabled;
  }

  .signature--invalid & {
    border-color: $signature-invalid-border;
  }

  .signature:not(.signature--disabled) &:focus {
    border-color: $signature-focus-border;
  }

  .signature--employee:not(.signature--disabled) &:focus {
    border-color: $signature-employee-focus-border;
  }

  .signature__canvas-inner {
    position: absolute;
    width: calc(100% - 2px);
    height: calc(100% - 2px);
  }
}

.signature__label-wrapper {
  font-size: 16px;
}

.signature__label {
  .signature--disabled & {
    color: $signature-disabled-label-color;
  }
}

.signature__form-helper {
  margin-top: 5px;
}
