@import '~@core-front/sass-tokens/index.scss';

$tabs-nav-link-icon-size: 32px;
$tabs-nav-link-icon-top-size: 40px;

.tabs {
  color: $tabs-text-color;
}

.tabs__content {
  border-top: 2px solid $tabs-border-color;
  position: relative;
  margin-top: -2px;

  .tabs--border-none & {
    border: none;
  }
}

.tabs__item {
  display: none;
  padding: 24px 0;

  @media (min-width: $media-width-md-min) {
    padding: 32px 0;
  }

  &.is-current {
    display: block;
  }

  &.fade-enter {
    display: block;
    opacity: 0;
  }

  &.fade-exit {
    display: block;
    opacity: 1;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;
    transition: opacity 150ms cubic-bezier(0.64, 0, 0.78, 0); // easeInQuint
  }

  &.fade-enter-active {
    opacity: 0;
    transition: opacity 150ms cubic-bezier(0.22, 1, 0.36, 1); // easeOutQuint
  }

  &.fade-enter-done {
    opacity: 1;
    transition: opacity 150ms cubic-bezier(0.22, 1, 0.36, 1); // easeOutQuint
  }

  &.fade-exit-active,
  &.fade-exit-done {
    opacity: 0;
  }

  &.fade-exit-done {
    display: none;
  }
}

.tabs__nav {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0;
  margin-left: -12px;
  margin-right: -12px;

  @media (min-width: $media-width-sm-min) {
    margin-left: -15px;
    margin-right: -15px;
  }
}

.tabs__nav-scroll {
  width: 100%;
  z-index: 1;
}

.tabs__nav-scroll-inner {
  // Needed to keep the animated underline in the correct position when scrollbar is visible.
  position: relative;

  .tabs--center > .tabs__nav & {
    display: flex;
  }
}

.tabs__nav-inner {
  display: flex;
  justify-content: flex-start;
  flex-wrap: nowrap;
  margin-bottom: 0;
  margin-right: 0;
  padding: 0;

  .tabs--center > .tabs__nav & {
    margin-left: auto;
    margin-right: auto;
  }
}

.tabs__nav-dropdown-button {
  margin-top: -8px;
  margin-bottom: 8px;
  margin-left: 5px;

  .tabs__nav--icons-top & {
    margin-top: 45px;
  }
}

.tabs__nav-item {
  display: inline-flex;
  text-align: center;
  flex-shrink: 0;

  .tabs__nav--icons-top & {
    align-items: flex-end;
  }
}

.tabs__nav-item--only-child {
  max-width: 100%;
}

.tabs__nav-link {
  display: flex;
  align-items: center;
  position: relative;
  font-weight: $tabs-nav-link-font-weight;
  color: $tabs-text-color;
  z-index: 2;
  outline-offset: -3px;
  padding-bottom: 16px;
  padding-left: 12px;
  padding-right: 12px;
  gap: 4px;
  transition: color 0.15s ease;

  @media (min-width: $media-width-sm-min) {
    gap: 8px;
    padding-left: 16px;
    padding-right: 16px;
  }

  html[data-whatintent='mouse'] &:hover {
    color: $tabs-hover-text-color;
  }

  .tabs__nav-item.is-current & {
    color: $tabs-selected-text-color;
  }

  .tabs__nav-item--employee & {
    color: $tabs-employee-text-color;

    html[data-whatintent='mouse'] &:hover {
      color: $tabs-employee-hover-text-color;
    }
  }

  .tabs__nav-item--employee.is-current & {
    color: $tabs-employee-selected-text-color;
  }

  .tabs__nav-item--only-child & {
    max-width: 100%;
  }

  .tabs__nav--icons-top & {
    flex-direction: column;
  }

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 12px;
    right: 12px;

    @media (min-width: $media-width-sm-min) {
      left: 15px;
      right: 15px;
    }

    .tabs__nav-item.is-current & {
      height: 2px;
      background: $tabs-selected-border-color;
    }

    .tabs__nav-item--employee.is-current & {
      background: $tabs-employee-border-color;
    }

    .tabs__nav-underline.is-animating ~ .tabs__nav-inner > .tabs__nav-item > & {
      opacity: 0;
    }
  }
}

.tabs__nav-link-inner {
  .tabs__nav-item--only-child & {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 100%;
  }
}

.tabs__nav-item-icon {
  @include reset-icon();

  position: relative;
  font-size: $tabs-nav-link-icon-size;
  flex-shrink: 0;

  &.tabs__nav-item-icon-image {
    width: $tabs-nav-link-icon-size;

    .tabs__nav--icons-top & {
      width: $tabs-nav-link-icon-top-size;
    }
  }

  .tabs__nav--icons-top & {
    font-size: $tabs-nav-link-icon-top-size;
    margin: 0 auto;
  }

  .tabs__nav-link & {
    .tabs__nav-item:not(.tabs__nav-item--employee) & {
      color: $tabs-nav-link-icon-color;
    }

    .tabs__nav-item:not(.tabs__nav-item--employee).is-current & {
      color: inherit;
    }
  }

  .tabs__nav-link:hover & {
    html[data-whatintent='mouse'] .tabs__nav-item:not(.tabs__nav-item--employee) &,
    html[data-whatintent='mouse'] .tabs__nav-item:not(.tabs__nav-item--employee).is-current & {
      color: inherit;
    }
  }
}

.tabs__nav-underline {
  opacity: 0;
  position: absolute;
  z-index: 1;
  bottom: 0;
  width: 100%;
  height: 2px;
  max-width: 0;
  margin: 0 10px;
  background-color: $tabs-selected-border-color;
  transition:
    max-width 300ms cubic-bezier(0.2, 0, 0.31, 1),
    left 300ms cubic-bezier(0.2, 0, 0.31, 1),
    background-color 300ms cubic-bezier(0.2, 0, 0.31, 1);

  @media (min-width: $media-width-sm-min) {
    margin: 0 15px;
  }

  &.is-animating {
    opacity: 1;
  }
}

.tabs__nav-underline--type-employee {
  background-color: $tabs-employee-border-color;
}
