@use 'sass:map';

@import '~@core-front/sass-tokens/index.scss';

.breadcrumbs {
  position: relative;
  z-index: map.get($zindex, breadcrumbs);
  display: none;

  @media (min-width: $media-width-sm-min) {
    display: flex;
  }
}

.breadcrumbs__list {
  display: flex;
  font-size: 12px;
  line-height: 1;
  margin: 0;
  padding: 13px 0;
  list-style: none;
}

.breadcrumbs__item {
  display: inline-flex;
  align-items: center;
  vertical-align: top;
}

.breadcrumbs__link {
  color: $breadcrumbs-link-color;
  text-decoration: none;
  font-weight: 400;

  .breadcrumbs__item.is-current & {
    font-weight: 700;
  }

  html[data-whatintent='mouse'] &:hover {
    color: $breadcrumbs-link-hover-color;
  }
}

.breadcrumbs__icon {
  font-size: 16px;
  margin-left: 6px;
  margin-right: 6px;
  color: $breadcrumbs-icon-color;

  .breadcrumbs__item:last-child & {
    display: none;
  }
}
