@import '~@telia-front/sass-tokens/index.scss';

.sort-bar {
  display: flex;
  flex-direction: column-reverse;

  @media (min-width: $media-width-md-min) {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background: $grey-l;
    border-radius: 30px;
    box-shadow: 0 1px 1px rgba($black, 0.1);
    height: 42px;
  }
}

.sort-bar--dark {
  @media (min-width: $media-width-md-min) {
    background-color: $white;
  }
}

.sort-bar__switch {
  flex-shrink: 0;
}

.sort-bar__filter {
  display: flex;
  align-items: center;
  background: $grey-l;
  border-radius: 30px;
  box-shadow: 0 1px 1px rgba($black, 0.1);
  height: 42px;
  padding: 0 20px;
  flex: 1 1 auto;

  @media (min-width: $media-width-md-min) {
    background-color: transparent;
    box-shadow: none;
  }

  .sort-bar--dark & {
    background-color: $white;

    @media (min-width: $media-width-md-min) {
      background-color: transparent;
    }
  }

  &.skeleton__item {
    @media (min-width: $media-width-md-min) {
      border-radius: 30px 0 0 30px;
    }
  }
}

.sort-bar__compare {
  margin-bottom: 10px;

  @media (min-width: $media-width-md-min) {
    background-color: transparent;
    box-shadow: none;
    margin-bottom: 0;
  }

  &.skeleton__item {
    @media (min-width: $media-width-md-min) {
      border-radius: 0 30px 30px 0;
    }
  }
}

.sort-bar__content {
  flex-basis: 100%;

  .sort-bar__switch + & {
    position: relative;
    padding-left: 30px;

    @media (min-width: $media-width-md-min) {
      &::before {
        content: '';
        display: block;
        position: absolute;
        left: 15px;
        top: 0;
        bottom: 0;
        width: 1px;
        background-color: $white;
      }
    }
  }
}
