.equal-height {
  display: flex;
  flex-direction: column;
  min-height: 1px;
}

.equal-height--middle {
  justify-content: center;
}

.equal-height--bottom {
  justify-content: flex-end;
}

.equal-height__inner {
  width: 100%;
}
