@use 'sass:map';
@use 'sass:math';

@import '~@telia-front/sass-tokens/index.scss';

.carousel-hero__container {
  position: relative;
  margin-top: -30px;
  overflow: hidden;
}

.carousel-hero {
  position: relative;
  overflow: hidden;
  z-index: map.get($zindex, carousel-hero);
  margin-left: auto;
  margin-right: auto;
  touch-action: pan-y;
  max-width: 2560px;

  .slick-track {
    display: flex;
    height: 100%;
  }

  .slick-slide {
    display: flex;
    position: relative;
    float: left;
    height: auto;
    opacity: 1 !important;

    &:not(.slick-current) {
      pointer-events: none;

      .carousel-hero__inner {
        display: none;
      }
    }

    > div {
      width: 100%;
    }
  }

  &::before {
    @media (min-width: $media-width-lg-min) {
      content: '';
      display: block;
      width: 100%;
      padding-top: (math.div(853, 2560) * 100%);

      @media (min-aspect-ratio: 2 / 1) {
        padding-top: (math.div(853, 2560) * 80%);
      }
    }
  }

  .slick-list {
    @media (min-width: $media-width-lg-min) {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }
}

.carousel-hero__item {
  position: relative;
  overflow: hidden;
  width: 100%;
  min-height: 1px;
  display: none;
  color: $text-color-base;
  transition: background-color 300ms cubic-bezier(0.25, 0.1, 0.25, 1);

  @media (min-width: $media-width-lg-min) {
    position: absolute;
    top: 0;
    left: 0;
  }

  .carousel-hero & {
    height: 100%;
  }

  &:first-child {
    display: block;
  }

  &.is-current {
    z-index: 1;
  }

  &.is-prev-current {
    z-index: 2;
  }

  &:not(.is-current) {
    // important to override inline style
    background-color: transparent !important;
  }

  &::before {
    @media (min-width: $media-width-lg-min) {
      content: '';
      display: block;
      width: 100%;
      padding-top: (math.div(853, 2560) * 100%);

      .carousel-hero:not(.carousel-hero--min-height) & {
        @media (min-aspect-ratio: 2 / 1) {
          padding-top: (math.div(853, 2560) * 80%);
        }
      }
    }
  }
}

.carousel-hero__background {
  position: relative;
  display: block;
  width: 100%;
  height: auto;
  opacity: 1;
  transition: 300ms cubic-bezier(0.25, 0.1, 0.25, 1);
  transition-property: opacity, transform;

  @media (min-width: $media-width-lg-min) {
    position: absolute;
    top: 0;
    left: 0;
  }

  .carousel-hero__item.is-current & {
    transform: translateX(0);
  }

  .carousel-hero__item.is-prev & {
    transform: translateX(-20px);
  }

  .carousel-hero__item.is-next & {
    transform: translateX(20px);
  }

  .carousel-hero__item.is-prev-current & {
    opacity: 0;
  }

  &::before {
    content: '';
    display: block;
    width: 100%;
    padding-top: 50%;

    @media (min-width: $media-width-lg-min) {
      padding-top: (math.div(853, 2560) * 100%);

      .carousel-hero:not(.carousel-hero--min-height) & {
        @media (min-aspect-ratio: 2 / 1) {
          padding-top: (math.div(853, 2560) * 80%);
        }
      }
    }
  }
}

.carousel-hero__background-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;

  .carousel-hero:not(.carousel-hero--min-height) & {
    @media (min-width: $media-width-lg-min) {
      @media (min-aspect-ratio: 2 / 1) {
        top: -10%;
      }
    }
  }
}

.carousel-hero__video-wrapper {
  display: none;

  @media (min-width: $media-width-lg-min) {
    display: block;
  }
}

.carousel-hero__background-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  background-color: $body-bg;
  border-radius: 0;

  & .video__iframe {
    border-radius: 0;
  }
}

.video__image {
  .carousel-hero__background-video & {
    height: 100%;
    border-radius: 0;
  }
}

.carousel-hero__content {
  padding-top: 30px;
  padding-bottom: 70px;
  opacity: 0;
  transition: 300ms cubic-bezier(0.25, 0.1, 0.25, 1) 100ms;
  transition-property: opacity, transform;

  @media (max-width: $media-width-md-max) {
    padding-top: 64px;
    text-align: center;
  }

  @media (min-width: $media-width-lg-min) {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    bottom: calc((100vw - 180px) / 4 * 0.142);
    padding-bottom: 30px;
  }

  @media (min-width: 2560px) {
    bottom: calc((2560px - 180px) / 4 * 0.142);
  }

  .carousel-hero__item.is-current & {
    transform: translateX(0);
    opacity: 1;
  }

  .carousel-hero__item.is-prev & {
    transform: translateX(-20px);
  }

  .carousel-hero__item.is-next & {
    transform: translateX(20px);
  }

  // special fluid h1 style inside carousel-hero

  h1,
  .h1 {
    font-size: $h2-size;

    @media (min-width: $media-width-sm-min) {
      font-size: $h1-size-sm;
    }

    @media (min-width: $media-width-lg-min) {
      @include fluid-type($media-width-lg-min, $media-width-xl-min, 2.75rem /* $h1-size-sm */, 3.5rem /* $h1-size-xl */);
    }

    @media (min-width: $media-width-xl-min) {
      // TODO: handle other brand font-sizes dynamically
      @include fluid-type($media-width-xl-min, 160rem, 3.5rem /* $h1-size-xl */, 4.625rem);
    }

    @media (min-width: 2560px) {
      font-size: 4.625rem;
    }
  }
}

.carousel-hero__dots {
  position: absolute;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 2;
  pointer-events: none;

  @media (min-width: $media-width-lg-min) {
    top: auto;
    bottom: calc((100vw - 180px) / 4 * 0.0866);
  }

  @media (min-width: $media-width-xl-min) {
    bottom: calc((#{$page-max-width} - 180px) / 4 * 0.0866);
  }

  &::before {
    @media (max-width: $media-width-md-max) {
      content: '';
      display: block;
      width: 100%;
      padding-top: calc(50% + 20px);
    }
  }

  // these elements don't have a specific class and can't be assigned one :(

  li {
    position: relative;
    display: inline-block;
    margin: 0;
    padding: 0;
    cursor: pointer;
    vertical-align: top;
  }
}

.carousel-hero__indicator {
  display: block;
  position: relative;
  background: transparent;
  border-radius: 50%;
  border: none;
  z-index: 1;
  pointer-events: auto;
  height: 24px;
  width: 24px;
  outline-offset: -2px;

  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 12px;
    height: 12px;
    border: 1px solid var(--color-brand-primary-border);
    border-radius: 50%;

    .slick-active & {
      background: var(--color-brand-primary-border);
    }
  }
}

.carousel-hero__pause-button-container {
  position: absolute;
  width: 100%;
  height: 100%;

  @media (max-width: $media-width-md-max) {
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
  }

  .carousel-hero__pause-button-inner {
    position: relative;
    height: 100%;
    width: 100%;
    max-width: 2560px;
    margin: 0 auto;

    &::before {
      @media (max-width: $media-width-md-max) {
        content: '';
        display: block;
        width: 100%;
        margin-top: calc(50% + 16px);
      }
    }

    .btn.carousel-hero__pause-button {
      position: absolute;
      right: 24px;
      bottom: 24px;
      z-index: 1;

      @media (max-width: $media-width-md-max) {
        right: 16px;
        bottom: unset;
      }
    }
  }
}
