@import '~@telia-front/sass-tokens/index.scss';

@import '~@core-front/main/styles.scss';

.main__page--with-background {
  position: relative;
}

.main__page--background {
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
}

.main__page--background-image {
  display: block;
  width: 100%;
  height: 100%;
}

.main__page--background-picture {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  height: 100%;
}
