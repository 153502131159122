@import '~@core-front/sass-tokens/index.scss';

.textarea__input {
  display: block;
  resize: none;
  height: 120px;
  font-weight: 400;
  overflow: hidden;

  .textarea--max-lines & {
    overflow: auto;
  }
}

.textarea__label--hidden,
.textarea__input--hidden {
  position: absolute;
  visibility: hidden;
  height: 1px;
  width: 100%;
}
