.header__actions {
  .header__top & {
    max-width: 100%;

    @media (min-width: $media-width-sm-min) {
      max-width: none;
    }
  }
}

.header__actions-list {
  @include list-unstyled();

  display: flex;
  align-items: center;
  gap: 24px;

  .header__bottom & {
    align-items: stretch;
  }
}

.header__actions-item {
  position: relative;

  .header__bottom & {
    display: flex;
  }
}

.header__actions-item--search {
  @media (min-width: $media-width-lg-min) {
    margin-right: 5px;
  }
}

.header__actions-item--nav {
  .header__bottom & {
    margin-left: -8px;
  }
}

.header__actions-item--sso {
  overflow: hidden;

  @media (min-width: $media-width-sm-min) {
    overflow: visible;
  }

  @media (max-width: $media-width-xs-max) {
    padding: 8px;
    margin-right: -8px;
  }

  & .header__actions-label {
    font-weight: 700;
    margin-left: 4px;

    @media (min-width: $media-width-sm-min) {
      font-size: var(--type-small-font-size);
      line-height: var(--type-small-line-height);
    }
  }
}

.header__actions-button,
.header__actions-button-inner {
  display: flex;
  align-items: center;
  max-width: 100%;
}

.header__actions-button {
  font-size: 2rem;
  color: var(--color-neutral-graphic);
  line-height: 1;
  justify-content: center;

  .header__top & {
    outline-offset: 5px;
  }
}

.header__actions-button-inner {
  flex-direction: column;

  .header__actions-button--sso:not(.is-text-color) &,
  .header__actions-button.is-current & {
    color: var(--color-brand-primary-text);
  }

  html[data-whatintent='mouse'] &:hover {
    color: var(--color-brand-primary-text-hover);
  }

  .header__top &,
  .header__bottom .header__actions-item--bottom & {
    flex-direction: row;
  }

  .header__top & {
    font-size: 1.5rem;

    @media (min-width: $media-width-lg-min) {
      font-weight: 400;
    }
  }

  .header__bottom .header__actions-item--search & {
    @media (min-width: $media-width-lg-min) {
      font-size: 2rem;
    }
  }

  .header__actions-button.is-current & {
    position: relative;
    z-index: 4;
  }
}

.header__actions-item--placeholder {
  opacity: 0;
  pointer-events: none;
}

.header__actions-label {
  font-size: var(--type-tiny-font-size);
  line-height: var(--type-tiny-line-height);
  font-weight: 400;

  @media (min-width: $media-width-sm-min) {
    font-size: var(--type-small-font-size);
    line-height: var(--type-small-line-height);
  }

  .header__bottom .header__actions-item--search & {
    @media (min-width: $media-width-lg-min) {
      @include visually-hidden();
    }
  }

  .header__actions.is-logged-in .header__actions-item--sso & {
    max-width: 140px;
    padding: 0;
    font-weight: 700;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    /* For ÜÕÖÄ dots to be visible */
    line-height: 2;
  }

  .header__actions-item--cart &,
  .header__actions-item--notifications &,
  .header__actions-item--search &,
  .header__bottom .header__actions-item--search .header__actions-button.is-current & {
    @include visually-hidden();
  }
}

.header__actions-icon-wrapper {
  display: flex;
  align-items: center;
}

.header__actions-icon {
  @include reset-icon();

  display: block;

  .header__actions-button.is-current & {
    display: none;

    @media (min-width: $media-width-lg-min) {
      display: block;
    }
  }

  .header__actions-item--search .header__actions-button.is-current & {
    display: none;
  }

  .header__actions-item--sso .header__actions-button.is-current & {
    display: block;
  }
}

.header__actions-icon--close {
  display: none;

  .header__actions-item--sso .header__actions-button.is-current & {
    display: none;
  }

  .header__actions-button.is-current & {
    display: block;

    @media (min-width: $media-width-lg-min) {
      display: none;
    }
  }

  .header__actions-item--search .header__actions-button.is-current & {
    display: block;
  }
}

.header__actions-arrow {
  @include reset-icon();

  display: block;
  font-size: 1rem;
  margin-left: 2px;
  flex-shrink: 0;
}

.header__actions-badge {
  vertical-align: bottom;
  position: relative;
  top: -10px;
  margin-left: -10px;

  @media (min-width: $media-width-lg-min) {
    top: -8px;
    margin-left: -12px;
  }
}

.header__actions-item--notifications,
.header__actions-item--cart,
.header__actions-item--search {
  .header__actions-button {
    font-size: 1.5rem;

    @media (min-width: $media-width-lg-min) {
      font-size: 2rem;
    }
  }
}

.header__actions-button.header__actions-button--search.is-current {
  font-size: 2rem;
}

.header {
  &:not(.header--open-search) .header__actions-item--search {
    @media (min-width: $media-width-xl-min) {
      display: none;
    }
  }
}

.header--minimized-search .header__actions-item--search {
  display: flex;
}

.header--open-search {
  .header__bottom {
    .header__actions-item--notifications,
    .header__actions-item--cart,
    .header__actions-item--nav {
      display: none;
    }

    .header__actions-item--search {
      @include animation(
        (
          'animation': short,
          'name': fade-in,
        )
      );

      margin-right: 0;
      margin-left: $header-search-close-button-margin-left;

      @keyframes fade-in {
        0% {
          opacity: 0;
        }

        100% {
          opacity: 1;
        }
      }
    }
  }
}

.header__bottom .header__actions-item--nav .header__actions-label {
  @include visually-hidden();
}
