@import '~@telia-front/sass-tokens/index.scss';

.carousel-preview {
  position: relative;
  margin: -$base-horizontal-spacing-xs;

  @media (min-width: $media-width-sm-min) {
    margin: -40px;
  }

  .slick-slide {
    display: none;
    float: left;
    min-height: 1px;
    overflow: hidden;
    height: 100%;
    padding: 0 60px;

    @media (min-width: $media-width-sm-min) {
      height: 600px;
      padding: 0;
    }

    & > div {
      height: 100%;
      display: flex;
      align-items: center;
    }

    html[data-whatintent='touch'] & {
      overflow: auto;
    }
  }

  &.is-panning:fullscreen {
    width: 100%;
    margin: 0;
    background: $white;

    .slick-slide {
      height: 100vh;
    }
  }

  .slick-initialized .slick-slide {
    display: block;
  }

  .slick-active {
    z-index: 1;
  }

  .slick-track {
    position: relative;
    top: 0;
    left: 0;
    display: block;
    margin-left: auto;
    margin-right: auto;
    min-width: 100%;
    transform: translateZ(0);
    height: 100%;

    &::after,
    &::before {
      display: table;
      content: '';
    }
  }

  .slick-list {
    position: relative;
    display: block;
    overflow: hidden;
    margin: 0;
    padding: 0;
    width: 100%;
    transform: translateZ(0);
    height: 100%;
  }
}

.carousel-preview__wrapper {
  position: relative;
  min-width: 0;
  align-self: center;
  flex: 1 0 0%;

  @media (min-width: $media-width-sm-min) {
    height: 600px;
  }

  &::before {
    content: '';
    display: block;
    width: 100%;
    padding-top: 100%;
  }

  .carousel-preview.is-panning:fullscreen & {
    height: 100vh;
  }
}

.carousel-preview__slider {
  display: block;
  box-sizing: border-box;
  user-select: none;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  &:not(.slick-slider) {
    height: 100%;
    display: flex;
    align-items: center;
  }
}

.carousel-preview__item {
  position: relative;
  cursor: pointer;
  margin: auto;

  .carousel-preview__slider > & {
    padding: 0 60px;

    @media (min-width: $media-width-sm-min) {
      padding: 0;
    }
  }
}

.carousel-preview__thumbnail-wrapper {
  @include list-unstyled();

  display: none !important;
  align-items: center;
  position: absolute;
  width: 100px;
  height: 100%;
  background: $white;
  top: 0;
  right: 30px;
  font-size: 0;
  overflow: hidden;

  @media (min-width: $media-width-sm-min) {
    display: flex !important;
    flex-direction: column;
    justify-content: center;
  }
}

.carousel-preview__thumbnail {
  width: 60px;
  height: 60px;
  padding: 10px;
  border-radius: 3px;
  cursor: pointer;

  .carousel-preview__thumbnail-wrapper li.slick-active & {
    position: relative;

    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      height: 60px;
      width: 60px;
      background-color: rgba($grey-d, 0.15);
      z-index: 1;
    }
  }
}

.carousel-preview__button {
  color: $black;
  position: absolute;
  bottom: 7px;
  left: 7px;

  @media (min-width: $media-width-sm-min) {
    bottom: 27px;
    left: 27px;
  }

  .btn__icon {
    font-size: 16px;
  }

  html[data-whatintent='mouse'] &:hover {
    border-color: $white;
    color: $black;
  }
}

.carousel-preview__image {
  padding: 40px 0;

  @media (min-width: $media-width-sm-min) {
    max-height: 600px;
    padding: 70px 0;
  }

  .carousel-preview.is-panning & {
    max-height: none;
    width: auto;
    padding: 0;
  }

  .carousel-preview__thumbnail-wrapper & {
    width: 100%;
    opacity: $slick-opacity-default;
    padding: 0;
  }
}

.carousel-preview__nav {
  // important to override a very specific selector in button styles
  // the default there is relative so forcing absolute here does not cause issues
  position: absolute !important;
  top: 50%;
  transform: translateY(-50%);
  z-index: 100;
  display: block;

  @media (min-width: $media-width-sm-min) {
    display: none;
  }

  .carousel-preview.is-panning & {
    display: none;
  }
}

.carousel-preview__nav--next {
  right: 20px;
}

.carousel-preview__nav--prev {
  left: 20px;
}
