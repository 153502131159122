.chart-legend {
  flex: 0 0 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0;
  padding: 0;
  list-style: none;
}

.chart-legend--stacked {
  justify-content: flex-start;
  flex-direction: column;
  align-self: center;
}

.chart-legend--small {
  font-size: 12px;
}

.chart-legend__item {
  display: flex;
  align-items: baseline;
  margin: 0 16px;

  .chart-legend--stacked & {
    margin: 0;
  }
}

.chart-legend__color {
  flex: 0 0 10px;
  width: 10px;
  height: 10px;
  margin-right: 8px;
  border-radius: 50%;
}
