@import '~@core-front/sass-tokens/index.scss';

.passfield {
  position: relative;
}

.passfield__button {
  position: absolute;
  top: $passfield-button-top;
  right: $passfield-button-right;
  padding: 10px;
  background: transparent;
  border: 0;
  border-radius: 0;
  appearance: none;
  font-size: 18px;

  html[data-whatintent='mouse'] &:hover {
    color: $passfield-button-hover-color;
  }
}

.passfield__input {
  .passfield & {
    padding-right: $passfield-input-padding-right;
  }
}

.textfield__label {
  .passfield & {
    padding-right: $passfield-input-padding-right;
  }

  .passfield.is-dirty &,
  .passfield.is-focused & {
    padding-right: 0;
  }
}

.passfield__button-icon {
  @include reset-icon();

  display: block;
}
