.header__search {
  position: relative;

  .textfield__input.search__input {
    padding-right: 48px;
    position: absolute;
    right: 0;
    font-size: 1rem;
  }

  .textfield--with-icon {
    .textfield__input.search__input {
      padding-right: 80px;
    }
  }
}

.header__search-suggestion-button {
  display: block;
  margin: 0 auto;
}

.textfield__inner {
  .header__search & {
    padding-top: 0;

    &::after {
      content: none;
    }
  }
}

.textfield__label {
  .header__search & {
    &::before,
    &::after {
      content: none;
    }
  }
}

.textfield__input {
  .header__search & {
    border: 1px solid var(--color-neutral-border);
    border-radius: 23px;
    background-color: var(--color-neutral-bg-level-3);
    height: 48px;
    padding: 0 50px 0 16px;
    outline: none;

    &:focus-visible {
      @include keyboard-focus();
    }
  }

  html[data-whatintent='mouse'] .header__search &:hover {
    border: 1px solid var(--color-brand-primary-border);
  }

  .search__textfield.is-focused & {
    .header__search & {
      border: 1px solid var(--color-neutral-border);
    }
  }
}

.search__action {
  .search--no-label-no-break.header__search & {
    top: 12px;
    right: 16px;
  }
}

.textfield__clear-btn {
  .header__search .search__textfield & {
    top: 12px;
    right: 48px;
  }
}

.header__search-suggestion.suggestion {
  border: none;
  border-radius: 8px;
  box-shadow: $elevation-5;
  width: calc(100vw - 30px);
  padding-bottom: 27px;
  max-height: none;
}
