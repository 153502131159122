@import '~@telia-front/sass-tokens/index.scss';

$end-width: 105px;

@mixin table-edge() {
  content: '';
  display: none;
  width: $end-width;
  position: absolute;
  bottom: 0;
  height: inherit;

  @media (min-width: $media-width-lg-min) {
    display: block;
  }
}

.offer-counter {
  position: relative;
  z-index: 0;
}

.offer-counter__figure {
  position: relative;
  padding-bottom: 70px;
  padding-right: 0;
  height: 350px;
  display: flex;
  align-items: flex-end;
}

.offer-counter__figure-inner {
  position: relative;
  z-index: 1;
  margin: 0 auto;
  max-width: 100%;

  &::after {
    content: '';
    height: 3px;
    left: -4px;
    right: -4px;
    position: absolute;
    bottom: 12px;
    box-shadow: 0 14px 4px rgba($black, 0.8);
    border-radius: 50%;
    z-index: -1;
  }
}

.offer-counter__table {
  position: absolute;
  height: 43px;
  background: linear-gradient($grey-l 17px, $grey 0);
  bottom: 35px;
  left: -20px;
  right: -20px;

  &::after {
    content: '';
    width: calc(100% - 40px);
    z-index: -1;
    box-shadow: 0 10px 110px rgba(0, 0, 0, 0.7);
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    height: 15px;
  }

  @media (min-width: $media-width-lg-min) {
    left: -11px;
    right: -11px;
  }

  .offer-counter__item--edge-start & {
    &::before {
      @include table-edge();

      left: -$end-width + 1px;
      background-image: linear-gradient(transparent, transparent 17px, $grey 0), linear-gradient(165deg, transparent, transparent 41px, $grey-l 0);
      background-position:
        0 0,
        0 17px;
    }

    &::after {
      @media (min-width: $media-width-lg-min) {
        width: calc(100% + 80px);
        transform: translateX(-80px);
        box-shadow: -7px 10px 100px rgba(0, 0, 0, 0.7);
      }
    }
  }

  .offer-counter__item--edge-end & {
    &::before {
      @include table-edge();

      right: -$end-width + 1px;
      background-image: linear-gradient(transparent, transparent 17px, $grey 0), linear-gradient(195deg, transparent, transparent 41px, $grey-l 0);
      background-position:
        0 0,
        0 -26px;
    }

    &::after {
      @media (min-width: $media-width-lg-min) {
        width: calc(100% + 80px);
        box-shadow: 7px 10px 100px rgba(0, 0, 0, 0.7);
      }
    }
  }

  .offer-counter__item:not(.offer-counter__item--edge-start, .offer-counter__item--edge-end) & {
    &::after {
      @media (min-width: $media-width-lg-min) {
        width: 100%;
      }
    }
  }
}
