@use 'sass:map';

@import '~@telia-front/sass-tokens/index.scss';

$lightbox-max-width: 1230px;

.lightbox {
  position: relative;
  height: 100vh;
  z-index: map.get($zindex, lightbox);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  background: $modal-backdrop-color;
  opacity: 0;
  transition: opacity 100ms ease;

  &.is-entered {
    opacity: 1;
  }
}

.lightbox__inner {
  display: flex;
  align-items: center;
  min-height: 100vh;
  padding: 15px;

  @media (min-width: $media-width-sm-min) {
    padding: $grid-gutter;
  }
}

.lightbox__box {
  display: flex;
  visibility: hidden;
  width: 100%;
  min-width: 100px;
  max-width: $lightbox-max-width;
  margin: auto;
  background-color: $white;
  border-radius: 3px;
  position: relative;
  overflow: hidden;

  &.has-media-loaded {
    visibility: visible;
  }
}

.lightbox__box-inner {
  width: 100%;
}

.lightbox__header {
  padding: 25px 20px;
  background-color: var(--color-brand-primary-bg-subtle);

  @media (min-width: $media-width-sm-min) {
    padding-left: 40px;
    padding-right: 40px;
  }
}

.lightbox__title {
  color: var(--color-neutral-text);
}

.lightbox__close {
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 2;
}

.lightbox__video,
.lightbox__image {
  width: 100%;
  background-color: $modal-background-color;
}

.lightbox__video,
.lightbox__video .video__iframe,
.lightbox__video .video__image {
  border-radius: 0;
}

.lightbox__spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
