@import '~@telia-front/sass-tokens/index.scss';

.product-category-card__icon {
  width: 55px;
  min-width: 55px;

  @media (min-width: $media-width-sm-min) {
    width: 80px;
    min-width: 80px;
  }
}
