@use 'sass:map';

@import '~@telia-front/sass-tokens/index.scss';
@import '~@core-front/tabs/styles.scss';

.tabs--background-light,
.tabs--huge {
  &.tabs--full-width {
    box-shadow: none;

    @each $size, $padding in $card-paddings {
      .card__content--padding-#{$size} > & {
        margin-left: -$padding;
        margin-right: -$padding;
      }
    }

    @each $size, $padding in $card-paddings {
      .card__content--padding-right-#{$size} > & {
        margin-right: -$padding;
      }

      .card__content--padding-left-#{$size} > & {
        margin-left: -$padding;
      }

      .card__content--padding-horizontal-#{$size} > & {
        margin-left: -$padding;
        margin-right: -$padding;
      }
    }
  }
}

.tabs--background-light {
  position: relative;
  background-color: $tabs-light-selected-color;
  box-shadow: 0 1px 1px rgba($black, 0.1);
  border-radius: 3px;

  &.tabs--full-width {
    @each $size, $padding in $card-paddings {
      .card__content--padding-#{$size} > & {
        margin-left: -$padding;
        margin-right: -$padding;
      }
    }

    @each $size, $padding in $card-paddings {
      .card__content--padding-right-#{$size} > & {
        margin-right: -$padding;
      }

      .card__content--padding-left-#{$size} > & {
        margin-left: -$padding;
      }

      .card__content--padding-horizontal-#{$size} > & {
        margin-left: -$padding;
        margin-right: -$padding;
      }
    }
  }
}

.tabs--huge {
  box-shadow: none;
  border-radius: 0 0 8px 8px;
  background-color: $tabs-light-color;

  & > .tabs__nav--huge .tabs__nav-item {
    border-bottom: 1px solid $tabs-huge-border-color;

    &.is-current {
      border-right: 1px solid $tabs-huge-border-color;
      border-left: 1px solid $tabs-huge-border-color;
      border-bottom-color: transparent;
    }
  }

  &.tabs--background-light {
    background-color: $tabs-light-selected-color;
  }

  &.tabs--full-width {
    .modal__body > & {
      margin-left: -$modal-horizontal-spacing-xs;
      margin-right: -$modal-horizontal-spacing-xs;

      @media (min-width: $media-width-sm-min) {
        margin-left: -40px;
        margin-right: -40px;
      }
    }

    @each $size, $padding in $card-paddings {
      .card__content--padding-#{$size} > & {
        margin-left: -$padding;
        margin-right: -$padding;
      }
    }

    @each $size, $padding in $card-paddings {
      .card__content--padding-right-#{$size} > & {
        margin-right: -$padding;
      }

      .card__content--padding-left-#{$size} > & {
        margin-left: -$padding;
      }

      .card__content--padding-horizontal-#{$size} > & {
        margin-left: -$padding;
        margin-right: -$padding;
      }
    }
  }
}

.tabs--sidemenu {
  @media (min-width: $media-width-sm-min) {
    display: flex;
  }
}

.tabs__nav {
  .tabs--pull-top > & {
    margin-top: -42px;

    &.tabs__nav--with-icons {
      margin-top: -48px;
    }

    &.tabs__nav--with-icons.tabs__nav--icons-top {
      margin-top: -86px;

      @media (min-width: $media-width-sm-min) {
        margin-top: -90px;
      }
    }
  }
}

.tabs__nav--background-light {
  background-color: $tabs-light-color;
  border-radius: 3px 3px 0 0;
  padding: 2px 5px 0;
  margin: unset;

  @media (min-width: $media-width-sm-min) {
    padding-right: 40px;
    padding-left: 40px;
    margin: unset;
  }
}

.tabs__nav--huge {
  white-space: normal;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  padding: 0;
  margin-left: unset;
  margin-right: unset;

  @media (min-width: $media-width-sm-min) {
    margin-left: unset;
    margin-right: unset;
  }

  &.tabs__nav--background-light {
    background-color: $tabs-huge-background-color;
  }
}

.tabs__nav--button-navigation {
  display: block;
  margin-left: unset;
  margin-right: unset;
}

.tabs__nav--sidemenu {
  display: block;
  margin: 0;
  overflow: visible;
  margin-left: unset;
  margin-right: unset;

  @media (min-width: $media-width-sm-min) {
    flex: 0 0 auto;
    flex-basis: calc(33.3333% - #{$grid-gutter * 0.5});
    margin-left: unset;
    margin-right: unset;
  }

  @media (min-width: $media-width-md-min) {
    flex-basis: calc(25% - #{$grid-gutter * 0.5});
  }
}

.tabs__nav-inner {
  .tabs__nav--huge & {
    width: 100%;
    display: grid;
    grid-auto-columns: 1fr;
    grid-auto-flow: column;
    gap: 4px;
  }
}

.tabs__nav-item {
  .tabs__nav--huge & {
    background-color: $tabs-huge-background-color;
    display: flex;
    align-items: center;
    justify-content: space-around;
    text-align: center;
    border-top: 2px solid transparent;
    border-radius: 4px 4px 0 0;
    transition: all 120ms cubic-bezier(0.2, 0, 0.31, 1);

    &.is-current {
      background-color: $tabs-huge-background-color-current;
      border-top: 2px solid $tabs-selected-border-color;
      border-radius: 0;
      transition:
        border 1ms 290ms linear,
        background-color 1ms 290ms linear;

      &:first-child {
        border-left: 1px solid $tabs-huge-border-color;
      }

      &:last-child {
        border-right: 1px solid $tabs-huge-border-color;
      }
    }

    &.tabs__nav-item--employee.is-current {
      border-top: 2px solid $tabs-huge-employee-border-color;
    }

    &.is-current + & {
      &::before {
        display: none;
      }
    }

    .tabs--full-width & {
      &.is-current {
        &:first-child {
          border-left: none;
        }

        &:last-child {
          border-right: none;
        }
      }
    }
  }

  .tabs__nav--huge > .tabs__nav-underline.is-animating ~ .tabs__nav-inner > &.is-current {
    background-color: transparent;
  }

  .tabs__nav--background-light & {
    background-color: $tabs-light-color;

    &.is-current {
      background-color: $tabs-light-selected-color;
    }
  }
}

.tabs__nav-link {
  .tabs__nav--huge.tabs__nav--background-light .tabs__nav-item &,
  .tabs__nav--huge .tabs__nav-item & {
    justify-content: center;
    border: none;
    padding: 15px 12px;
    margin: 0;
    width: 100%;
    font-size: inherit;
    font-weight: 400;
    transition: color 120ms cubic-bezier(0.2, 0, 0.31, 1);

    @media (min-width: $media-width-sm-min) {
      padding: 16px 18px 18px;
    }

    &::after {
      content: none;
    }
  }

  .tabs__nav--background-light .tabs__nav-item & {
    height: 100%;
    padding: 10px 14px 13px;
    margin: 0;
    font-size: 14px;
    line-height: 1.43;
    font-weight: 700;

    &::after {
      content: none;
    }
  }

  .tabs__nav--background-light .tabs__nav-item--icon & {
    padding-left: 15px;
  }

  @media (min-width: $media-width-sm-min) {
    font-size: $h4-size-xl;
    line-height: $h4-line-height-xl;
  }
}

.tabs__nav-link-inner {
  .tabs__nav--huge .tabs__nav-item & {
    max-width: 100%;
  }
}

.tabs__content {
  .tabs--background-light > & {
    border: none;
    margin-top: 0;
    border-radius: 0 0 3px 3px;
    background-color: $tabs-light-selected-color;
  }

  .tabs--huge > & {
    border: 1px solid $tabs-huge-border-color;
    margin-top: -1px;
    border-radius: 0 0 8px 8px;
  }

  .tabs--huge.tabs--full-width > & {
    border: none;
    border-top: 1px solid $tabs-huge-border-color;
  }

  .tabs--button-navigation > & {
    border: none;
    margin-top: 0;
  }

  .tabs--sidemenu > & {
    border: none;
    margin-top: 0;

    @media (min-width: $media-width-sm-min) {
      margin-left: $grid-gutter;
      flex: 0 1 auto;
      flex-basis: calc(66.6666% - #{$grid-gutter * 0.5});
      max-width: calc(66.6666% - #{$grid-gutter * 0.5});
    }

    @media (min-width: $media-width-md-min) {
      flex-basis: calc(75% - #{$grid-gutter * 0.5});
      max-width: calc(75% - #{$grid-gutter * 0.5});
    }
  }
}

.tabs__item {
  .tabs--sidemenu & {
    padding: 20px 0;

    @media (min-width: $media-width-sm-min) {
      padding: 0;
    }
  }

  .tabs--medium.tabs--background-light & {
    padding: 20px $base-horizontal-spacing-xs;

    @media (min-width: $media-width-sm-min) {
      padding: 30px;
    }

    @media (min-width: $media-width-md-min) {
      padding: 40px;
    }
  }

  .tabs--huge > .tabs__content > div > & {
    padding: 24px 16px;

    @media (min-width: $media-width-md-min) {
      padding: 32px;
    }
  }
}

.tabs__nav-item-content {
  display: inline-flex;
  align-items: center;
  max-width: 100%;
}

.tabs__nav-item-tag {
  margin-left: 4px;
}

.tabs__nav-underline {
  .tabs__nav--background-light & {
    display: none;
  }

  .tabs__nav--huge.tabs__nav--background-light & {
    background-color: $tabs-huge-background-color;
    display: unset;
  }

  .tabs__nav--huge & {
    height: 100%;
    margin: 0;
    background-color: $tabs-huge-background-color-current;

    &::before {
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      height: 2px;
      background-color: $tabs-selected-border-color;
      pointer-events: none;
      content: '';
    }

    &.tabs__nav-underline--type-employee::before {
      background-color: $tabs-huge-employee-border-color;
    }

    &.is-animating {
      border-left: 1px solid $tabs-huge-border-color;
      border-right: 1px solid $tabs-huge-border-color;
    }
  }
}
