@import '~@telia-front/sass-tokens/index.scss';

@import '~@core-front/close-button/styles.scss';

.close-button--type-modal {
  padding: 0;
}

.close-button__inner {
  .close-button--type-modal & {
    width: $close-button-modal-size;
    height: $close-button-modal-size;
    padding: 0;

    &::before {
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      width: $close-button-modal-size;
      height: $close-button-modal-size;
      background-color: $close-button-modal-background;
      border-radius: 50%;
      opacity: 0.8;
      content: '';
    }
  }
}
