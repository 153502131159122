.header__nav {
  .header__bottom & {
    @media (max-width: $media-width-md-max) {
      display: none;
    }
  }

  @media (min-width: $media-width-lg-min) {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    flex-grow: 1;
  }

  .header__dropdown & {
    display: block;
  }
}

.header__nav-list {
  @include list-unstyled();

  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  @media (min-width: $media-width-lg-min) {
    flex-direction: row;
  }

  &::before {
    content: '';
    display: none;
    position: absolute;
    left: 0;
    right: 0;
    height: 1px;
    top: 0;
    background: var(--color-neutral-border-subtlest);

    @media (min-width: $media-width-lg-min) {
      display: none;
    }
  }
}

.header__nav-item {
  position: relative;
  list-style: none;
  flex-grow: 1;
  display: inline-block;
  box-sizing: initial;

  &::after {
    content: '';
    display: block;
    height: 1px;
    position: absolute;
    right: 0;
    left: 0;
    bottom: 0;
    background: var(--color-neutral-border-subtlest);

    @media (min-width: $media-width-lg-min) {
      display: none;
    }
  }

  &:last-child {
    &::after {
      display: none;
    }
  }
}

.header__nav-link {
  display: flex;
  align-items: center;
  color: var(--color-neutral-text);
  font-weight: 400;
  padding: 16px;
  transition: color $transition-default;
  font-size: var(--type-small-font-size);
  line-height: var(--type-small-line-height);
  justify-content: flex-start;

  .header__dropdown & {
    font-size: $h4-size;
    line-height: $h4-line-height;
  }

  @media (min-width: $media-width-lg-min) {
    width: 100%;
    max-width: max-content;
    text-align: center;
    line-height: 1;
    padding: 15px 12px;
  }

  @media (min-width: $media-width-xl-min) {
    font-size: var(--type-font-size);
    line-height: var(--type-line-height);
  }

  .header__nav-item.is-open > &,
  .header__nav-item.is-current > & {
    color: var(--color-brand-primary-text);
  }

  html[data-whatintent='mouse'] &:hover {
    color: var(--color-brand-primary-text-hover);
  }

  .header__nav-label-inner {
    .header__nav-item.is-current > &::after {
      @media (min-width: $media-width-lg-min) {
        display: block;
        content: '';
        border-bottom: 2px solid var(--color-brand-primary-border);
        padding-top: 70px;
        position: absolute;
        width: 100%;
        z-index: 2;
      }
    }

    .header__nav-item.is-current.has-submenu > &::after {
      @media (min-width: $media-width-lg-min) {
        width: calc(100% + 15px);
      }
    }
  }
}

.header__nav-link--employee {
  color: var(--color-employee-text);

  html[data-whatintent='mouse'] &:hover,
  .header__nav-item.is-open > &,
  .header__nav-item.is-current > & {
    color: var(--color-employee-text-hover);
  }
}

.header__nav-icon {
  @include reset-icon();

  display: block;
  font-size: 1.5rem;
  margin-right: 8px;

  @media (min-width: $media-width-lg-min) {
    display: none;
  }
}

.header__nav-item-icon {
  font-size: 1.5rem;
  margin-right: 8px;
}

.header__nav-label {
  flex: 1 1 auto;

  @media (min-width: $media-width-lg-min) {
    display: flex;
    max-width: 100%;
    flex: initial;
  }
}

.header__nav-label-inner {
  position: relative;
  padding-right: 2px;
  display: flex;
  align-items: center;

  @media (min-width: $media-width-lg-min) {
    min-height: 2em;
  }
}

.header__nav-arrow {
  @include reset-icon();

  flex-shrink: 0;
  font-size: 1.5rem;
  display: initial;

  @media (min-width: $media-width-lg-min) {
    font-size: 1rem;
  }

  .header__nav-item.is-open > .header__nav-link > & {
    @media (max-width: $media-width-md-max) {
      transform: rotate(180deg);
    }
  }
}

.header__nav-secondary-icon {
  font-size: 1rem;
  margin-left: 3px;
}

.header__nav-megamenu {
  display: none;

  .header__nav-megamenu-dropdown & {
    display: block;
  }

  .header__nav-item.is-open > & {
    display: block;

    @media (min-width: $media-width-lg-min) {
      display: none;
    }
  }

  .header__nav-item > & {
    padding: 16px;
  }
}

.header__nav-megamenu-dropdown {
  .header__dropdown-content {
    padding: 32px 20px 40px;
  }
}

.header__nav-item-button-container {
  padding: 32px;

  @media (min-width: $media-width-lg-min) {
    display: none;
  }

  .header__nav-item-button {
    box-sizing: border-box;
  }
}
