@import '~@core-front/sass-tokens/index.scss';

.number {
  position: relative;

  &.is-disabled {
    color: $number-disabled-color;
  }
}

.number__label {
  display: block;
  font-size: 12px;
  text-align: center;
  max-width: $number-max-width;

  .number--hide-label & {
    @include visually-hidden();
  }

  .number--small & {
    max-width: $number-small-max-width;
  }

  .number--align-center & {
    margin-left: auto;
    margin-right: auto;
  }
}

.number__inner {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  align-items: center;
  max-width: $number-max-width;

  .number--small & {
    max-width: $number-small-max-width;
  }

  .number--align-center & {
    margin-left: auto;
    margin-right: auto;
  }
}

.number__input {
  border: 1px solid $number-input-border-color;
  border-radius: $number-input-border-radius;
  display: block;
  font-size: 16px;
  margin: $number-input-margin;
  padding: 0;
  height: $number-input-height;
  width: $number-input-width;
  background: none;
  text-align: center;
  color: inherit;
  transition-duration: 200ms;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  appearance: textfield;
  outline: none;

  .number.is-disabled & {
    border-color: $number-input-disabled-border-color;
  }

  &:focus {
    border-color: $number-input-focus-outline;
  }

  .number.is-invalid & {
    border-color: $number-invalid-color;
  }

  .number--small & {
    height: $number-small-input-height;
    font-size: 12px;
    width: $number-input-small-width;
    margin: $number-input-small-margin;
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    display: none;
  }
}

.number__form-helper {
  margin-top: 5px;

  .number--align-center & {
    justify-content: center;
  }
}
