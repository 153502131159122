@import '~@core-front/sass-tokens/index.scss';

.container {
  width: 100%;
  max-width: $page-max-width;
  padding-left: $container-padding;
  padding-right: $container-padding;
  margin: 0 auto;

  @media (min-width: $media-width-sm-min) {
    padding-left: $container-padding-sm;
    padding-right: $container-padding-sm;
  }

  @media (min-width: $media-width-lg-min) {
    padding-left: $container-padding-lg;
    padding-right: $container-padding-lg;
  }
}
