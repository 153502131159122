@import '~@telia-front/sass-tokens/index.scss';

.section {
  z-index: 0;
  position: relative;
  width: 100%;
  max-width: 2560px;
  margin-left: auto;
  margin-right: auto;
  color: var(--color-neutral-text);
}

.section--has-min-height {
  @media (min-width: $media-width-md-min) {
    display: flex;
    flex-direction: column;
  }
}

.section__inner {
  .section--has-min-height & {
    @media (min-width: $media-width-md-min) {
      display: flex;
      align-items: center;
    }

    @media (max-width: $media-width-sm-max) {
      min-height: auto !important;
    }
  }

  .section--full & {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .section--full.section--bottom-padding-large & {
    padding-bottom: 80px;
  }

  .section--full.section--bottom-padding-xlarge & {
    padding-bottom: 140px;
  }

  .section--full.section--small-screen-image-above & {
    @media (max-width: $media-width-sm-max) {
      padding-top: 0;
    }
  }

  .section--pull-top & {
    @media (min-width: $media-width-sm-min) {
      padding-top: 67px;
    }
  }
}

.section--pull-top {
  margin-top: -30px;

  @media (min-width: $media-width-sm-min) {
    margin-top: -72px;
  }
}

.section--pull-top-sales-arguments {
  margin-top: -15px;

  @media (min-width: $media-width-sm-min) {
    margin-top: -72px;
  }
}

.section--pull-top-no-breadcrumbs {
  margin-top: -30px;
}

.section--pull-bottom {
  margin-bottom: -40px;
}

.section--pull-bottom-footer {
  margin-bottom: -100px;
}

.section__close-button {
  right: 5px;
  top: 5px;
  position: absolute;
}

.section__background {
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;

  .section--small-screen-image-above & {
    @media (max-width: $media-width-sm-max) {
      position: relative;
      margin-bottom: 30px;
    }
  }
}

.section__background-image {
  display: block;
  width: 100%;
  height: 100%;

  .section--small-screen-image-above & {
    @media (max-width: $media-width-sm-max) {
      position: relative;
    }
  }
}

.section__background-picture {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  height: 100%;

  .section__background.has-video-and-image & {
    .section--full & {
      display: none;

      @media (max-width: $media-width-sm-max) {
        display: block;
      }
    }
  }
}

.section__background-video {
  .section--full & {
    height: 56.25vw;
    left: 50%;
    min-height: 100%;
    min-width: 100%;
    max-width: unset;
    transform: translate(-50%, -50%);
    position: absolute;
    top: 50%;
    width: 177.7778vh;
  }

  .section--full.section--small-screen-image-above & {
    @media (max-width: $media-width-sm-max) {
      height: unset;
      left: unset;
      transform: unset;
      position: unset;
      width: unset;
    }
  }

  .section__background.has-video-and-image & {
    @media (max-width: $media-width-sm-max) {
      display: none !important;
    }
  }
}

.section__background-video,
.section__background-video .video__iframe,
.section__background-video .video__image {
  border-radius: 0;
}

.section__content {
  width: 100%;
}

.section__carousel {
  position: absolute;
  overflow: hidden;
  z-index: -1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  .slick-track {
    height: 100%;
  }

  .slick-slide {
    display: block;
    position: relative;
    float: left;
    height: 100%;
    opacity: 1 !important;
    pointer-events: none;
  }

  .slick-list {
    height: 100%;
  }

  .section--small-screen-image-above & {
    @media (max-width: $media-width-sm-max) {
      position: relative;
    }
  }
}

.section__carousel-item {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 1;
  transition: opacity 500ms cubic-bezier(0.25, 0.1, 0.25, 1);

  &.is-current {
    z-index: 1;
  }

  &.is-prev-current {
    z-index: 2;
    opacity: 0;
  }

  .section--small-screen-image-above & {
    @media (max-width: $media-width-sm-max) {
      position: relative;
    }
  }
}

.section__image-container {
  position: relative;
  height: 100%;
  width: 100%;
}

.section__image {
  margin: auto;

  @media (min-width: $media-width-md-min) {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(0, -50%);
  }
}

.section__carousel-button {
  position: absolute;
  top: 10px;
  right: $container-padding;

  @media (min-width: $media-width-sm-min) {
    right: $container-padding-sm;
  }

  @media (min-width: $media-width-lg-min) {
    right: $container-padding-lg;
  }

  &--bottom {
    bottom: 10px;
    top: initial;
  }

  &--mobile-bottom {
    @media (max-width: $media-width-sm-max) {
      bottom: 10px;
      top: initial;
    }
  }

  &--mobile-top {
    @media (max-width: $media-width-sm-max) {
      top: 10px;
    }
  }
}

.section__carousel-inner {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
}

.section__carousel-inner-container {
  position: relative;
  height: 100%;
}
