@import '~@core-front/sass-tokens/index.scss';

.accordion {
  --accordion-border-color: #{$accordion-border-color};
  --accordion-title-color: #{$text-color-base};
  --accordion-title-hover-color: #{$accordion-title-hover-color};
  --accordion-title-open-color: #{$accordion-title-open-color};
  --accordion-title-inactive-color: #{$accordion-label-inactive};
  --accordion-arrow-color: #{$text-color-base};
  --accordion-arrow-hover-color: #{$accordion-arrow-hover-color};
  --accordion-arrow-open-color: #{$accordion-arrow-is-open-color};
  --accordion-icon-color: var(--accordion-arrow-color);
}

.accordion--bordered {
  border-top: 1px solid var(--accordion-border-color);
}

.accordion--full-width {
  .container > & {
    @media (max-width: $media-width-xs-max) {
      margin-left: -$container-padding;
      margin-right: -$container-padding;
    }
  }
}

.accordion__item {
  position: relative;

  &:not(:last-child) {
    border-bottom: 1px solid var(--accordion-border-color);
  }

  .accordion--bordered > &:last-child {
    border-bottom: 1px solid var(--accordion-border-color);
  }
}

.accordion__item--employee-bg {
  background: $accordion-employee-bg;
}

.accordion__item-inner {
  position: relative;
  width: 100%;
}

.accordion__heading {
  font-weight: inherit;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: inherit;
  min-width: 0;

  &:not(.h1, .h2, .h3, .h4, .h5, .h6, .p) {
    font-size: 16px;
    line-height: 1.375;
    font-weight: $accordion-title-font-weight;

    .accordion--large & {
      font-weight: inherit;
    }
  }
}

.accordion__header {
  display: flex;
  flex-direction: column;
  cursor: pointer;

  .accordion__item--no-content & {
    cursor: default;
  }

  .accordion__item--employee > .accordion__item-inner > & {
    --accordion-title-hover-color: #{$accordion-employee-hover-color};
    --accordion-title-open-color: #{$accordion-employee-open-color};
    --accordion-arrow-hover-color: #{$accordion-employee-hover-color};
    --accordion-arrow-open-color: #{$accordion-employee-open-color};
  }

  .accordion__item.is-inactive > .accordion__item-inner > & {
    --accordion-title-color: var(--accordion-title-inactive-color);
    --accordion-icon-color: #{$accordion-icon-inactive};
  }
}

.accordion__header-inner {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: $accordion-header-inner-gap;
  padding-top: $accordion-header-inner-padding-top;
  padding-bottom: $accordion-header-inner-padding-bottom;
  padding-right: $accordion-header-inner-padding-right;
  padding-left: $accordion-header-inner-padding-left;
  min-height: $accordion-header-inner-min-height;

  @media (min-width: $media-width-sm-min) {
    padding-left: $accordion-header-inner-padding-left-sm;
    padding-right: $accordion-header-inner-padding-right-sm;
    min-height: $accordion-header-inner-min-height-sm;
  }

  .accordion__item--icon > .accordion__item-inner > .accordion__header & {
    padding-left: 16px;
  }

  .accordion__item.is-open > .accordion__item-inner > .accordion__header > & {
    box-shadow: $accordion-header-inner-box-shadow;
  }

  .accordion--large > .accordion__item > .accordion__item-inner > .accordion__header > & {
    @media (min-width: $media-width-sm-min) {
      min-height: $accordion-header-inner-large-min-height;
      padding-right: 64px;
    }
  }

  .accordion--large & {
    @media (min-width: $media-width-sm-min) {
      padding-left: 16px;
      gap: $accordion-header-inner-gap-large;
    }
  }
}

.accordion__header-content-wrapper {
  position: relative;
  flex: 1 1 auto;
  min-width: 0;

  @media (min-width: $media-width-sm-min) {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
  }
}

.accordion__arrow {
  @include transition(
    (
      'animation': medium,
      'properties': (
        color,
        transform,
      ),
    )
  );

  font-size: 32px;
  position: absolute;
  right: $accordion-arrow-right;
  top: 50%;
  cursor: pointer;
  color: var(--accordion-arrow-color);
  transform: translateY(-50%);
  padding: 8px;
  line-height: 0;

  @media (min-width: $media-width-sm-min) {
    right: $accordion-arrow-right-sm;
    left: $accordion-arrow-left-sm;
  }

  .accordion__item.is-open > .accordion__item-inner > .accordion__header & {
    transform: rotate(180deg) translateY(50%);
    color: var(--accordion-arrow-open-color);
  }

  .accordion__item--no-content > .accordion__item-inner > .accordion__header &,
  .accordion__item--no-content > & {
    display: none;
  }

  .accordion--large > .accordion__item > .accordion__item-inner > .accordion__header & {
    @media (min-width: $media-width-sm-min) {
      padding: 16px;
    }
  }

  html[data-whatintent='mouse'] .accordion__item:not(.accordion__item--no-content) > .accordion__item-inner > .accordion__header:hover & {
    color: var(--accordion-arrow-hover-color);
  }
}

.accordion__title {
  @include reset-button();
  @include transition(
    (
      'animation': short,
      'properties': color,
    )
  );

  color: var(--accordion-title-color);
  max-width: 100%;
  font-weight: inherit;
  line-height: inherit;

  .accordion__item.is-open > .accordion__item-inner > .accordion__header & {
    color: var(--accordion-title-open-color);
  }

  html[data-whatintent='mouse'] .accordion__item:not(.accordion__item--no-content) > .accordion__item-inner > .accordion__header:hover & {
    color: var(--accordion-arrow-hover-color);
  }

  .accordion__item--no-content:not(.is-inactive) > .accordion__item-inner > .accordion__header & {
    cursor: auto;
  }
}

.accordion__body {
  @include transition(
    (
      'animation': medium,
      'properties': height,
    )
  );

  .accordion__item.is-open > .accordion__item-inner > & {
    box-shadow: $accordion-body-box-shadow;
  }
}

.accordion__content {
  padding: $accordion-content-padding;

  @media (min-width: $media-width-sm-min) {
    padding: $accordion-content-padding-sm;
  }
}

.accordion__icon-wrapper,
.accordion__image-wrapper {
  z-index: 1;
  line-height: 0;
  flex-shrink: 0;
}

.accordion__icon {
  @include reset-icon();
  @include transition(
    (
      'animation': medium,
      'properties': color,
    )
  );

  font-size: 32px;
  color: var(--accordion-icon-color);

  .accordion__item.is-open > .accordion__item-inner > .accordion__header & {
    color: var(--accordion-arrow-open-color);
  }

  .accordion--large > .accordion__item > .accordion__item-inner > .accordion__header & {
    @media (min-width: $media-width-sm-min) {
      font-size: $accordion-large-icon-size;
    }
  }

  html[data-whatintent='mouse'] .accordion__item:not(.accordion__item--no-content) > .accordion__item-inner > .accordion__header:hover & {
    color: var(--accordion-arrow-hover-color);
  }
}

.accordion__image {
  width: 32px;

  .accordion--large > .accordion__item > .accordion__item-inner > .accordion__header & {
    @media (min-width: $media-width-sm-min) {
      width: $accordion-large-image-size;
    }
  }
}

.accordion__meta {
  padding: $accordion-meta-padding;

  @media (min-width: $media-width-sm-min) {
    padding: 0 8px 0 0;
  }
}
