$fit-values: (fill, contain, cover);
$pos-x: (left, right, center);
$pos-y: (top, bottom, center);

@each $xValue in $pos-x {
  @each $yValue in $pos-y {
    .object-position--#{$yValue}-#{$xValue} {
      object-position: #{$yValue} #{$xValue};
    }
  }
}

@each $value in $pos-x {
  .object-position--#{$value} {
    object-position: #{$value};
  }
}

@each $value in $pos-y {
  @if $value != 'center' {
    .object-position--#{$value} {
      object-position: #{$value};
    }
  }
}

@each $value in $fit-values {
  .object-fit--#{$value} {
    object-fit: #{$value};
  }
}

.object-fit {
  position: absolute;
  height: 100%;
  width: 100%;
}
