@import '~@telia-front/sass-tokens/index.scss';

.grid-collapse {
  transition: 150ms height;
}

.grid-collapse-trigger {
  transition: 150ms all;
}

.grid-collapse-trigger--entered {
  opacity: 1;
}

.grid-collapse-trigger--exiting {
  opacity: 0;
}

.grid-collapse-trigger--exited {
  display: none;
}
