@use 'sass:color';
@use 'sass:map';

.header__overlay-notices-wrapper {
  pointer-events: auto;
  position: absolute;
  z-index: -1;
  width: 100%;
}

.header__overlay-notice-wrapper {
  position: relative;
  transition: height 500ms cubic-bezier(0.86, 0, 0.07, 1);

  @for $i from 1 through 10 {
    &:nth-child(#{$i}) {
      z-index: -1 * $i;
    }
  }
}

.header__overlay-notice {
  position: relative;
  top: 0;
  left: 0;
  right: 0;

  .header__overlay-notice-wrapper.is-entering &,
  .header__overlay-notice-wrapper.is-entered &,
  .header__overlay-notice-wrapper.is-exiting &,
  .header__overlay-notice-wrapper.is-exited & {
    position: absolute;
  }

  .header__overlay-notice-wrapper.is-entering & {
    transform: translateY(calc(-100% - 3px));
  }

  .header__overlay-notice-wrapper.is-entered & {
    transform: translateY(0);
    transition: transform 500ms cubic-bezier(0.86, 0, 0.07, 1);
  }

  .header__overlay-notice-wrapper.is-exiting & {
    transform: translateY(0);
  }

  .header__overlay-notice-wrapper.is-exited & {
    transform: translateY(calc(-100% - 3px));
    transition: transform 500ms cubic-bezier(0.86, 0, 0.07, 1);
  }
}
