@import '~@telia-front/sass-tokens/index.scss';

@import '~@core-front/list/styles.scss';

.list__inner {
  .list--ordered.list--number-size-huge > &,
  .list--ordered.list--number-size-small > & {
    @media (min-width: $media-width-lg-min) {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 0;
      margin-left: -10px;
      margin-right: -10px;
    }
  }
}

.list__item {
  .list--ordered.list--number-size-huge > .list__inner > &,
  .list--ordered.list--number-size-small > .list__inner > & {
    @media (min-width: $media-width-lg-min) {
      flex: 1 0 20%;
      margin-top: 0;
      padding: 0 10px;
    }
  }

  .list--ordered.list--number-size-huge.list--no-gutter > .list__inner > &,
  .list--ordered.list--number-size-huge.list--no-horizontal-gutter > .list__inner > & .list--ordered.list--number-size-small.list--no-gutter > .list__inner > &,
  .list--ordered.list--number-size-small.list--no-horizontal-gutter > .list__inner > & {
    @media (min-width: $media-width-lg-min) {
      padding-left: 0;
      padding-right: 0;
    }
  }
}

.list__item-inner {
  &::before {
    .list--ordered.list--number-size-huge > .list__inner > .list__item > &,
    .list--ordered.list--number-size-small > .list__inner > .list__item > & {
      font-family: $font-secondary;
      content: counter(item);
      font-size: 32px;
      line-height: 0.9;
      margin-right: 0.25em;
    }

    .list--ordered.list--number-size-huge > .list__inner > .list__item > & {
      @media (min-width: $media-width-lg-min) {
        font-size: 56px;
        margin-right: 0.1429em;
      }
    }

    .list--ordered.list--number-size-small > .list__inner > .list__item > & {
      @media (min-width: $media-width-lg-min) {
        font-size: 40px;
        margin-right: 0.2em;
      }
    }
  }
}

.list__content {
  .list--number-size-huge > .list__inner > .list__item > .list__item-inner > &,
  .list--number-size-small > .list__inner > .list__item > .list__item-inner > & {
    @media (min-width: $media-width-lg-min) {
      align-self: flex-start;
    }
  }
}
