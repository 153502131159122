
// Do not edit directly
// Generated on Thu, 19 Sep 2024 07:09:50 GMT

$color-raw-purple-black: #1f002e;
$color-raw-purple-dim: #29003e;
$color-raw-purple-deep: #46006d;
$color-raw-purple-dark: #5e0092;
$color-raw-purple-light: #7800b4;
$color-raw-purple-base: #990ae3;
$color-raw-purple-medium: #c461f7;
$color-raw-purple-bright: #d58df9;
$color-raw-purple-vivid: #e4b6fb;
$color-raw-purple-fare: #f4e0ff;
$color-raw-purple-crystal: #fbf0ff;
$color-raw-green-base: #008641 !default;
$color-raw-green-light: #ccefdd;
$color-raw-green-medium: #00572a;
$color-raw-green-dark: #00827c;
$color-raw-blue-base: #007acc !default;
$color-raw-blue-light: #ccebff !default;
$color-raw-blue-medium: #0094ff;
$color-raw-blue-dark: #00558f;
$color-raw-red-deep: #890e37 !default;
$color-raw-red-light: #be0040 !default;
$color-raw-red-base: #e4175c !default;
$color-raw-red-bright: #fad1de !default;
$color-raw-orange-base: #cc7c00;
$color-raw-orange-light: #ffebcc;
$color-raw-orange-strong: #995d00;
$color-raw-pink-base: #c428ad;
$color-raw-pink-light: #ff00cd;
$color-raw-pink-medium: rgba(210, 45, 185, 0.1);
$color-raw-pink-bright: #fcedf2;
$color-raw-white-base: #ffffff;
$color-raw-white-strong: rgba(255, 255, 255, 0.87);
$color-raw-white-medium: rgba(255, 255, 255, 0.6);
$color-raw-white-bright: rgba(255, 255, 255, 0.48);
$color-raw-white-dull: rgba(255, 255, 255, 0.38);
$color-raw-white-light: rgba(255, 255, 255, 0.24);
$color-raw-white-fare: rgba(255, 255, 255, 0.12);
$color-raw-grey-strong: #121214 !default;
$color-raw-grey-deep: #28282a !default;
$color-raw-grey-black: #303032 !default;
$color-raw-grey-medium: #48484b !default;
$color-raw-grey-dark: #8c8c90 !default;
$color-raw-grey-bright: #dcdce1 !default;
$color-raw-grey-base: #e8e8ed !default;
$color-raw-grey-light: #f4f4f9 !default;
$color-raw-black-base: #000000;
$color-raw-black-strong: rgba(0, 0, 0, 0.87);
$color-raw-black-medium: rgba(0, 0, 0, 0.6);
$color-raw-black-bright: rgba(0, 0, 0, 0.48);
$color-raw-black-dull: rgba(0, 0, 0, 0.38);
$color-raw-black-light: rgba(0, 0, 0, 0.24);
$color-raw-black-fare: rgba(0, 0, 0, 0.12);
$color-raw-beige-base: #5c524e;
$color-raw-beige-medium: #988a84;
$color-raw-beige-light: #ecded4;
$color-raw-beige-fare: #f8eee8;
$color-third-party-swedbank-base: #fc8314;
$color-third-party-swedbank-light: #ff9e44;
$color-third-party-seb-base: #51c103;
$color-third-party-seb-light: #7bd73a;
$color-third-party-danske-base: #00466d;
$color-third-party-danske-light: #006394;
$color-third-party-lhv-base: #474747;
$color-third-party-lhv-light: #6d6d6d;
$color-third-party-coop-base: #0079cf;
$color-third-party-luminor-base: #481335;
$color-third-party-google-base: #f24a2e;
$color-third-party-facebook-base: #3b5998;
$color-third-party-microsoft-base: #03a4f0;
$color-third-party-spotify-base: #00d35f;
$color-third-party-twitter-base: #55acee;
$color-third-party-diil-base: #009999;
$color-graph-1: #5e0092;
$color-graph-2: #990ae3;
$color-graph-3: #988a84;
$color-graph-4: #e4175c;
$color-graph-5: #ecded4;
$color-graph-6: #ff00cd;
$color-graph-7: #cc7c00;
$color-primary-1: #990ae3 !default;
$color-primary-2: #7800b4 !default;
$color-primary-3: #f4f4f9 !default;
$color-primary-4: #ffffff !default;
$color-primary-5: #000000 !default;
$color-primary-6: #5e0092 !default;
$color-neutral-1: #f4f4f9 !default;
$color-neutral-2: #e8e8ed !default;
$color-neutral-3: #8c8c90 !default;
$color-neutral-4: rgba(0, 0, 0, 0.6) !default;
$color-neutral-5: #303032 !default;
$color-neutral-6: #28282a !default;
$color-background-1: #5e0092 !default;
$color-background-2: #46006d !default;
$color-background-3: #1f002e !default;
$color-background-4: #f8eee8 !default;
$color-background-5: #d22db91a !default;
$color-background-6: #f4e0ff !default;
$color-background-7: #29003e !default;
$color-background-8: #ecded4 !default;
$color-success-1: #008641 !default;
$color-success-2: #ccefdd !default;
$color-success-3: #00572a !default;
$color-selected-1: #5e0092 !default;
$color-selected-2: #990ae3 !default;
$color-selected-3: #c461f7 !default;
$color-campaign-1: #121214 !default;
$color-campaign-2: #e4175c !default;
$color-campaign-3: #46006d !default;
$color-campaign-4: #00827c !default;
$color-campaign-light-1: #f4f4f9 !default;
$color-campaign-light-2: #fad1de !default;
$color-campaign-light-3: #e4b6fb !default;
$color-campaign-extra-light-2: #fcedf2 !default;
$color-information-1: #007acc !default;
$color-information-2: #ccebff !default;
$color-information-3: #00558f !default;
$color-focus-1: #0094ff !default;
$color-attention-1: #cc7c00 !default;
$color-attention-2: #ffebcc !default;
$color-attention-3: #995d00 !default;
$color-recommended-1: #46006d !default;
$color-recommended-2: #e4b6fb !default;
$color-inactive-1: rgba(0, 0, 0, 0.38) !default;
$color-inactive-2: rgba(255, 255, 255, 0.38) !default;
$color-error-1: #e4175c !default;
$color-error-2: #be0040 !default;
$color-error-3: #890e37 !default;
$color-error-4: #fad1de !default;
$color-employee-1: #c428ad !default;
$color-employee-2: #ff00cd !default;
$color-employee-3: rgba(210, 45, 185, 0.1) !default;
