@import '~@telia-front/sass-tokens/index.scss';

.listbox {
  @include list-unstyled();

  border-bottom: 1px solid var(--color-neutral-border-subtlest);
}

.listbox__option {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  gap: 8px;
  padding: 16px;
  cursor: pointer;
  border-top: 1px solid var(--color-neutral-border-subtlest);
  min-height: 57px; // Icon height + padding + border. Prevents layout shift when option without description or icon is selected.
  outline-offset: -2px;
  background-clip: padding-box;

  &:hover {
    background-color: var(--color-neutral-bg-hover);
  }

  &[aria-selected='true'] {
    background-color: var(--color-neutral-bg-subtlest);
    color: var(--color-brand-primary-text);
    font-weight: 700;
  }
}

.listbox__option-icon {
  flex-shrink: 0;
}

.listbox__option-label {
  font-weight: 700;
  color: var(--color-neutral-text);

  .listbox__option[aria-selected='true'] & {
    color: var(--color-brand-primary-text);
  }
}

.listbox__option-desc {
  color: var(--color-neutral-text-subtle);

  .listbox__option[aria-selected='true'] & {
    color: var(--color-brand-primary-text);
  }
}
