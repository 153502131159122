@use 'sass:math';

@import '~@telia-front/sass-tokens/index.scss';

.banner-old {
  position: relative;
  display: flex;
  flex-direction: column;
  border-radius: 3px;
  width: 100%;
  box-shadow: 0 1px 1px rgba($black, 0.1);
  color: inherit;

  html[data-whatintent='mouse'] &:hover {
    color: inherit;
  }
}

.banner-old--dark {
  color: $white;

  html[data-whatintent='mouse'] &:hover {
    color: $white;
  }
}

.banner-old--anchor {
  font-weight: inherit;
}

.banner-old__image-column {
  overflow: hidden;
  position: relative;
  width: 100%;
  border-radius: 3px 3px 0 0;

  @media (min-width: $media-width-lg-min) {
    position: absolute;
    height: 100%;
    border-radius: 3px;
  }

  .banner-old--xsmall & {
    position: absolute;
    height: 100%;
    border-radius: 3px;
  }

  &::before {
    content: '';
    display: block;
    width: 100%;
    padding-top: 50%;

    .banner-old--xsmall & {
      padding-top: 0;
    }

    .banner-old--large & {
      padding-top: 100%;
    }
  }
}

.banner-old__content-column {
  position: relative;
  display: flex;
  flex: 1 0 auto;

  &::before {
    @media (min-width: $media-width-lg-min) {
      content: '';
      display: block;
      width: 100%;
      padding-top: math.div(390, 1180) * 100%;

      .banner-old--xsmall & {
        padding-top: 0;
      }
    }
  }
}

.banner-old__content .animate__item.enter,
.banner-old__content .animate__item.entered {
  z-index: 1;
}

.banner-old__image-animate {
  position: absolute;
  width: 100%;
  // important to override inline style applied by component
  height: 100% !important;
  top: 0;
  left: 0;
}

.banner-old__image-animate-item {
  top: 0;
  left: 0;
  height: 100%;
  transform: none;
}

.banner-old__image-picture {
  display: block;
  width: 100%;
  height: 100%;
}

.banner-old__image {
  position: absolute;
  width: 100%;
  top: 0;
  bottom: 0;

  @media (min-width: $media-width-lg-min) {
    height: 100%;
  }
}

.banner-old--no-radius {
  border-radius: 0;

  .banner-old__image-column {
    border-radius: 0;
  }
}

.banner-old__inner {
  // important rule since element is inside flex parent
  max-width: 100%;
  padding: 15px;
  width: 100%;
  align-self: auto;
  display: flex;
  flex-direction: column;

  @media (min-width: $media-width-sm-min) {
    padding: 30px;
  }

  @media (min-width: $media-width-lg-min) {
    margin-left: -100%;
    width: 50%;
    padding: 40px 0 40px 40px;
  }

  .banner-old--xsmall & {
    width: 66%;
    padding: 16px 0 16px 16px;

    @media (min-width: $media-width-lg-min) {
      padding: 32px 0 32px 32px;
    }
  }
}

.banner-old__heading {
  margin-bottom: 10px;
  flex: 1 0 auto;

  @media (min-width: $media-width-sm-min) {
    margin-bottom: 25px;

    .banner-old--xsmall & {
      margin-bottom: 8px;
    }
  }

  .banner-old--xsmall & {
    margin-bottom: 8px;
  }
}

.banner-old__tag {
  position: absolute;
  top: 15px;

  @media (min-width: $media-width-sm-min) {
    top: 30px;
  }

  @media (min-width: $media-width-lg-min) {
    top: 40px;
  }

  .banner-old--tag-left & {
    left: 15px;

    @media (min-width: $media-width-sm-min) {
      left: 30px;
    }

    @media (min-width: $media-width-md-min) {
      left: 40px;
    }

    @media (min-width: $media-width-lg-min) {
      left: calc(50% + 40px);
    }
  }

  .banner-old--tag-right & {
    right: 15px;

    @media (min-width: $media-width-sm-min) {
      right: 30px;
    }

    @media (min-width: $media-width-lg-min) {
      right: 40px;
    }
  }
}
