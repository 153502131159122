@import '~@core-front/sass-tokens/index.scss';

@mixin textfield-hide-label() {
  // important is necessary to override all the possible states
  color: transparent !important;
  padding-top: 10px !important;
  animation: none !important;

  &::before {
    opacity: 1 !important;
    top: -10px !important;
    animation: none !important;
  }

  &::after {
    top: 0 !important;
    animation: none !important;
  }
}

.textfield {
  position: relative;
  font-size: 16px;
  width: 100%;
  max-width: 100%;

  &.is-disabled {
    color: $textfield-disabled-color;
  }

  &.is-focused {
    z-index: 2;
  }

  .table & {
    min-width: 180px;
  }
}

.textfield--employee {
  &::before {
    content: '';
    display: block;
    background: rgba($color-employee-1, 0.1);
    position: absolute;
    top: -5px;
    left: -5px;
    right: -5px;
    bottom: -5px;
    border-radius: 3px;
  }
}

.textfield__inner {
  position: relative;
  padding: $textfield-label-visible-height 0 0;

  .textfield--no-label-no-break & {
    padding-top: $textfield-no-label-padding-top;

    &::after {
      // important is necessary to override all the possible states
      opacity: 1 !important;
      top: 0;
      border-top-right-radius: 3px;
      border-right: $textfield-border;
      height: 10px;
      right: 0;
    }
  }

  .textfield--no-label-no-break.textfield--small & {
    padding-top: $textfield-small-no-label-padding-top;

    &::after {
      // important is necessary to override all the possible states
      opacity: 1 !important;
      top: 0;
    }
  }

  .textfield--no-label-sm & {
    @media (min-width: $media-width-sm-min) {
      padding-top: $textfield-no-label-padding-top;

      &::after {
        // important is necessary to override all the possible states
        opacity: 1 !important;
        top: 0;
        border-top-right-radius: 3px;
        border-right: $textfield-border;
        height: 10px;
        right: 0;
      }
    }
  }

  .textfield--no-label-sm.textfield--small & {
    @media (min-width: $media-width-sm-min) {
      padding-top: $textfield-small-no-label-padding-top;

      &::after {
        // important is necessary to override all the possible states
        opacity: 1 !important;
        top: 0;
      }
    }
  }

  .textfield--no-label-md & {
    @media (min-width: $media-width-md-min) {
      padding-top: $textfield-no-label-padding-top;

      &::after {
        // important is necessary to override all the possible states
        opacity: 1 !important;
        top: 0;
        border-top-right-radius: 3px;
        border-right: $textfield-border;
        height: 10px;
        right: 0;
      }
    }
  }

  .textfield--no-label-md.textfield--small & {
    @media (min-width: $media-width-md-min) {
      padding-top: $textfield-small-no-label-padding-top;

      &::after {
        // important is necessary to override all the possible states
        opacity: 1 !important;
        top: 0;
      }
    }
  }

  &::after {
    content: '';
    display: block;
    position: absolute;
    top: $textfield-border-top-position;
    left: 10px;
    right: 10px;
    border-top: $textfield-border;
    transition: border-color 100ms ease-out;
    opacity: 0;

    .textfield--floating-label & {
      opacity: 1;
    }

    .textfield.is-focused & {
      border-color: $textfield-focused-border-color;
      opacity: 0;
    }

    .textfield.is-dirty & {
      opacity: 0;
    }

    .textfield.is-invalid & {
      border-color: $textfield-error-border-color;
    }

    .textfield.is-disabled & {
      border-color: $textfield-disabled-border-color;
    }
  }

  .textfield--small & {
    padding-top: 10px;

    &::after {
      height: 3px;
      left: 5px;
      top: $textfield-border-top-position;
    }
  }
}

.textfield__image {
  position: absolute;
  max-width: 22px;
  margin-right: 10px;
  left: $textfield-image-left;
  top: 50%;
  transform: translateY(calc(-50% + 3px));

  .textfield--image.textfield--no-label-no-break &,
  .textfield--image.textfield--no-label-no-break.textfield--small &,
  .textfield--image.textfield--no-label-sm &,
  .textfield--image.textfield--no-label-md & {
    transform: translateY(-50%);
  }
}

.textfield__input {
  border: $textfield-border;
  border-top: 0;
  border-radius: $textfield-border-radius;
  box-shadow: $textfield-box-shadow;
  display: block;
  font-size: $textfield-font-size;
  font-weight: $textfield-weight;
  margin: 0;
  padding: $textfield-padding;
  height: $textfield-height;
  width: 100%;
  background: $textfield-background;
  color: $textfield-color;
  text-transform: $textfield-transform;
  transition: border-color 100ms ease-out;
  text-align: $textfield-text-align;

  .textfield--with-icon & {
    padding-right: $textfield-valid-padding-right;
  }

  .textfield.is-focused & {
    outline: none;
    border-color: $textfield-focused-border-color;
  }

  .textfield.is-invalid & {
    border-color: $textfield-error-border-color;
  }

  .textfield.is-disabled & {
    background-color: $textfield-disabled-background;
    color: $textfield-disabled-color;
    -webkit-text-fill-color: $textfield-disabled-color;
    outline: none;
    border-color: $textfield-disabled-border-color;
  }

  .textfield.is-static & {
    background: none;
    box-shadow: none;
    height: auto;
    border: 0;
    padding: $textfield-static-padding;
  }

  &::-ms-clear {
    display: none;
  }

  &:focus {
    outline: none;
  }

  .textfield--small & {
    height: $textfield-small-height;
    padding: $textfield-small-padding;
    font-size: 12px;
  }

  .textfield--large & {
    height: $textfield-large-height;
    padding: $textfield-large-padding;
    font-family: $textfield-large-font-family;
    font-size: $textfield-large-text-size;
    line-height: $textfield-large-text-line-height;
    text-transform: uppercase;
    font-weight: $textfield-large-font-weight;
  }

  .textfield--center & {
    text-align: center;
  }

  .textfield--center.textfield--with-icon & {
    padding-left: 40px;
  }

  .textfield--center.textfield--with-icon.textfield--small & {
    padding-left: 28px;
  }

  .textfield--small.textfield--with-icon & {
    padding-right: 28px;
  }

  .textfield--image & {
    padding-left: 45px;
  }

  .textfield--text-transform-unset & {
    text-transform: unset;
  }
}

.textfield__label {
  color: $textfield-label-color;
  font-size: $textfield-label-size;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: $textfield-label-pointer-events;
  position: absolute;
  display: flex;
  flex-direction: row;
  width: 100%;
  text-align: left;
  padding: $textfield-label-padding;
  transition: color 100ms ease-out;
  font-weight: $textfield-label-font-weight;

  .textfield__label-text {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    .textfield--with-icon:not(.is-focused, .is-dirty) & {
      padding-right: 40px;
    }

    .textfield--center & {
      width: 100%;
      text-align: center;
      padding-right: 18px;
    }

    .textfield--center.textfield--with-icon & {
      padding-right: 18px;
    }

    .textfield--center.textfield--small & {
      padding-right: 12px;
    }

    .textfield--center.is-focused &,
    .textfield--center.is-dirty & {
      width: auto;
      padding-right: initial;
    }
  }

  .textfield.is-static & {
    padding: 0;
  }

  .textfield--floating-label & {
    @include animation(
      (
        'animation': short,
        'name': textfield-hide-small-label,
        'iteration-count': 1,
      ),
      (
        'animation': short,
        'name': textfield-show-big-label,
        'delay': short,
        'iteration-count': 1,
      )
    );

    padding-top: $textfield-label-start-position;
    font-size: $textfield-floating-label-size;
    color: $textfield-floating-label-color;
  }

  .textfield--floating-label.is-focused &,
  .textfield--floating-label.is-dirty & {
    @include animation(
      (
        'animation': short,
        'name': textfield-hide-big-label,
        'iteration-count': 1,
      ),
      (
        'animation': short,
        'name': textfield-show-small-label,
        'delay': short,
        'iteration-count': 1,
      )
    );

    font-size: $textfield-label-size;
    padding-top: 0;

    &::after,
    &::before {
      @include animation(
        (
          'animation': short,
          'name': textfield-float-line-with-big-label-to-top,
          'iteration-count': 1,
        ),
        (
          'animation': short,
          'name': textfield-float-line-with-small-label-to-top,
          'delay': short,
          'iteration-count': 1,
        )
      );

      top: $textfield-border-top-position;
    }
  }

  .textfield.is-dirty & {
    color: $textfield-dirty-text-color;

    &::before,
    &::after {
      border-color: $textfield-dirty-border-color;
    }
  }

  .textfield.is-focused & {
    color: $textfield-focused-color;

    &::before,
    &::after {
      border-color: $textfield-focused-border-color;
    }
  }

  .textfield.is-invalid & {
    &::before,
    &::after {
      border-color: $textfield-error-border-color;
    }
  }

  .textfield.is-disabled & {
    color: $textfield-disabled-color;

    &::before,
    &::after {
      border-color: $textfield-disabled-border-color;
    }
  }

  .textfield--no-label-no-break & {
    @include textfield-hide-label();
  }

  .textfield--no-label-no-break.is-dirty &,
  .textfield--no-label-no-break.is-focused & {
    &::after {
      top: -10px !important;
    }
  }

  .textfield--no-label-sm & {
    @media (min-width: $media-width-sm-min) {
      @include textfield-hide-label();
    }
  }

  .textfield--no-label-sm.is-dirty &,
  .textfield--no-label-sm.is-focused & {
    @media (min-width: $media-width-sm-min) {
      @include textfield-hide-label();

      &::after {
        top: -10px !important;
      }
    }
  }

  .textfield--no-label-md & {
    @media (min-width: $media-width-md-min) {
      @include textfield-hide-label();
    }
  }

  .textfield--no-label-md.is-dirty &,
  .textfield--no-label-md.is-focused & {
    @media (min-width: $media-width-md-min) {
      @include textfield-hide-label();

      &::after {
        top: -10px !important;
      }
    }
  }

  .textfield--large & {
    font-size: $textfield-large-text-size;
    font-family: $textfield-large-font-family;
    line-height: $textfield-large-label-line-height;
    text-transform: uppercase;
  }

  .textfield--large.is-focused &,
  .textfield--large.is-dirty & {
    font-size: $textfield-large-label-size;
    font-family: inherit;
    line-height: inherit;
    text-transform: none;
  }

  &::before,
  &::after {
    content: '';
    position: relative;
    top: $textfield-border-top-position;
    display: block;
    flex-grow: 0;
    height: 10px;
    border: $textfield-border;
    border-bottom: 0;
    transition: border-color 100ms ease-out;
  }

  .textfield--small &::before,
  .textfield--small &::after {
    height: 3px;
  }

  &::after {
    right: 0;
    position: absolute;
    width: 10px;
    flex-grow: 1;
    border-radius: 0 3px 0 0;
    border-left: 0;

    .is-dirty &,
    .is-focused & {
      left: 0;
      top: -10px;
      position: relative;
      margin-left: 10px;
    }

    .textfield.is-static & {
      width: 0;
      margin-right: 0;
      opacity: 0;
    }

    .textfield--center & {
      flex-grow: 1;
    }

    .textfield--floating-label.is-focused.textfield--small &,
    .textfield--floating-label.is-dirty.textfield--small & {
      top: $textfield-floating-label-border-top-position;
      position: relative;
    }

    .textfield--floating-label.textfield--small & {
      top: 7px;
      position: absolute;
    }

    .textfield--no-label-no-break & {
      opacity: 0;
    }

    .textfield--no-label-md & {
      @media (min-width: $media-width-md-min) {
        opacity: 0;
      }
    }

    .textfield--no-label-sm & {
      @media (min-width: $media-width-sm-min) {
        opacity: 0;
      }
    }
  }

  &::before {
    left: 0;
    right: 0;
    top: -10px;
    padding-right: 9px;
    margin-right: 10px;
    border-radius: 3px 0 0;
    border-right: 0;
    flex: 0 1 auto;

    .textfield.is-static & {
      opacity: 0;
      padding-right: 0;
      margin-right: 0;
    }

    .textfield--floating-label & {
      @include animation(
        (
          'animation': short,
          'name': textfield-float-line-with-small-label-to-bottom,
          'iteration-count': 1,
        ),
        (
          'animation': short,
          'name': textfield-float-line-with-big-label-to-bottom,
          'delay': short,
          'iteration-count': 1,
        )
      );

      top: $textfield-floating-label-border-top-position;
    }

    .textfield--center.is-focused &,
    .textfield--center.is-dirty & {
      flex: 1 1 auto;
      padding-right: 10px;
    }
  }

  .textfield--small & {
    font-size: $textfield-small-label-size;

    &::before {
      top: $textfield-border-top-position;
      margin-right: 6px;
      padding-right: 4px;
    }

    &::after {
      margin-left: 6px;
    }
  }

  .textfield--small.textfield--center.is-focused &::before,
  .textfield--small.textfield--center.is-dirty &::before {
    padding-right: 10px;
  }

  .textfield--small.textfield--floating-label & {
    @include animation(
      (
        'animation': short,
        'name': textfield-small-hide-small-label,
        'iteration-count': 1,
      ),
      (
        'animation': short,
        'name': textfield-small-show-big-label,
        'delay': short,
        'iteration-count': 1,
      )
    );

    padding-top: $textfield-small-label-start-position;
    font-size: $textfield-small-floating-label-size;

    &::before {
      @include animation(
        (
          'animation': short,
          'name': textfield-small-float-line-with-small-label-to-bottom,
          'iteration-count': 1,
        ),
        (
          'animation': short,
          'name': textfield-small-float-line-with-big-label-to-bottom,
          'delay': short,
          'iteration-count': 1,
        )
      );

      top: $textfield-small-floating-label-border-top-position;
    }
  }

  .textfield--small.textfield--floating-label.is-focused &,
  .textfield--small.textfield--floating-label.is-dirty & {
    @include animation(
      (
        'animation': short,
        'name': textfield-small-hide-big-label,
        'iteration-count': 1,
      ),
      (
        'animation': short,
        'name': textfield-small-show-small-label,
        'delay': short,
        'iteration-count': 1,
      )
    );

    padding-top: 0;
    font-size: $textfield-small-label-size;

    &::before,
    &::after {
      @include animation(
        (
          'animation': short,
          'name': textfield-small-float-line-with-big-label-to-top,
          'iteration-count': 1,
        ),
        (
          'animation': short,
          'name': textfield-small-float-line-with-small-label-to-top,
          'delay': short,
          'iteration-count': 1,
        )
      );

      top: $textfield-border-top-position;
    }
  }
}

.textfield__icon {
  @include reset-icon();

  position: absolute;
  top: $textfield-icon-top;
  right: 9px;
  font-size: 24px;
  pointer-events: none;

  .textfield--no-label-no-break & {
    top: 10px;
  }

  .textfield--no-label-sm & {
    @media (min-width: $media-width-sm-min) {
      top: 10px;
    }
  }

  .textfield--no-label-md & {
    @media (min-width: $media-width-md-min) {
      top: 10px;
    }
  }

  .textfield--small & {
    font-size: 12px;
    top: 15px;
    right: 8px;
  }

  .textfield--small.textfield--no-label-no-break & {
    top: 7px;
  }

  .textfield--small.textfield--no-label-sm & {
    @media (min-width: $media-width-sm-min) {
      top: 7px;
    }
  }

  .textfield--small.textfield--no-label-md & {
    @media (min-width: $media-width-md-min) {
      top: 7px;
    }
  }
}

.textfield__form-helper {
  margin-top: $textfield-form-helper-margin-top;

  &.form-helper--help {
    color: $textfield-helper-color;
  }
}

.textfield__clear-btn {
  position: absolute;
  top: 16px;
  right: 8px;

  .textfield--small & {
    top: 12px;
    right: 5px;
    font-size: 16px;
    width: 16px;
    height: 16px;
  }

  .textfield--no-label-no-break & {
    top: 8px;
  }

  .textfield--no-label-sm &,
  .textfield--no-label-md & {
    top: 16px;

    @media (min-width: $media-width-md-min) {
      top: 8px;
    }
  }

  .textfield--no-label-sm & {
    @media (min-width: $media-width-sm-min) {
      top: 8px;
    }
  }

  .textfield--no-label-no-break.textfield--small & {
    top: 4px;
  }

  .textfield--no-label-sm.textfield--small &,
  .textfield--no-label-md.textfield--small & {
    top: 11px;

    @media (min-width: $media-width-md-min) {
      top: 4px;
    }
  }

  .textfield--no-label-sm.textfield--small & {
    @media (min-width: $media-width-sm-min) {
      top: 4px;
    }
  }
}

.textfield__textarea-input {
  resize: none;
}

@keyframes textfield-hide-big-label {
  0% {
    padding-top: $textfield-label-start-position;
    font-size: $textfield-floating-label-size;
  }

  100% {
    padding-top: $textfield-border-top-position;
    color: transparent;
    font-size: $textfield-floating-label-size;
  }
}

@keyframes textfield-show-small-label {
  0% {
    padding-top: $textfield-border-top-position;
    color: transparent;
    font-size: $textfield-label-size;
  }

  100% {
    padding-top: 0;
    font-size: $textfield-label-size;
  }
}

@keyframes textfield-show-big-label {
  0% {
    padding-top: $textfield-border-top-position;
    color: transparent;
    font-size: $textfield-floating-label-size;
  }

  100% {
    padding-top: $textfield-label-start-position;
    font-size: $textfield-floating-label-size;
  }
}

@keyframes textfield-hide-small-label {
  0% {
    padding-top: 0;
    font-size: $textfield-label-size;
  }

  100% {
    padding-top: $textfield-border-top-position;
    color: transparent;
    font-size: $textfield-label-size;
  }
}

@keyframes textfield-float-line-with-big-label-to-top {
  0% {
    top: $textfield-floating-label-border-top-position;
  }

  100% {
    top: 0;
  }
}

@keyframes textfield-float-line-with-small-label-to-top {
  0% {
    top: 0;
  }

  100% {
    top: $textfield-border-top-position;
  }
}

@keyframes textfield-float-line-with-big-label-to-bottom {
  0% {
    top: 0;
  }

  100% {
    top: $textfield-floating-label-border-top-position;
  }
}

@keyframes textfield-float-line-with-small-label-to-bottom {
  0% {
    top: $textfield-border-top-position;
  }

  100% {
    top: 0;
  }
}

// keyframes for small textfield

@keyframes textfield-small-hide-big-label {
  0% {
    padding-top: $textfield-small-label-start-position;
    font-size: $textfield-small-floating-label-size;
  }

  100% {
    padding-top: $textfield-border-top-position;
    color: transparent;
    font-size: $textfield-small-floating-label-size;
  }
}

@keyframes textfield-small-show-small-label {
  0% {
    padding-top: $textfield-border-top-position;
    color: transparent;
    font-size: $textfield-small-label-size;
  }

  100% {
    padding-top: 0;
    font-size: $textfield-small-label-size;
  }
}

@keyframes textfield-small-show-big-label {
  0% {
    padding-top: $textfield-border-top-position;
    color: transparent;
    font-size: $textfield-small-floating-label-size;
  }

  100% {
    padding-top: $textfield-small-label-start-position;
    font-size: $textfield-small-floating-label-size;
  }
}

@keyframes textfield-small-hide-small-label {
  0% {
    padding-top: 0;
    font-size: $textfield-small-label-size;
  }

  100% {
    padding-top: $textfield-border-top-position;
    color: transparent;
    font-size: $textfield-small-label-size;
  }
}

@keyframes textfield-small-float-line-with-big-label-to-top {
  0% {
    top: $textfield-small-floating-label-border-top-position;
  }

  100% {
    top: 0;
  }
}

@keyframes textfield-small-float-line-with-small-label-to-top {
  0% {
    top: 0;
  }

  100% {
    top: $textfield-border-top-position;
  }
}

@keyframes textfield-small-float-line-with-big-label-to-bottom {
  0% {
    top: 0;
  }

  100% {
    top: $textfield-small-floating-label-border-top-position;
  }
}

@keyframes textfield-small-float-line-with-small-label-to-bottom {
  0% {
    top: $textfield-border-top-position;
  }

  100% {
    top: 0;
  }
}
