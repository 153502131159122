@import '~@core-front/sass-tokens/index.scss';

.skeleton {
  width: 100%;
  cursor: progress;
}

.skeleton__item {
  @include animation(
    (
      'animation': extra-long,
      'name': skeleton-background,
      'iteration-count': infinite,
    )
  );

  display: inline-block;
  width: 100%;
  line-height: calc(
    1em - 2px
  ); // Fallback for browsers that do not support lh units, can be removed once support is better for our browserslist: https://caniuse.com/mdn-css_types_length_lh
  line-height: calc(1lh - 8px);
  background: $skeleton-background-color;
  border-radius: 3px;
  cursor: progress;
}

@keyframes skeleton-background {
  0% {
    background-color: $skeleton-background-color;
  }

  50% {
    background-color: $skeleton-background-color-secondary;
  }

  100% {
    background-color: $skeleton-background-color;
  }
}
