.header__employee {
  pointer-events: auto;
  min-height: 35px;
  background: var(--color-employee-bg-strong);
  color: var(--color-employee-text-onbg-strong);
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
}

.header__employee--test {
  background-color: var(--color-palette-blue-500);
}

.header__employee--dev {
  background-color: var(--color-palette-orange-400);
}

.header__employee-actions {
  display: flex;
  align-items: center;

  @include list-unstyled();
}

.header__employee-item {
  position: relative;
  height: 100%;
}

.header__employee-button {
  display: flex;
  align-items: center;
  font-size: var(--type-tiny-font-size);
  line-height: var(--type-tiny-line-height);
  font-weight: 700;
  color: var(--color-employee-text-onbg-strong);
  margin-left: 3px;
  padding: 10px 10px 12px;
  background: transparent;
  border: none;
  border-top: 2px solid var(--color-employee-bg-strong);
  height: inherit;
  outline-offset: -3px;

  &.is-current {
    position: relative;
    z-index: 4;
  }

  html[data-whatintent='mouse'] &:hover,
  &.is-current {
    color: var(--color-employee-text-hover);
    background-color: var(--color-neutral-bg-level-3);
  }

  .header__employee--test & {
    border-top-color: var(--color-palette-blue-500);
    color: var(--color-employee-text-onbg-strong);

    html[data-whatintent='mouse'] &:hover,
    &.is-current {
      color: var(--color-palette-blue-500);
      background-color: var(--color-neutral-bg-level-3);
    }
  }

  .header__employee--dev & {
    border-top-color: var(--color-palette-orange-400);
    color: var(--color-employee-text-onbg-strong);

    html[data-whatintent='mouse'] &:hover,
    &.is-current {
      color: var(--color-palette-orange-400);
      background-color: var(--color-neutral-bg-level-3);
    }
  }
}

.header__employee-button--with-icon {
  padding: 6px 10px 8px;
}

.header__employee-item-label {
  .header__employee-button--hide-label & {
    @include visually-hidden();
  }
}

.header__employee-icon,
.header__employee-arrow-icon {
  @include reset-icon();
}

.header__employee-icon {
  margin-right: 6px;
  font-size: 1.5rem;

  .header__employee-button--hide-label & {
    margin-right: 0;
  }
}

.header__employee-arrow-icon {
  transition: transform 0.3s ease-in-out;
  margin-left: 2px;
  font-size: 1rem;

  .header__employee-button.is-current & {
    transform: rotate(180deg);
  }
}
