.header__languages {
  line-height: 1;

  .header__top & {
    display: none;

    &.header__languages--top {
      display: block;
    }

    @media (min-width: $media-width-sm-min) {
      display: block;
    }
  }

  .header__dropdown & {
    @media (min-width: $media-width-sm-min) {
      display: none;
    }
  }
}

.header__languages-list {
  font-size: var(--type-tiny-font-size);
  line-height: var(--type-tiny-line-height);
  display: flex;
  margin-left: -8px;
  margin-right: -8px;

  .header__dropdown & {
    font-size: var(--type-small-font-size);
    line-height: var(--type-small-line-height);
    justify-content: flex-end;
  }
}

.header__languages-link {
  position: relative;
  font-weight: 400;
  outline-offset: -5px;
  display: block;
  padding: 16px 8px;

  .header__languages-item.is-current & {
    color: var(--color-brand-primary-text);

    &::after {
      display: block;
      content: '';
      border-bottom: 2px solid var(--color-brand-primary-border);
      position: absolute;
      bottom: 0;
      left: 8px;
      right: 8px;
    }
  }
}
