@import '~@core-front/sass-tokens/index.scss';

h1,
.h1 {
  font-size: $h1-size;
  line-height: $h1-line-height;
  font-family: $heading-font;
  font-weight: $heading-weight;
  color: $h1-color;
  text-transform: $h1-transform;
  letter-spacing: $h1-letter-spacing;

  @media (min-width: $media-width-sm-min) {
    font-size: $h1-size-sm;
    line-height: $h1-line-height-sm;
  }

  @media (min-width: $media-width-md-min) {
    font-size: $h1-size-md;
    line-height: $h1-line-height-md;
  }

  @media (min-width: $media-width-xl-min) {
    font-size: $h1-size-xl;
    line-height: $h1-line-height-xl;
  }
}

h2,
.h2 {
  font-size: $h2-size;
  line-height: $h2-line-height;
  font-family: $heading-font;
  font-weight: $heading-weight;
  text-transform: $heading-transform;
  letter-spacing: $h2-letter-spacing;
  // if it is used with h1, like <h1 class="h2">, it should not be purple
  color: inherit;

  @media (min-width: $media-width-sm-min) {
    font-size: $h2-size-sm;
    line-height: $h2-line-height-sm;
  }

  @media (min-width: $media-width-md-min) {
    font-size: $h2-size-md;
    line-height: $h2-line-height-md;
  }

  @media (min-width: $media-width-xl-min) {
    font-size: $h2-size-xl;
    line-height: $h2-line-height-xl;
  }
}

h3,
.h3 {
  font-size: $h3-size;
  line-height: $h3-line-height;
  text-transform: $h3-transform;
  font-family: $h3-font-family;
  font-weight: $heading-weight;
  letter-spacing: $h3-letter-spacing;
  // if it is used with h1, like <h1 class="h3">, it should not be purple
  color: inherit;

  @media (min-width: $media-width-sm-min) {
    font-size: $h3-size-sm;
    line-height: $h3-line-height-sm;
  }

  @media (min-width: $media-width-md-min) {
    font-size: $h3-size-md;
    line-height: $h3-line-height-md;
  }

  @media (min-width: $media-width-xl-min) {
    font-size: $h3-size-xl;
    line-height: $h3-line-height-xl;
  }
}

h4,
.h4 {
  font-size: $h4-size;
  line-height: $h4-line-height;
  // if it is used with h1, like <h1 class="h4">, it should not be purple
  color: inherit;
  text-transform: none;
  font-family: inherit;
  font-weight: $h4-font-weight;

  @media (min-width: $media-width-xl-min) {
    font-size: $h4-size-xl;
    line-height: $h4-line-height-xl;
  }
}

h5,
.h5 {
  font-size: $h5-size;
  line-height: $h5-line-height;
  // if it is used with h1, like <h1 class="h5">, it should not be purple
  color: inherit;
  text-transform: none;
  font-family: inherit;
  font-weight: $h5-font-weight;

  @media (min-width: $media-width-xl-min) {
    font-size: $h5-size-xl;
    line-height: $h5-line-height-xl;
  }
}

h6,
.h6 {
  font-size: $h6-size;
  line-height: $h6-line-height;
  // if it is used with h1, like <h1 class="h6">, it should not be purple
  color: inherit;
  text-transform: none;
  font-family: inherit;
  font-weight: $h6-font-weight;

  @media (min-width: $media-width-xl-min) {
    font-size: $h6-size-xl;
    line-height: $h6-line-height-xl;
  }
}
