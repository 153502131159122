@import '~@core-front/sass-tokens/index.scss';

.search {
  position: relative;
}

.textfield__input.search__input {
  padding-right: 42px;

  .search--small & {
    padding-right: 32px;
  }

  .textfield--with-icon & {
    padding-right: 74px;

    .search--small & {
      padding-right: 56px;
    }
  }
}

.search__textfield .textfield__label {
  color: $search-textfield-label-color;
}

.search__textfield {
  .textfield__clear-btn {
    right: 42px;

    .search--small & {
      right: 32px;
    }
  }
}

.search__action {
  position: absolute;
  right: 10px;
  top: 16px;
  height: 24px;
  width: 24px;
  padding: 0;
  margin: 0;
  border: none;
  background-color: transparent;
  z-index: 2;

  html[data-whatintent='mouse'] &:hover {
    color: $search-action-hover-color;
  }

  &:disabled {
    color: $search-action-disabled-color;
  }

  .search--small & {
    right: 8px;
    top: 12px;
    height: 16px;
    width: 16px;
  }

  .search--no-label-no-break & {
    top: 8px;
  }

  .search--no-label-sm & {
    @media (min-width: $media-width-sm-min) {
      top: 8px;
    }
  }

  .search--no-label-md & {
    @media (min-width: $media-width-md-min) {
      top: 8px;
    }
  }
}

.search__action-icon {
  @include reset-icon();

  display: block;
  font-size: 24px;

  .search--small & {
    font-size: 16px;
  }
}
