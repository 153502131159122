@use 'sass:map';

.header__dropdown {
  position: fixed;
  z-index: map.get($zindex, header-dropdown);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  min-height: 85px;
  pointer-events: auto;
  white-space: normal;
  outline-offset: -3px;
  background: var(--color-neutral-bg-level-3);

  @media (min-width: $media-width-lg-min) {
    overflow: visible;
    background-color: transparent;
    width: calc(#{$page-max-width} - 64px); // container width minus padding
    max-width: calc(100% - #{$container-padding-lg * 2});
    border-radius: 0 0 3px 3px;
    pointer-events: none;
  }

  @media (max-width: $media-width-md-max) {
    box-shadow: $elevation-1;
  }
}

.header__dropdown--small {
  @media (min-width: $media-width-lg-min) {
    width: 20rem;
  }
}

.header__dropdown--medium {
  @media (min-width: $media-width-lg-min) {
    width: 37.5rem;
  }
}

.header__dropdown-inner {
  height: 100%;
  will-change: transform, opacity;
  opacity: 0;
  transition: 100ms ease-out;
  transition-property: transform, opacity;
  transform: translateY(10px);
  color: var(--color-neutral-text);

  @media (min-width: $media-width-lg-min) {
    height: 100%;
    background-color: transparent;
  }

  .header__dropdown.is-entered & {
    opacity: 1;
    transform: translateY(0);
  }
}

.header__dropdown-content-wrapper {
  position: relative;
  overflow: auto;
  max-height: 100%;
  pointer-events: auto;
  -webkit-overflow-scrolling: touch;
  box-sizing: border-box;

  @media (min-width: $media-width-lg-min) {
    border-radius: 8px;
    box-shadow: $elevation-5;
    background: var(--color-neutral-bg-level-3);
  }
}

.header__dropdown-content {
  .header__dropdown--padding-xsmall & {
    padding: 8px;
  }

  .header__dropdown--padding-small & {
    padding: 20px;
  }

  .header__dropdown--padding-medium & {
    padding: 20px;

    @media (min-width: $media-width-lg-min) {
      padding: 40px;
    }
  }
}

.header__dropdown-header {
  background-color: var(--color-neutral-bg-level-3);

  .header__dropdown--padding-xsmall & {
    // set top/bottom padding so that header would "contain" close button with minimal content
    padding: 16px;
  }

  .header__dropdown--padding-small & {
    padding: 20px;
  }

  .header__dropdown--padding-medium & {
    padding: 20px;

    @media (min-width: $media-width-lg-min) {
      padding: 40px;
    }
  }
}

.header__dropdown-footer {
  background-color: var(--color-neutral-bg-level-3);
  position: sticky;
  bottom: 0;

  .header__dropdown--padding-xsmall & {
    padding: 24px 8px;
  }

  .header__dropdown--padding-small & {
    padding: 20px;
  }

  .header__dropdown--padding-medium & {
    padding: 20px;

    @media (min-width: $media-width-lg-min) {
      padding: 40px;
    }
  }
}

.header__dropdown-close {
  display: none;
  z-index: 2;
  position: absolute;
  top: 8px;
  right: 8px;

  @media (min-width: $media-width-lg-min) {
    display: block;
  }

  .header__dropdown--sso & {
    display: block;
  }
}

.header__dropdown-content-top {
  background-color: var(--color-neutral-bg-level-2);
}
