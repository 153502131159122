@import '~@core-front/sass-tokens/index.scss';

.chip {
  display: inline-flex;
  padding: 4px 8px;
  border-radius: 12px;
  border: 1px solid $chip-border-color;
  font-size: $font-size-tiny;
  line-height: $line-height-tiny;
  word-break: break-word;

  &.is-disabled {
    opacity: 0.4;
    pointer-events: none;
  }
}

.chip__remove {
  @include reset-button();
  @include transition(
    (
      'animation': short,
      'properties': color,
    )
  );

  margin-left: 5px;
  margin-right: -4px;
  color: $text-color-base;

  html[data-whatintent='mouse'] &:hover {
    color: $chip-hover-color;
  }
}

.chip__remove-icon {
  vertical-align: bottom;
}
