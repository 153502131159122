@use 'sass:math';

@import '~@core-front/sass-tokens/index.scss';

$radio-indicator-size-medium: 20px !default;
$radio-indicator-size-small: 16px !default;

$radio-content-line-height: 1.375 !default;
$radio-content-medium-line-height: 1.43 !default;
$radio-content-small-line-height: 1.334 !default;

$radio-content-font-size: 16px !default;
$radio-content-medium-font-size: 14px !default;
$radio-content-small-font-size: 12px !default;

$radio-indicator-inner-size: 10px !default;
$radio-indicator-inner-size-medium: 8px !default;
$radio-indicator-inner-size-small: 6px !default;

.radio {
  position: relative;
}

.radio--vertical {
  display: flex;
}

.radio__label {
  @include visually-hidden();
}

.radio__input {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
}

.radio__inner {
  position: relative;
  display: inline-block;
  padding-left: ($radio-indicator-size + math.div($radio-indicator-size, 2));
  min-height: $radio-indicator-size;
  cursor: pointer;
  border-radius: 3px;

  .radio__input:focus-visible ~ & {
    @include keyboard-focus();
  }

  .radio--small & {
    padding-left: ($radio-indicator-size-small + math.div($radio-indicator-size-small, 2));
    min-height: $radio-indicator-size-small;
  }

  .radio--medium & {
    padding-left: ($radio-indicator-size-medium + math.div($radio-indicator-size-medium, 2));
    min-height: $radio-indicator-size-medium;
  }

  .radio--employee-background &::before {
    content: '';
    display: block;
    background: $radio-employee-background;
    position: absolute;
    top: -5px;
    left: -5px;
    right: -10px;
    bottom: -5px;
    border-radius: 3px;
  }

  .radio__input:disabled ~ & {
    cursor: default;
  }

  .radio--vertical & {
    display: flex;
    flex-direction: column-reverse;
    text-align: center;
    justify-content: space-between;
    padding: 0;
  }

  .skeleton__content > & {
    display: block;
    cursor: default;
  }

  .radio--hide-label & {
    padding-left: $radio-indicator-size;
  }

  .radio--hide-label.radio--medium & {
    padding-left: $radio-indicator-size-medium;
  }

  .radio--hide-label.radio--small & {
    padding-left: $radio-indicator-size-small;
  }
}

.radio__content {
  display: block;
  font-weight: 400;
  position: relative;

  .radio--large & {
    font-size: $radio-content-font-size;
    line-height: $radio-content-line-height;
    margin-top: calc(($radio-indicator-size - ($radio-content-line-height * $radio-content-font-size)) / 2);
  }

  .radio--medium & {
    font-size: $radio-content-medium-font-size;
    line-height: $radio-content-medium-line-height;
    margin-top: calc(($radio-indicator-size-medium - ($radio-content-medium-line-height * $radio-content-medium-font-size)) / 2);
  }

  .radio--small & {
    font-size: $radio-content-small-font-size;
    line-height: $radio-content-small-line-height;
    margin-top: calc(($radio-indicator-size-small - ($radio-content-small-line-height * $radio-content-small-font-size)) / 2);
  }

  .radio__input:disabled ~ .radio__inner & {
    color: $radio-disabled-color;
  }

  .radio--vertical & {
    margin-bottom: 15px;
  }

  .radio--hide-label & {
    display: none;
  }
}

.radio__indicator {
  display: block;
  border: 1px solid $radio-border-color;
  box-shadow: none;
  width: $radio-indicator-size;
  height: $radio-indicator-size;
  border-radius: 50%;
  background: $radio-indicator-bg;
  position: absolute;
  left: 0;

  .radio__input:checked ~ .radio__inner & {
    background: $radio-indicator-checked-bg;
    border-color: $radio-indicator-checked-border;
    color: $radio-indicator-checked-color;

    .radio--employee & {
      background: $radio-indicator-employee-bg;
      border-color: $radio-indicator-employee-border;
      color: $radio-indicator-employee-color;
    }
  }

  .radio--medium & {
    width: $radio-indicator-size-medium;
    height: $radio-indicator-size-medium;
  }

  .radio--small & {
    width: $radio-indicator-size-small;
    height: $radio-indicator-size-small;
  }

  .radio__input:disabled ~ .radio__inner & {
    background: $radio-indicator-disabled-bg;
    border-color: $radio-indicator-disabled-border;
    opacity: $radio-indicator-disabled-opacity;
  }

  .radio__input:disabled:checked ~ .radio__inner & {
    background: $radio-indicator-disabled-checked-bg;
    border-color: $radio-indicator-disabled-checked-border;
    color: $radio-indicator-disabled-color;
  }

  .radio--indicator-middle & {
    top: 50%;
    transform: translateY(-50%);
  }

  .radio--vertical & {
    top: 0;
    position: relative;
    margin: 0 auto;
  }

  &::before {
    content: '';
    display: block;
    width: $radio-indicator-inner-size;
    height: $radio-indicator-inner-size;
    background: currentcolor;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;

    .radio--medium & {
      width: $radio-indicator-inner-size-medium;
      height: $radio-indicator-inner-size-medium;
    }

    .radio--small & {
      width: $radio-indicator-inner-size-small;
      height: $radio-indicator-inner-size-small;
    }

    .radio__input:checked ~ .radio__inner & {
      opacity: 1;
    }
  }

  &.skeleton__item {
    border: none;
  }
}

.radio__extra {
  padding-top: 10px;
  padding-left: ($radio-indicator-size + math.div($radio-indicator-size, 2));

  .radio--medium & {
    padding-left: ($radio-indicator-size-medium + math.div($radio-indicator-size-medium, 2));
  }

  .radio--small & {
    padding-left: ($radio-indicator-size-small + math.div($radio-indicator-size-small, 2));
  }
}
