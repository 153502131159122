@use 'sass:map';

@import '~@telia-front/sass-tokens/index.scss';
@import '~@core-front/tag/styles.scss';

$tag-colors: (
  primary: (
    default: var(--color-brand-primary-bg-strong),
    text: var(--color-brand-primary-text-onbg-strong),
  ),
  recommended: (
    default: var(--color-brand-expressive-bg-subtle),
    text: var(--color-brand-expressive-text),
  ),
  attention: (
    default: var(--color-attention-bg-subtle),
    text: var(--color-attention-text),
  ),
  employee: (
    default: var(--color-employee-bg-strong),
    text: var(--color-employee-text-onbg-strong),
  ),
  success: (
    default: var(--color-success-bg-subtle),
    text: var(--color-success-text),
  ),
  information: (
    default: var(--color-info-bg-subtle),
    text: var(--color-info-text),
  ),
  service: (
    default: var(--color-neutral-bg-level-1),
  ),
  error: (
    default: var(--color-error-bg-strong),
    text: var(--color-error-text-onbg-strong),
  ),
  campaign-1: (
    default: var(--color-campaign-1-bg),
    text: var(--color-campaign-1-text),
  ),
  campaign-2: (
    default: var(--color-campaign-2-bg),
    text: var(--color-campaign-2-text),
  ),
  campaign-3: (
    default: var(--color-campaign-3-bg),
    text: var(--color-campaign-3-text),
  ),
  campaign-4: (
    default: var(--color-campaign-4-bg),
    text: var(--color-campaign-4-text),
  ),
  text: (
    default: var(--color-neutral-bg-subtle),
    text: var(--color-neutral-text),
  ),
);

/* Modifier: Purposes */
@each $name, $colors in $tag-colors {
  .telia-tag--#{$name} {
    background: map.get($colors, 'default');
    color: map.get($colors, 'text');
  }
}

.telia-tag__icon {
  @include reset-icon();

  flex-basis: $tag-svg-size;
  width: $tag-svg-size;
  height: $tag-svg-size;

  .telia-tag--animating:not(.telia-tag--large) &,
  .telia-tag--icon:not(.telia-tag--large) & {
    flex-basis: $tag-svg-size-icon;
    width: $tag-svg-size-icon;
    height: $tag-svg-size-icon;
  }

  .telia-tag--animating.telia-tag--small &,
  .telia-tag--icon.telia-tag--small & {
    flex-basis: $tag-svg-size-icon-small;
    width: $tag-svg-size-icon-small;
    height: $tag-svg-size-icon-small;
  }
}
