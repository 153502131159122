@import '~@telia-front/sass-tokens/index.scss';

@import '~@core-front/grid/styles.scss';

@include grid-gutter(medium, $grid-gutter-medium, $grid-gutter-medium-xs);
@include grid-gutter(smedium, $grid-gutter-smedium);

@media (min-width: $media-width-xxl-min) {
  @include make-grid(xxl);
}
